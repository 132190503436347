import React from 'react';
import Switch from 'react-switch';
import { Grid, Typography, Button } from '@mui/material';
import { Check } from '@mui/icons-material';

import { useStyles } from './PageHeader.styles';

export interface HeaderButton {
  buttonLabel: string;
  onClick: (e: any) => void;
  disabled?: boolean;
}

export interface HeaderSwitch {
  switchChecked: boolean;
  switchAction: () => void;
  disabled?: boolean;
}

export interface PageHeaderProps {
  pageTitle: string;
  buttons: HeaderButton[];
  switchButton?: HeaderSwitch;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ pageTitle, buttons, switchButton }) => {
  const { pageTitleContainer, buttonContainer, titleContainer, button: buttonStyle } = useStyles();

  return (
    <Grid className={pageTitleContainer} alignItems="center">
      <Grid className={titleContainer}>
        <Typography color="primary" gutterBottom variant="h1">
          {pageTitle}
        </Typography>
      </Grid>
      <Grid className={buttonContainer}>
        {switchButton && (
          <Switch
            checked={switchButton.switchChecked}
            onChange={() => switchButton.switchAction()}
          />
        )}
        {buttons &&
          buttons.map(button => (
            <Button
              startIcon={<Check />}
              key={Math.random()}
              variant="contained"
              color="primary"
              className={buttonStyle}
              disabled={button.disabled}
              onClick={button.onClick}>
              {button.buttonLabel}
            </Button>
          ))}
      </Grid>
    </Grid>
  );
};
