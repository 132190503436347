import React, { useState } from 'react';
import { Collapse, Button, Typography, Grid } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import clsx from 'clsx';
import { CustomCollapseProps } from './Collapse.types';
import { useCollapseStyles } from './useCollapseStyles';

export const CustomCollapse: React.FC<CustomCollapseProps> = ({
  listTitleOpen,
  listTitleClosed,
  titleStyle,
  title,
  arrow,
  children,
}) => {
  const {
    title: collapseTitleStyle,
    expandIcon,
    buttonContainer,
    button,
    container,
  } = useCollapseStyles();
  const [open, setOpen] = useState<boolean>(false);

  const handleListOpen = () => {
    setOpen(!open);
  };

  return (
    <Grid className={container}>
      <Grid className={buttonContainer}>
        <Button variant="contained" color="primary" onClick={handleListOpen} className={button}>
          <Typography variant="body1" gutterBottom className={clsx(collapseTitleStyle, titleStyle)}>
            {title}
            {open && listTitleOpen ? listTitleOpen : listTitleClosed}
          </Typography>
          {arrow && open ? (
            <ExpandLess className={expandIcon} />
          ) : (
            <ExpandMore className={expandIcon} />
          )}
        </Button>
      </Grid>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Grid>
  );
};
