import React, { useState } from 'react';
import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Box,
  Collapse,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { theme } from 'src/utils/theme';
import { NestedNavItem } from './Nav';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { ReactComponent as Logo } from '../../assets/logo_d.svg';
import { useStoreState, useStoreActions } from 'src/stores';

export const drawerWidth = 240;
export const appBarHeight = 0;

const useStyles = makeStyles(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  drawerTitle: {
    textTransform: 'uppercase',
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
}));

export interface SideDrawerProps {
  navItems: NestedNavItem[];
}

const SideDrawer: React.FC<SideDrawerProps> = ({ navItems }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const resetAuthStore = useStoreActions(actions => actions.auth.reset);
  const resetUserStore = useStoreActions(actions => actions.user.reset);
  const resetWorkerStore = useStoreActions(actions => actions.workers.reset);
  const resetNotificationStore = useStoreActions(actions => actions.popUp.reset);
  const resetFinanceStore = useStoreActions(actions => actions.finance.reset);
  const resetJobStore = useStoreActions(actions => actions.jobs.reset);

  const user = useStoreState(state => state.user.data);

  const handleNavigation = (link?: string) => {
    if (!link) {
      return;
    }
    history.push(link);
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();

    resetAuthStore();
    resetUserStore();
    resetWorkerStore();
    resetNotificationStore();
    resetFinanceStore();
    resetJobStore();

    history.push('/auth');
  };

  const isFocused = (link: string) => {
    return history.location.pathname.includes(link);
  };

  const [openSubId, setOpenSupId] = useState<number>(-1);

  const getItem = (navItem: NestedNavItem, index: number) => (
    <ListItem
      button
      onClick={() =>
        navItem.subsItems && navItem.subsItems.length
          ? setOpenSupId(openSubId === index ? -1 : index)
          : handleNavigation(navItem.link)
      }
      key={index}
      style={{
        backgroundColor: navItem.link && isFocused(navItem.link) ? '#003162' : 'transparent',
      }}>
      <ListItemIcon>{navItem.icon()}</ListItemIcon>
      <ListItemText primary={navItem.label} />
      {navItem.subsItems && (openSubId === index ? <ExpandLess /> : <ExpandMore />)}
    </ListItem>
  );

  return (
    <SwipeableDrawer
      anchor="left"
      open={isOpen}
      variant="permanent"
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}>
      <Box mt={2} className={classes.drawerTitleContainer}>
        <Logo />
        <Typography mt={2} variant="body2">
          {user.firstName} {user.lastName} -{' '}
          <Typography
            onClick={handleLogout}
            style={{ cursor: 'pointer' }}
            color="primary"
            component={'span'}>
            Logout
          </Typography>
        </Typography>
        <Typography variant="body2">{user.email}</Typography>
      </Box>
      <List>
        {navItems.map((navItem, index) => (
          <>
            {getItem(navItem, index)}
            <Collapse in={openSubId === index} timeout="auto" unmountOnExit>
              <List>{navItem.subsItems?.map((subItem, index) => getItem(subItem, index))}</List>
            </Collapse>
          </>
        ))}
      </List>
    </SwipeableDrawer>
  );
};

export { SideDrawer };
