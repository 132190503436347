import React, { useState, useEffect } from 'react';
import { Alert, Box, Grid } from '@mui/material';
import { useGetJobConfirmations } from 'src/api/hooks/useJob';
import { useStoreActions } from 'src/stores';
import { Trans, useTranslation } from 'react-i18next';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { JobConfirmation } from 'src/DTO/JobConfirmation.type';
import { TFunction } from 'i18next';
import { formatDate } from 'src/utils/formatDate';
import {
  DataGrid,
  GridToolbar,
  GridValueGetterParams,
  GridRenderCellParams,
  GridFilterModel,
} from '@mui/x-data-grid';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const headers = (t: TFunction) => {
  return [
    {
      headerName: t('jobConfirmationsScreen.headers.id'),
      field: 'id',
      sorting: false,
      hide: true,
      flex: 1,
    },
    {
      headerName: t('jobConfirmationsScreen.headers.worker'),
      field: 'worker.id',
      flex: 1,
      hide: true,
      valueGetter: (params: GridValueGetterParams<JobConfirmation>) => params.row.worker.id,
    },
    {
      headerName: t('jobConfirmationsScreen.headers.worker'),
      field: 'worker.name',
      flex: 1,
      valueGetter: (params: GridValueGetterParams<JobConfirmation>) => params.row.worker.name,
    },
    {
      headerName: t('jobConfirmationsScreen.headers.status'),
      field: 'status',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams<JobConfirmation>) => {
        const status = row.closedBy === null ? 'PENDING' : 'CLOSED';
        return <Alert severity={status === 'PENDING' ? 'warning' : 'success'}>{status}</Alert>;
      },
      valueGetter: ({ row }: GridValueGetterParams<JobConfirmation>) => {
        const status = row.closedBy === null ? 'PENDING' : 'CLOSED';
        return status;
      },
    },

    {
      headerName: t('jobConfirmationsScreen.headers.company'),
      field: 'company.name',
      flex: 1,
      valueGetter: (params: GridValueGetterParams<JobConfirmation>) => params.row.company.name,
    },
    {
      headerName: t('jobConfirmationsScreen.headers.job'),
      field: 'job.id',
      flex: 1,
      valueGetter: (params: GridValueGetterParams<JobConfirmation>) => params.row.job.role.name,
    },
    {
      headerName: t('jobConfirmationsScreen.headers.closedBy'),
      field: 'closedBy',
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams<JobConfirmation>) =>
        row.closedBy === null ? '' : row.closedBy,
      sorting: false,
    },
    {
      headerName: t('jobConfirmationsScreen.headers.invoice'),
      field: 'invoice',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams<JobConfirmation>) => {
        return row.invoice ? (
          <Alert severity="success">
            <Trans>jobConfirmationsScreen.invoiced</Trans>
          </Alert>
        ) : (
          <Alert severity="info">
            <Trans>jobConfirmationsScreen.notInvoiced</Trans>
          </Alert>
        );
      },
      sorting: false,
    },
    {
      headerName: t('generic.updatedAt'),
      field: 'updatedAt',
      filtering: false,
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams<JobConfirmation>) => formatDate(row.updatedAt),
    },
  ];
};

export const JobConfirmationList: React.FC = () => {
  const { t } = useTranslation();

  const [jobConfirmations, setJobConfirmations] = useState<JobConfirmation[] | undefined>(
    undefined,
  );
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const { entity, id } = useParams() as { entity: string; id: string };

  useEffect(() => {
    if (isEmpty(id)) {
      setFilterModel({
        items: [
          {
            columnField: 'status',
            operatorValue: 'contains',
            value: 'pending',
          },
        ],
      });
    }
  }, []);

  const [getJobs] = useGetJobConfirmations({
    onComplete: res => {
      if (res) {
        setJobConfirmations(res);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.jobConfirmationsScreen.error'),
      });
    },
  });

  useEffect(() => {
    if (!isEmpty(id) && entity === 'company') {
      setFilterModel({
        items: [
          {
            columnField: 'role.company.id',
            operatorValue: 'contains',
            value: id,
          },
        ],
      });
    }
    if (!isEmpty(id) && entity === 'worker') {
      setFilterModel({
        items: [
          {
            columnField: 'worker.id',
            operatorValue: 'contains',
            value: id,
          },
        ],
      });
    }
  }, [id]);

  useEffect(() => {
    getJobs();
  }, []);

  const navigateToDetails = (id: string) => {
    return window.open(`/confirmations/${id}`, '_blank');
  };

  return (
    <Grid style={{ width: '100%' }}>
      <DataGrid
        autoHeight
        components={{
          Toolbar: () => {
            return (
              <Box mt={1} ml={1}>
                <Grid spacing={2} container>
                  <Grid item xs={12}>
                    <GridToolbar />
                  </Grid>
                  <Grid item xs={6}>
                    {filterModel &&
                      filterModel.items.length > 0 &&
                      filterModel.items.find(f => f.value === 'pending') && (
                        <Alert severity="warning">
                          Default filter on pending confim. is active!
                        </Alert>
                      )}
                  </Grid>
                </Grid>
              </Box>
            );
          },
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        autoPageSize
        initialState={{
          sorting: {
            sortModel: [{ field: 'updatedAt', sort: 'desc' }],
          },
        }}
        filterModel={filterModel}
        rows={jobConfirmations ? jobConfirmations : []}
        onFilterModelChange={newFilterModel => setFilterModel(newFilterModel)}
        columns={headers(t)}
        loading={!jobConfirmations}
        onRowClick={data => {
          navigateToDetails(data.row.id);
        }}
        pageSize={100}
        rowsPerPageOptions={[5, 20]}
      />
    </Grid>
  );
};
