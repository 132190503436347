import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  dataType: {
    paddingRight: 8,
  },
  title: {
    textAlign: 'center',
  },
}));
