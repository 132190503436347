import { Log } from 'src/DTO/Log.type';
import { useApi } from './useApi';
import { BaseHookRequest } from './useApi.types';

export const useGetLogs: BaseHookRequest<Log[], null> = params => {
  const [{ getRequest }, state] = useApi<Log[]>(params);
  const getLogs = () => {
    return getRequest('/admin/log');
  };
  return [getLogs, state];
};
