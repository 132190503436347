import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { useCreateInvoice } from 'src/api/hooks/useInvoice';
import { useStoreActions } from 'src/stores';
import { CreateInvoiceModalProps } from './InvoiceHistory.types';

export const CreateInvoiceModal: React.FC<CreateInvoiceModalProps> = ({
  isOpen,
  cancelButtonAction,
  companyId,
}) => {
  const { t } = useTranslation();
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [createInvoice] = useCreateInvoice({
    onComplete: res => {
      if (res) {
        cancelButtonAction();
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.success,
          message: t('alerts.createInvoiceHistory.success'),
        });
      }
    },
    onError: () => {
      cancelButtonAction();
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.companyDetailsScreen.error'),
      });
    },
  });

  const doCreateInvoice = () => {
    return createInvoice({ variables: { companyId } });
  };

  return (
    <Dialog open={isOpen} onClose={cancelButtonAction} fullWidth maxWidth="sm">
      <div>
        <Grid style={{ minHeight: 50 }}>
          <DialogTitle>
            <Trans>companyInvoiceHistory.modal.title</Trans>
          </DialogTitle>
        </Grid>

        <DialogActions>
          <Button onClick={cancelButtonAction} color="primary" autoFocus>
            <Trans>generic.cancel</Trans>
          </Button>
          <Button onClick={doCreateInvoice} color="primary" autoFocus variant="contained">
            <Trans>generic.create</Trans>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
