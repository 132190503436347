import { ManagerInvite, ManagerInviteResponse } from 'src/DTO/ManagerInvite.type';
import { ManagerProfile } from 'src/DTO/ManagerProfile.type';
import { useApi } from './useApi';
import { BaseRequest, BaseHookRequest } from './useApi.types';
import {
  GetManagerByIdPayload,
  GetManagersParams,
  InviteManagerPayload,
  ResendManagerInvitePayload,
  UpdateManagerPayload,
} from './useManager.types';

export const useGetManagers: BaseHookRequest<ManagerProfile[], GetManagersParams | undefined> =
  params => {
    const [{ postRequest }, state] = useApi<ManagerProfile[]>(params);
    const getManagers = () => {
      return postRequest('/admin/managerProfile');
    };
    return [getManagers, state];
  };

export const useGetManagerById: BaseHookRequest<ManagerProfile, GetManagerByIdPayload> = params => {
  const [{ getRequest }, state] = useApi<ManagerProfile>(params);
  const getManager: BaseRequest<GetManagerByIdPayload> = details =>
    getRequest<GetManagerByIdPayload>(`/admin/managerProfile/${details?.variables.id}`);
  return [getManager, state];
};

export const useInviteManager: BaseHookRequest<ManagerInviteResponse, InviteManagerPayload> =
  params => {
    const [{ postRequest }, state] = useApi<ManagerInviteResponse>(params);
    const inviteManager: BaseRequest<InviteManagerPayload> = details =>
      postRequest<InviteManagerPayload>('/admin/managerInvite', details?.variables);
    return [inviteManager, state];
  };

export const useGetManagerInvites: BaseHookRequest<ManagerInvite[], undefined> = params => {
  const [{ getRequest }, state] = useApi<ManagerInvite[]>(params);
  const getManagerInvites: BaseRequest<undefined> = () => getRequest('/admin/managerInvite');
  return [getManagerInvites, state];
};

export const useResendManagerInvite: BaseHookRequest<
  ManagerInviteResponse,
  ResendManagerInvitePayload
> = params => {
  const [{ getRequest }, state] = useApi<ManagerInviteResponse>(params);
  const getManagerInvites: BaseRequest<ResendManagerInvitePayload> = details =>
    getRequest(`/admin/managerInvite/resend/${details?.variables.id}`);
  return [getManagerInvites, state];
};

export const useUpdateManager: BaseHookRequest<ManagerProfile, UpdateManagerPayload> = params => {
  const [{ postRequest }, state] = useApi<ManagerProfile>(params);
  const updateManager: BaseRequest<UpdateManagerPayload> = details => {
    return postRequest<UpdateManagerPayload>('/admin/managerProfile/update', {
      updatedManagerData: details?.variables.updatedManagerData,
    });
  };
  return [updateManager, state];
};
