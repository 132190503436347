import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { PageHeader } from 'src/components/PageHeader/PageHeader';
import { CompanyDetailsParams } from 'src/pages/CompanyDetails/CompanyDetails.types';
import { useGetCompanyAccount } from 'src/api/hooks/useCompany';
import { Box, Grid, Card, CardContent, Typography } from '@mui/material';

import { TransactionCard } from './TransactionCard';
import { CompanyAccountState } from './CompanyAccount.types';
import { TransactionModal } from './TransactionModal';
import { useStyles } from './CompanyAccount.styles';

export const CompanyAccount: React.FC = () => {
  const { t } = useTranslation();
  const { highLightedText } = useStyles();
  const { companyId } = useParams() as CompanyDetailsParams;
  const [transModalOpen, setTransModalOpen] = useState<boolean>(false);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);
  const [companyAccount, setCompanyAccount] = useState<CompanyAccountState>();

  const [getCompanyAccount] = useGetCompanyAccount({
    onComplete: res => {
      if (res) {
        setCompanyAccount(res as CompanyAccountState);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.account.error'),
      });
    },
  });

  useEffect(() => {
    getCompanyAccount({ variables: { id: companyId } });
  }, []);

  const closeModal = () => {
    setTransModalOpen(false);
    getCompanyAccount({ variables: { id: companyId } });
  };

  return (
    <>
      <PageHeader
        pageTitle={t('companyAccount.title')}
        buttons={[
          {
            buttonLabel: t('companyAccount.addTransaction'),
            onClick: () => setTransModalOpen(true),
            disabled: false,
          },
        ]}
      />
      {companyAccount && (
        <>
          <Grid item xs={12}>
            <Box mb={2}>
              <Card onClick={() => console.log()}>
                <CardContent>
                  <Grid container>
                    <Box>
                      <Typography className={highLightedText}>
                        <Trans i18nKey="companyAccount.balance">
                          {{
                            balance: Number(companyAccount.account?.balance).toLocaleString(
                              'HU-hu',
                            ),
                          }}
                        </Trans>
                      </Typography>
                    </Box>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Typography className={highLightedText}>
            <Trans>companyAccount.transaction</Trans>
          </Typography>
          {companyAccount.account &&
            companyAccount.account.transfer &&
            companyAccount.account.transfer.map(transaction => (
              <TransactionCard
                key={transaction.id}
                id={transaction.id}
                description={transaction.description}
                amount={transaction.amount}
                metadata={transaction.metadata}
                createdAt={transaction.createdAt}
              />
            ))}
        </>
      )}
      {companyAccount && companyAccount.account && (
        <TransactionModal
          isOpen={transModalOpen}
          cancelButtonAction={closeModal}
          accountId={companyAccount.account.id}
        />
      )}
    </>
  );
};
