import React, { useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, Grid, LinearProgress, Typography } from '@mui/material';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { useGetCompanyInvoiceHistory, useGetCompanyNotInvoiced } from 'src/api/hooks/useCompany';
import { useStoreActions } from 'src/stores';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { CompanyDetailsParams } from 'src/pages/CompanyDetails/CompanyDetails.types';
import { PageHeader } from 'src/components/PageHeader/PageHeader';
import { JobConfirmation } from 'src/DTO/JobConfirmation.type';
import { InvoiceHistoryDataType } from './InvoiceHistory.types';
import { CreateInvoiceModal } from './CreateInvoiceModal';
import { ConfirmationCard } from './ConfirmationCard';

export const InvoiceHistory: React.FC = () => {
  const { t } = useTranslation();
  const { companyId } = useParams() as CompanyDetailsParams;
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);
  const [dataType, setDataType] = useState<InvoiceHistoryDataType>('history');
  const [year, setYear] = useState<number>(2021);
  const [month, setMonth] = useState<number>(1);
  const [jobConfirmations, setJobConfirmations] = useState<JobConfirmation[]>([]);
  const [notInvoicedConfirmations, setNotInvoicedConfirmations] = useState<JobConfirmation[]>([]);
  const [zeroHourConfirmations, setZeroHourConfirmations] = useState<JobConfirmation[]>([]);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);

  const [getCompanyInvoiceHistory, { loading }] = useGetCompanyInvoiceHistory({
    onComplete: res => {
      if (res) {
        setJobConfirmations(res);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.companyDetailsScreen.error'),
      });
    },
  });

  const [getCompanyNotInvoiced, { loading: notInvoicedLoading }] = useGetCompanyNotInvoiced({
    onComplete: res => {
      if (res) {
        setNotInvoicedConfirmations(res.filter(({ hoursWorked }) => Number(hoursWorked) !== 0));
        setZeroHourConfirmations(res.filter(({ hoursWorked }) => Number(hoursWorked) === 0));
        setCreateModalOpen(false);
      }
    },
    onError: () => {
      setCreateModalOpen(false);
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.createInvoiceHistory.error'),
      });
    },
  });

  useEffect(() => {
    getCompanyInvoiceHistory({ variables: { id: companyId, month, year } });
  }, [year, month]);

  useEffect(() => {
    getCompanyNotInvoiced({ variables: { id: companyId } });
  }, []);

  const openCreateInvoiceModal = () => {
    const notClosed = notInvoicedConfirmations.find(
      jobConfirmation => jobConfirmation.closedBy === null,
    );
    if (!notClosed) {
      return setCreateModalOpen(true);
    }
    setPopUpData({
      isOpen: true,
      type: PopUpEnum.error,
      message: t('alerts.notClosedJobConfirmation.error'),
    });
  };

  if (loading || notInvoicedLoading) return <LinearProgress />;

  return (
    <>
      <PageHeader
        pageTitle={t('companyInvoiceHistory.title')}
        buttons={[
          {
            buttonLabel: t('companyInvoiceHistory.buttonLabel'),
            onClick: openCreateInvoiceModal,
            disabled: notInvoicedConfirmations.length === 0,
          },
        ]}
      />
      <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
        <ButtonGroup color="primary">
          <Button
            onClick={() => setDataType('history')}
            variant={dataType === 'history' ? 'contained' : 'outlined'}>
            <Trans>companyInvoiceHistory.history</Trans>
          </Button>
          <Button
            onClick={() => setDataType('zero')}
            variant={dataType === 'zero' ? 'contained' : 'outlined'}>
            <Trans i18nKey="companyInvoiceHistory.zero">
              {{ numberOfZeroConfs: zeroHourConfirmations.length }}
            </Trans>
          </Button>
          <Button
            onClick={() => setDataType('outstanding')}
            variant={dataType === 'outstanding' ? 'contained' : 'outlined'}>
            <Trans i18nKey="companyInvoiceHistory.outstanding">
              {{ numberOfOutstandingConfs: notInvoicedConfirmations.length }}
            </Trans>
          </Button>
        </ButtonGroup>
      </Box>
      {dataType === 'history' && (
        <Box>
          <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
            <ButtonGroup color="primary">
              <Button
                onClick={() => setYear(2021)}
                variant={year === 2021 ? 'contained' : 'outlined'}>
                2021
              </Button>
              <Button
                onClick={() => setYear(2022)}
                variant={year === 2022 ? 'contained' : 'outlined'}>
                2022
              </Button>
              <Button
                onClick={() => setYear(2023)}
                variant={year === 2023 ? 'contained' : 'outlined'}>
                2023
              </Button>
            </ButtonGroup>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
            <ButtonGroup color="primary">
              <Button onClick={() => setMonth(0)} variant={month === 0 ? 'contained' : 'outlined'}>
                <Trans>generic.january</Trans>
              </Button>
              <Button onClick={() => setMonth(1)} variant={month === 1 ? 'contained' : 'outlined'}>
                <Trans>generic.february</Trans>
              </Button>
              <Button onClick={() => setMonth(2)} variant={month === 2 ? 'contained' : 'outlined'}>
                <Trans>generic.march</Trans>
              </Button>
              <Button onClick={() => setMonth(3)} variant={month === 3 ? 'contained' : 'outlined'}>
                <Trans>generic.april</Trans>
              </Button>
              <Button onClick={() => setMonth(4)} variant={month === 4 ? 'contained' : 'outlined'}>
                <Trans>generic.may</Trans>
              </Button>
              <Button onClick={() => setMonth(5)} variant={month === 5 ? 'contained' : 'outlined'}>
                <Trans>generic.june</Trans>
              </Button>
              <Button onClick={() => setMonth(6)} variant={month === 6 ? 'contained' : 'outlined'}>
                <Trans>generic.july</Trans>
              </Button>
              <Button onClick={() => setMonth(7)} variant={month === 7 ? 'contained' : 'outlined'}>
                <Trans>generic.august</Trans>
              </Button>
              <Button onClick={() => setMonth(8)} variant={month === 8 ? 'contained' : 'outlined'}>
                <Trans>generic.september</Trans>
              </Button>
              <Button onClick={() => setMonth(9)} variant={month === 9 ? 'contained' : 'outlined'}>
                <Trans>generic.october</Trans>
              </Button>
              <Button
                onClick={() => setMonth(10)}
                variant={month === 10 ? 'contained' : 'outlined'}>
                <Trans>generic.november</Trans>
              </Button>
              <Button
                onClick={() => setMonth(11)}
                variant={month === 11 ? 'contained' : 'outlined'}>
                <Trans>generic.december</Trans>
              </Button>
            </ButtonGroup>
          </Box>
          {jobConfirmations.length > 0 && (
            <>
              <Grid container spacing={2} style={{ padding: 20 }}>
                {jobConfirmations.map(jobConfirmation => (
                  <Grid item xs={3} key={jobConfirmation.id}>
                    <ConfirmationCard
                      title={jobConfirmation.worker.name}
                      actionButtonTitle={t('companyInvoiceHistory.confCard.button')}
                      action={() => window.open(`/confirmations/${jobConfirmation.id}`, '_blank')}
                      infoToDisplay={[
                        {
                          title: t('companyInvoiceHistory.confCard.hoursWorked'),
                          value: jobConfirmation.hoursWorked.toString(),
                        },
                        {
                          title: t('companyInvoiceHistory.confCard.closedBy'),
                          value:
                            jobConfirmation.closedBy === null
                              ? 'Not closed'
                              : jobConfirmation.closedBy,
                        },
                        {
                          title: t('companyInvoiceHistory.confCard.totalInvoicedAmount'),
                          value: jobConfirmation.wages?.totalInvoicedAmount || 0,
                        },
                        {
                          title: t('companyInvoiceHistory.confCard.totalGrossWage'),
                          value: jobConfirmation.wages?.totalGrossAmount || 0,
                        },
                        {
                          title: t('companyInvoiceHistory.confCard.totalNetWage'),
                          value: jobConfirmation.wages?.totalNetAmount || 0,
                        },
                        {
                          title: t('companyInvoiceHistory.confCard.rake'),
                          value: jobConfirmation.wages?.rake || 0,
                        },
                        {
                          title: t('companyInvoiceHistory.confCard.tax'),
                          value: jobConfirmation.wages?.tax || 0,
                        },
                      ]}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {!jobConfirmations.length && (
            <Box display="flex" alignItems="center" justifyContent="center" mb={2} marginTop={20}>
              <Typography style={{ fontSize: 20 }}>
                <Trans>companyInvoiceHistory.noInvoice</Trans>
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {dataType === 'zero' && (
        <>
          <Grid container spacing={2} style={{ padding: 20 }}>
            {zeroHourConfirmations.length > 0 &&
              zeroHourConfirmations.map(jobConfirmation => (
                <Grid item xs={3} key={jobConfirmation.id}>
                  <ConfirmationCard
                    title={jobConfirmation.worker.name}
                    actionButtonTitle={t('companyInvoiceHistory.confCard.button')}
                    action={() => window.open(`/confirmations/${jobConfirmation.id}`, '_blank')}
                    infoToDisplay={[
                      {
                        title: t('companyInvoiceHistory.confCard.hoursWorked'),
                        value: jobConfirmation.hoursWorked.toString(),
                      },
                      {
                        title: t('companyInvoiceHistory.confCard.closedBy'),
                        value:
                          jobConfirmation.closedBy === null
                            ? 'Not closed'
                            : jobConfirmation.closedBy,
                      },
                      {
                        title: t('companyInvoiceHistory.confCard.totalInvoicedAmount'),
                        value: jobConfirmation.wages?.totalInvoicedAmount || 0,
                      },
                      {
                        title: t('companyInvoiceHistory.confCard.totalGrossWage'),
                        value: jobConfirmation.wages?.totalGrossAmount || 0,
                      },
                      {
                        title: t('companyInvoiceHistory.confCard.totalNetWage'),
                        value: jobConfirmation.wages?.totalNetAmount || 0,
                      },
                      {
                        title: t('companyInvoiceHistory.confCard.rake'),
                        value: jobConfirmation.wages?.rake || 0,
                      },
                      {
                        title: t('companyInvoiceHistory.confCard.tax'),
                        value: jobConfirmation.wages?.tax || 0,
                      },
                    ]}
                  />
                </Grid>
              ))}
          </Grid>
        </>
      )}
      {dataType === 'outstanding' && (
        <>
          <Grid container spacing={2} style={{ padding: 20 }}>
            {notInvoicedConfirmations.length > 0 && (
              <Grid item xs={3}>
                <ConfirmationCard
                  title="Summary"
                  actionButtonTitle="Create invoice"
                  action={openCreateInvoiceModal}
                  infoToDisplay={[
                    {
                      title: t('total items'),
                      value: notInvoicedConfirmations.length,
                    },
                    {
                      title: t('total invoiced amount'),
                      value: notInvoicedConfirmations
                        .reduce((acc, conf) => {
                          if (conf.wages) {
                            return acc + conf.wages.totalInvoicedAmount;
                          }
                          return acc;
                        }, 0)
                        .toLocaleString('hu-HU'),
                    },
                    {
                      title: t('total gross amount'),
                      value: notInvoicedConfirmations
                        .reduce((acc, conf) => {
                          if (conf.wages) {
                            return acc + conf.wages.totalGrossAmount;
                          }
                          return acc;
                        }, 0)
                        .toLocaleString('hu-HU'),
                    },
                    {
                      title: t('total net amount'),
                      value: notInvoicedConfirmations
                        .reduce((acc, conf) => {
                          if (conf.wages) {
                            return acc + conf.wages.totalNetAmount;
                          }
                          return acc;
                        }, 0)
                        .toLocaleString('hu-HU'),
                    },
                  ]}
                />
              </Grid>
            )}
            {notInvoicedConfirmations.length > 0 &&
              notInvoicedConfirmations.map(jobConfirmation => (
                <Grid item xs={3} key={jobConfirmation.id}>
                  <ConfirmationCard
                    title={jobConfirmation.worker.name}
                    actionButtonTitle={t('companyInvoiceHistory.confCard.button')}
                    action={() => window.open(`/confirmations/${jobConfirmation.id}`, '_blank')}
                    infoToDisplay={[
                      {
                        title: t('companyInvoiceHistory.confCard.hoursWorked'),
                        value: jobConfirmation.hoursWorked.toString(),
                      },
                      {
                        title: t('companyInvoiceHistory.confCard.closedBy'),
                        value:
                          jobConfirmation.closedBy === null
                            ? 'Not closed'
                            : jobConfirmation.closedBy,
                      },
                      {
                        title: t('companyInvoiceHistory.confCard.totalInvoicedAmount'),
                        value:
                          jobConfirmation.wages?.totalInvoicedAmount.toLocaleString('hu-HU') || 0,
                      },
                      {
                        title: t('companyInvoiceHistory.confCard.totalGrossWage'),
                        value: jobConfirmation.wages?.totalGrossAmount.toLocaleString('hu-HU') || 0,
                      },
                      {
                        title: t('companyInvoiceHistory.confCard.totalNetWage'),
                        value: jobConfirmation.wages?.totalNetAmount.toLocaleString('hu-HU') || 0,
                      },
                      {
                        title: t('companyInvoiceHistory.confCard.rake'),
                        value: jobConfirmation.wages?.rake || 0,
                      },
                      {
                        title: t('companyInvoiceHistory.confCard.tax'),
                        value: jobConfirmation.wages?.tax || 0,
                      },
                    ]}
                  />
                </Grid>
              ))}
          </Grid>
          {!notInvoicedConfirmations.length && (
            <Box display="flex" alignItems="center" justifyContent="center" mb={2} marginTop={20}>
              <Typography style={{ fontSize: 20 }}>
                <Trans>companyInvoiceHistory.noOutstanding</Trans>
              </Typography>
            </Box>
          )}
        </>
      )}
      <CreateInvoiceModal
        isOpen={createModalOpen}
        companyId={companyId}
        cancelButtonAction={() => setCreateModalOpen(false)}
        notInvoicedConfirmations={notInvoicedConfirmations}
      />
    </>
  );
};
