import React, { useEffect, useState } from 'react';
import { ManagerProfile } from 'src/DTO/ManagerProfile.type';
import { Box, Button, Grid, Typography } from '@mui/material';
import { LockOpen, NewReleases } from '@mui/icons-material';
import { useGetManagers } from 'src/api/hooks/useManager';
import { useStoreActions } from 'src/stores';
import { useTranslation, TFunction } from 'react-i18next';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { ManagerInviteModal } from 'src/pages/ManagerInvites/ManagerInviteModal';
import { formatDate } from 'src/utils/formatDate';
import { useRequestManagerTokenLogin } from 'src/api/hooks/useAuth';
import { getConfig } from 'src/configs/config';
import {
  DataGrid,
  GridToolbar,
  GridRenderCellParams,
  GridActionsCellItem,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';

const headers = (t: TFunction, loginToManager: any) => {
  return [
    {
      headerName: t('managerProfilesScreen.headers.id'),
      field: 'id',
      sorting: false,
      flex: 1,
      hide: true,
    },
    { headerName: t('managerProfilesScreen.headers.name'), field: 'name', flex: 1 },
    { headerName: t('managerProfilesScreen.headers.email'), field: 'email', flex: 1 },
    {
      headerName: t('managerProfilesScreen.headers.phoneNumber'),
      field: 'phoneNumber',
      flex: 1,
    },
    {
      headerName: t('generic.updatedAt'),
      field: 'updatedAt',
      flex: 1,
      render: (rowData: ManagerProfile) => <Typography>{formatDate(rowData.updatedAt)}</Typography>,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      flex: 1,
      filterable: false,
      getActions: (params: GridRenderCellParams<Date>) => [
        <GridActionsCellItem
          key={1}
          showInMenu
          onClick={() => {
            loginToManager(params.row.id);
          }}
          icon={<LockOpen />}
          label="Login as Manager"
        />,
      ],
    },
  ];
};

export const ManagerProfileList: React.FC = () => {
  const { t } = useTranslation();
  const [managerProfiles, setManagerProfiles] = useState<ManagerProfile[] | undefined>(undefined);
  const [inviteModalOpen, setInviteModalOpen] = useState<boolean>(false);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [getManagers] = useGetManagers({
    onComplete: res => {
      if (res) {
        setManagerProfiles(res);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.managerProfileListScreen.error'),
      });
    },
  });

  useEffect(() => {
    getManagers();
  }, []);

  const navigateToDetails = (id: string) => {
    return window.open(`/manager-profile/${id}`, '_blank');
  };

  const [requestToken, { loading }] = useRequestManagerTokenLogin({
    onComplete: res => {
      if (res && res.isSuccess) {
        window.open(`${getConfig().managerUrl}/tokenAuth/${res.token}`);
      } else {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.error,
          message: t('alerts.managerProfileListScreen.errorToken'),
        });
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.managerProfileListScreen.errorToken'),
      });
    },
  });

  const loginToManager = (id: string) => {
    requestToken({
      variables: { managerId: id },
    });
  };

  return (
    <Grid style={{ width: '100%' }}>
      <DataGrid
        autoHeight
        components={{
          Toolbar: () => {
            return (
              <Box mt={1} ml={1}>
                <Grid spacing={2} container>
                  <Grid item xs={6}>
                    <GridToolbar
                      componentsProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item textAlign="right" xs={6}>
                    <GridToolbarQuickFilter />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setInviteModalOpen(true);
                      }}
                      // disabled={!(selectedWorkers.length > 0) || isLoading}
                      startIcon={<NewReleases />}>
                      {t('managerProfilesScreen.inviteButtonLabel')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            );
          },
        }}
        autoPageSize
        rows={managerProfiles ? managerProfiles : []}
        columns={headers(t, loginToManager)}
        loading={!managerProfiles || loading}
        onRowClick={data => {
          navigateToDetails(data.row.id);
        }}
        pageSize={100}
        rowsPerPageOptions={[5, 20]}
      />
      <ManagerInviteModal
        isOpen={inviteModalOpen}
        cancelButtonAction={() => setInviteModalOpen(false)}
      />
    </Grid>
  );
};
