import { makeStyles } from '@mui/styles';
import { theme } from 'src/utils/theme';

export const useStyles = makeStyles(() => ({
  pageTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(6),
  },
  titleContainer: {
    width: '30%',
  },
  button: {
    width: 220,
    height: 40,
    margin: 6,
    fontSize: 14,
  },
  title: {
    fontSize: 24,
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '70%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));
