import { Transaction } from 'src/DTO/Transaction.type';
import { useApi } from './useApi';
import { BaseHookRequest, BaseRequest } from './useApi.types';
import {
  AddTransactionPayload,
  GetCompanyAccountResponse,
  TransactionsByIdPayload,
  TransactionsPayload,
} from './useFinance.types';

export const useCompanyAccount: BaseHookRequest<GetCompanyAccountResponse, null> = params => {
  const [{ getRequest }, state] = useApi<GetCompanyAccountResponse>(params);
  const getCompanyAccount = () => getRequest('manager/company/transfer/total');
  return [getCompanyAccount, state];
};

export const useCompanyTransactions: BaseHookRequest<Transaction[], TransactionsPayload> =
  params => {
    const [{ getRequest }, state] = useApi<Transaction[]>(params);
    const getCompanyTransactions: BaseRequest<TransactionsPayload> = details =>
      getRequest<TransactionsPayload>(
        `manager/company/transfer/last/${details?.variables.skip}/${details?.variables.limit}`,
      );
    return [getCompanyTransactions, state];
  };

export const useGetAllTransactions: BaseHookRequest<Transaction[], null> = params => {
  const [{ getRequest }, state] = useApi<Transaction[]>(params);
  const getAllTransactions = () => getRequest<null>('admin/transfer');
  return [getAllTransactions, state];
};

export const useGetTransactionById: BaseHookRequest<Transaction, TransactionsByIdPayload> =
  params => {
    const [{ getRequest }, state] = useApi<Transaction>(params);
    const getCompany: BaseRequest<TransactionsByIdPayload> = details =>
      getRequest<TransactionsByIdPayload>(`/admin/transfer/${details?.variables.id}`);
    return [getCompany, state];
  };

export const useAddTransaction: BaseHookRequest<Transaction, AddTransactionPayload> = params => {
  const [{ postRequest }, state] = useApi<Transaction>(params);
  const addTransaction: BaseRequest<AddTransactionPayload> = details => {
    return details
      ? postRequest<AddTransactionPayload>('/admin/transfer', { ...details.variables })
      : undefined;
  };
  return [addTransaction, state];
};
