import { makeStyles } from '@mui/styles';
import { theme } from 'src/utils/theme';

export const useStyles = makeStyles(() => ({
  fastInfoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(6),
  },
  fastInfoTitle: {
    paddingRight: 10,
  },
  fastInfoItem: {
    marginLeft: theme.spacing(1),
  },
}));
