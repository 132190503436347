import moment from 'moment';

export const validateTaxNumber = (taxNumber: string) => {
  const pureNumber = taxNumber
    .replace(/[^\w\s]/gi, '')
    .trim()
    .replace(/\s/g, '');

  const numberArray = pureNumber.split('');
  if (numberArray.length !== 10) return false;

  let amount = 0;
  numberArray.map((val, index) => {
    if (index === 9) return;
    amount += (index + 1) * Number(val);
  });

  if (amount % 11 === 10) return false;
  if (amount % 11 === Number(numberArray[9])) {
    return true;
  } else {
    return false;
  }
};

export const validateStudentNumber = (studentNumber: string) => {
  const pureNumber = studentNumber
    .replace(/[^\w\s]/gi, '')
    .trim()
    .replace(/\s/g, '');

  const numberArray = pureNumber.split('');
  if (numberArray.length !== 11) return false;

  let amount = 0;
  numberArray.map((val, index) => {
    if (index === 10) return;
    amount += (index + 1) * Number(val);
  });

  if (amount % 11 === 10) return false;
  if (amount % 11 === Number(numberArray[10])) {
    return true;
  } else {
    return false;
  }
};

export const compareTaxNumbersWithBirthDay = (taxNumber: string, birthDate: string) => {
  const diffBirthDay = moment(birthDate).diff(moment('1867-01-01'), 'days');
  const taxDays = taxNumber.substring(1, 6);

  if (diffBirthDay === parseInt(taxDays)) return true;
  else return false;
};

export const validateSocialSecurityNumber = (secNumber: string) => {
  const pureNumber = secNumber
    .replace(/[^\w\s]/gi, '')
    .trim()
    .replace(/\s/g, '');

  const numberArray = pureNumber.split('');
  if (numberArray.length !== 9) return false;

  const even =
    (Number(numberArray[1]) +
      Number(numberArray[3]) +
      Number(numberArray[5]) +
      Number(numberArray[7])) *
    7;
  const odd =
    (Number(numberArray[0]) +
      Number(numberArray[2]) +
      Number(numberArray[4]) +
      Number(numberArray[6])) *
    3;

  if ((even + odd) % 10 === Number(numberArray[8])) return true;
  else return false;
};
