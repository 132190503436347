import React, { useState, useEffect } from 'react';
import { Alert, Grid } from '@mui/material';
import { useStoreActions } from 'src/stores';
import { useTranslation, TFunction } from 'react-i18next';
import { PopUpEnum } from 'src/stores/NotificationStore';
import {
  DataGrid,
  GridFilterModel,
  GridRenderCellParams,
  GridToolbar,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { isEmpty, isNull } from 'lodash';
import {
  useGetDocumentsRequests,
  useGetSignedDocumentUrls,
  useReviewDocument,
} from 'src/api/hooks/useDocument';
import { GetDocumentResponse } from 'src/api/hooks/useDocument.types';
import { formatDate } from 'src/utils/formatDate';
import { SwipableDocumentDetails } from './SwipableDocumentDetail';
import { Block, Check, Timer } from '@mui/icons-material';
import { DocumentStatus } from 'src/DTO/DocumentStatus.enum';
import moment from 'moment';

const headers = (t: TFunction) => {
  return [
    {
      headerName: t('documentListScreen.headers.id'),
      field: 'id',
      sorting: false,
      hide: true,
      flex: 1,
    },
    {
      headerName: t('documentListScreen.headers.workerName'),
      field: 'workerName',
      flex: 1,
    },
    {
      headerName: t('documentListScreen.headers.managerName'),
      field: 'managerName',
      flex: 1,
    },
    {
      headerName: t('documentListScreen.headers.companyName'),
      field: 'companyName',
      flex: 1,
    },
    {
      headerName: t('documentListScreen.headers.title'),
      field: 'title',
      flex: 1,
    },
    {
      headerName: t('documentListScreen.headers.status'),
      field: 'status',
      renderCell: (params: GridRenderCellParams<DocumentStatus>) => {
        switch (params.value) {
          case DocumentStatus.Approved:
            return <Check />;

          case DocumentStatus.Declined:
            return <Block />;

          case DocumentStatus.Pending:
            return <Timer />;

          default:
            break;
        }
      },
      flex: 1,
    },

    {
      headerName: t('documentListScreen.headers.expires'),
      field: 'expires',
      renderCell: (params: GridRenderCellParams<string>) => {
        if (['StudentId', 'DoctorsPaper', 'StudentStatusVerification'].includes(params.row.type)) {
          if (!params.value || isNull(params.value)) {
            return <Alert color="warning">No date</Alert>;
          }
          if (moment(params.value) < moment()) {
            return <Alert color="error">Expired</Alert>;
          }
          if (moment(params.value) >= moment()) {
            return <Alert color="success">Valid</Alert>;
          }
        } else {
          return <Alert color="success">Never</Alert>;
        }
      },
      flex: 1,
    },

    {
      headerName: t('generic.updatedAt'),
      field: 'createdAt',
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams<GetDocumentResponse>) =>
        formatDate(row.createdAt),
    },
  ];
};

export const DocumentList: React.FC = () => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState<GetDocumentResponse[] | undefined>(undefined);
  const [selectedDocument, setSelectedDocument] = useState<GetDocumentResponse | undefined>(
    undefined,
  );
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [reviewDocument] = useReviewDocument({
    onComplete: res => {
      if (res && res.isSuccess) {
        console.log('reviewDocument - res', res);
        setSelectedDocument(undefined);
        getDocuments();
      } else {
        alert('FAIL !!!!');
      }
    },
    onError: err => {
      console.error(err);
      alert('FAIL !!!!');
    },
  });

  const [getDocuments] = useGetDocumentsRequests({
    onComplete: res => {
      if (res) {
        setDocuments(res.documents);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('generic.error'),
      });
    },
  });

  useEffect(() => {
    getDocuments();
  }, []);

  const { entity, id } = useParams() as { entity: string; id: string };

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const [getSignedUrls, { data: signedUrls }] = useGetSignedDocumentUrls();

  useEffect(() => {
    if (!isEmpty(id) && entity === 'company') {
      setFilterModel({
        items: [
          {
            columnField: 'role.company.id',
            operatorValue: 'contains',
            value: id,
          },
        ],
      });
    }
    if (!isEmpty(id) && entity === 'worker') {
      setFilterModel({
        items: [
          {
            columnField: 'worker.ids',
            operatorValue: 'contains',
            value: id,
          },
        ],
      });
    }
  }, [id]);

  return (
    <Grid style={{ width: '100%' }}>
      <DataGrid
        autoHeight
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        autoPageSize
        filterModel={filterModel}
        onFilterModelChange={newFilterModel => setFilterModel(newFilterModel)}
        rows={documents ? documents : []}
        columns={headers(t)}
        loading={!documents}
        pageSize={100}
        onRowClick={data => {
          setSelectedDocument(data.row as GetDocumentResponse);
          getSignedUrls({
            variables: {
              id: data.row.id,
            },
          });
        }}
        rowsPerPageOptions={[5, 20]}
      />
      <SwipableDocumentDetails
        data={selectedDocument}
        loading={false}
        reviewDocument={reviewDocument}
        signedUrls={signedUrls?.signedUrls || []}
        onClose={() => setSelectedDocument(undefined)}
      />
      {/* <DocumentUploadModal
        isOpen={true}
        cancelButtonAction={() => {
          console.log('asd');
        }}
      /> */}
    </Grid>
  );
};
