import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  dialogContainer: {
    width: 600,
  },
  titleContainer: {
    paddingBottom: 20,
  },
  contentContainer: {
    paddingBottom: 20,
  },
}));
