import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useFormInputFieldStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginBottom: '15px',
  },
  root: {
    border: 'none',
    padding: theme.spacing(1, 0),
  },
  label: {
    color: 'rgba(0, 0, 0, 0.5)',
    '&:focused': {
      color: 'rgba(0, 0, 0, 0.5)',
    },
  },
}));
