import React from 'react';
import { TFunction } from 'react-i18next';
import { formatDate } from '../../utils/formatDate';
import { GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';
import { Check, Block } from '@mui/icons-material';

export const headers = (t: TFunction) => {
  return [
    { headerName: t('companyListScreen.headers.name'), field: 'name', flex: 1 },
    {
      headerName: t('companyListScreen.headers.status'),
      field: 'active',
      flex: 1,
      renderCell: (params: GridRenderCellParams<boolean>) => {
        return params.value ? <Check /> : <Block />;
      },
    },
    {
      headerName: t('companyListScreen.headers.rake'),
      field: 'rake',
      valueGetter: (params: GridValueGetterParams<string>) => {
        const value = Number(params.value);
        return isNaN(value) ? '' : `${Math.round(value * 100)}%`;
      },
      flex: 1,
    },
    {
      headerName: t('companyListScreen.headers.workerCount'),
      field: 'workerCount',
      valueGetter: (params: GridValueGetterParams<string>) => {
        const workers = params.row.workers || [];
        return `${workers.length || 'Workers Not Found'}`;
      },
      flex: 1,
    },
    {
      headerName: t('companyListScreen.headers.rolesCount'),
      field: 'roles',
      valueGetter: (params: GridValueGetterParams<string>) => {
        const roles = params.row.roles || [];
        return `${roles.length || 'No Job Roles Found'}`;
      },
      flex: 1,
    },
    {
      headerName: t('companyListScreen.headers.balance'),
      field: 'balance',
      valueGetter: (params: GridValueGetterParams<string>) => {
        const balance = params.row?.account?.balance;
        return `${balance ?? 'Not Available'}`;
      },
      flex: 1,
    },
    {
      headerName: t('companyListScreen.headers.vatNumber'),
      field: 'vatNumber',
      flex: 1,
    },
    {
      headerName: t('generic.updatedAt'),
      field: 'updatedAt',
      valueGetter: (params: GridValueGetterParams<Date>) => {
        const updatedAt = params.row.updatedAt;
        return updatedAt ? formatDate(updatedAt) : 'Date Not Found';
      },
      flex: 1,
    },
    {
      headerName: t('companyListScreen.headers.id'),
      field: 'id',
      sorting: false,
      flex: 1,
    },
  ];
};
