import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { PageHeader } from 'src/components/PageHeader/PageHeader';
import { Box, Grid, Card, CardContent, Typography } from '@mui/material';

import { TransactionCard } from './TransactionCard';
import { TransactionModal } from './TransactionModal';
import { useStyles } from './WorkerAccount.styles';
import { useGetWorkerAccount } from 'src/api/hooks/useWorker';
import { Account } from 'src/DTO/Account.type';
import { TransactionType, WorkerDetailsParams } from './WorkerAccount.types';

export const WorkerAccount: React.FC = () => {
  const { t } = useTranslation();
  const { highLightedText } = useStyles();
  const { workerId } = useParams() as WorkerDetailsParams;
  const [transModalOpen, setTransModalOpen] = useState<boolean>(false);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);
  const [workerAccount, setWorkerAccount] = useState<Account>();

  const [getworkerAccount] = useGetWorkerAccount({
    onComplete: res => {
      console.log('res', res);

      if (res) {
        setWorkerAccount(res);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.account.error'),
      });
    },
  });

  useEffect(() => {
    getworkerAccount({ variables: { id: workerId } });
  }, []);

  const closeModal = () => {
    setTransModalOpen(false);
    getworkerAccount({ variables: { id: workerId } });
  };

  const getDefaultTransactionType = () => {
    const balance = parseInt(workerAccount?.balance || '0');

    if (balance > 0) {
      return TransactionType.Charge;
    }

    return TransactionType.Credit;
  };

  return (
    <>
      <PageHeader
        pageTitle={t('workerAccount.title')}
        buttons={[
          {
            buttonLabel: t('workerAccount.addTransaction'),
            onClick: () => setTransModalOpen(true),
            disabled: false,
          },
        ]}
      />
      {workerAccount && (
        <>
          <Grid item xs={12}>
            <Box mb={2}>
              <Card onClick={() => console.log()}>
                <CardContent>
                  <Grid container>
                    <Box>
                      <Typography className={highLightedText}>
                        <Trans i18nKey="workerAccount.balance">
                          {{ balance: Number(workerAccount.balance).toLocaleString('HU-hu') }}
                        </Trans>
                      </Typography>
                    </Box>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Typography className={highLightedText}>
            <Trans>workerAccount.transaction</Trans>
          </Typography>
          {workerAccount &&
            workerAccount.transfer &&
            workerAccount.transfer.map(transaction => (
              <TransactionCard
                key={transaction.id}
                id={transaction.id}
                description={transaction.description}
                amount={transaction.amount}
                metadata={transaction.metadata}
                createdAt={transaction.createdAt}
              />
            ))}
        </>
      )}
      {workerAccount && (
        <TransactionModal
          isOpen={transModalOpen}
          cancelButtonAction={closeModal}
          accountId={workerAccount.id}
          defaultTransActionType={getDefaultTransactionType()}
          defaultTransActionValue={workerAccount.balance || '0'}
        />
      )}
    </>
  );
};
