import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { TextField, Select } from '@mui/material';

import { useStoreActions } from 'src/stores';
import { useSendMessage } from 'src/api/hooks/useNotifications';
import { convertTextToHtml } from 'src/utils/convertTextToHtml';
import { MessageCenterProps, MessageConfig } from './MessageCenterModal.types';
import { validationSchema } from './validationSchema';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { eventTypes } from 'src/locales/types/eventTypes';
import { messageTypes } from 'src/locales/types/messageTypes';

const initialMessageConfig = {
  textHu: '',
  textEn: '',
  titleHu: '',
  titleEn: '',
};

export const MessageCenterModal: React.FC<MessageCenterProps> = ({
  isOpen,
  closeModal,
  selectedWorkerIds,
}) => {
  const { t } = useTranslation();

  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const emptyPopUpData = useStoreActions(action => action.popUp.emptyPopUpData);

  const emptyingPopUp = () => {
    setTimeout(() => {
      emptyPopUpData();
    }, 3000);
  };

  const [selectedMessageType, setSelectedMessageType] = useState(messageTypes[0].value);
  const [selectedEventType, setSelectedEventType] = useState(eventTypes[0].key);
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setIsLoading] = useState(false);

  const [messageConfig, setMessageConfig] = useState(initialMessageConfig);

  const htmlContentHu = messageConfig.textHu && convertTextToHtml(messageConfig.textHu);
  const htmlContentEn = messageConfig.textEn && convertTextToHtml(messageConfig.textEn);

  const selectedEvent = eventTypes.find(event => event.key === selectedEventType);

  useEffect(() => {
    if (selectedEvent && selectedEvent.value) {
      const { push, email, sms } = selectedEvent.value;

      setMessageConfig(prev => ({
        ...prev,
        titleHu: email?.subject.hu || prev.titleHu,
        titleEn: email?.subject.en || prev.titleEn,
        textHu: push?.hu || email?.html.hu || sms?.hu || prev.textHu,
        textEn: push?.en || email?.html.en || sms?.en || prev.textEn,
      }));
    } else {
      setMessageConfig(initialMessageConfig);
    }
  }, [selectedEventType]);

  const initialMessageType = eventTypes
    .filter(eventType => eventType.key.includes(selectedMessageType))
    .map(selectItem => selectItem.key)[0];

  const handleNextNotificationStepSuccessMessage = () => {
    setPopUpData({
      isOpen: true,
      type: PopUpEnum.success,
      message: t('alerts.nextNotificationStep.success'),
    });
    emptyingPopUp();
  };

  const handleNextNotificationStepErrorMessage = () => {
    setPopUpData({
      isOpen: true,
      type: PopUpEnum.error,
      message: t('alerts.nextNotificationStep.error'),
    });
    emptyingPopUp();
  };

  const [sendNotification] = useSendMessage({
    onComplete: res => {
      setIsLoading(false);
      close();
      handleNextNotificationStepSuccessMessage();
      console.log('success', res);
    },
    onError: error => {
      setIsLoading(false);
      handleNextNotificationStepErrorMessage();
      console.log('error ', error);
    },
  });

  const handleConfirmClicked = async () => {
    const schema = validationSchema(selectedMessageType);

    try {
      await schema.validate(messageConfig, { abortEarly: false });

      setIsLoading(true);
      sendNotification({
        variables: {
          workerIds: selectedWorkerIds,
          //eventType: "",
          message: {
            ...messageConfig,
            htmlHu: convertTextToHtml(messageConfig.textHu),
            htmlEn: convertTextToHtml(messageConfig.textEn),
            type: selectedMessageType,
          },
        },
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = err.errors;
        setErrors(validationErrors);
      }
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    textType: keyof MessageConfig,
  ) => {
    const value = e.currentTarget.value;
    setMessageConfig(prevState => ({
      ...prevState,
      [textType]: value,
    }));
  };

  const close = () => {
    setIsLoading(false);
    setErrors([]);
    closeModal();
  };

  useEffect(() => {
    setMessageConfig(initialMessageConfig);
    setSelectedEventType(initialMessageType);
    setErrors([]);
  }, [selectedMessageType]);

  useEffect(() => {
    setErrors([]);
  }, [selectedEventType]);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="lg" onBackdropClick={close}>
      <DialogTitle>
        <Trans>{t('workerMessageCenterModalScreen.title')}</Trans>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <Select
                onChange={event => setSelectedMessageType(event.target.value)}
                value={selectedMessageType}>
                {messageTypes.map(selectItem => (
                  <MenuItem key={selectItem.type} value={selectItem.value}>
                    {selectItem.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <Select
                onChange={event => setSelectedEventType(event.target.value)}
                value={selectedEventType}>
                {eventTypes
                  .filter(eventType => eventType.key.includes(selectedMessageType))
                  .map(selectItem => (
                    <MenuItem key={selectItem.key} value={selectItem.key}>
                      {selectItem.key}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {errors.length > 0 && (
              <Grid item xs={12}>
                {errors.map((errorMsg, index) => (
                  <Typography key={index} color="error">
                    {errorMsg}
                  </Typography>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>

        {selectedMessageType === 'EMAIL' && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder={t('workerMessageCenterModalScreen.subjectHungarian')}
                onChange={e => handleChange(e, 'titleHu')}
                value={messageConfig.titleHu}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder={t('workerMessageCenterModalScreen.subjectEnglish')}
                onChange={e => handleChange(e, 'titleEn')}
                value={messageConfig.titleEn}
                fullWidth
              />
            </Grid>
          </Grid>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              placeholder={t('workerMessageCenterModalScreen.messageHungarian')}
              multiline
              minRows={10}
              maxRows={125}
              onChange={e => handleChange(e, 'textHu')}
              value={messageConfig.textHu}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div
              style={{
                border: '1px solid #919090',
                borderRadius: 1,
                padding: 5,
                minHeight: 230,
                whiteSpace: 'pre-wrap',
              }}
              dangerouslySetInnerHTML={{ __html: htmlContentHu }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              placeholder={t('workerMessageCenterModalScreen.messageEnglish')}
              multiline
              minRows={10}
              maxRows={100}
              onChange={e => handleChange(e, 'textEn')}
              value={messageConfig.textEn}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div
              style={{
                border: '1px solid #919090',
                borderRadius: 5,
                padding: 5,
                minHeight: 230,
                whiteSpace: 'pre-wrap',
              }}
              dangerouslySetInnerHTML={{ __html: htmlContentEn }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary" variant="outlined">
          <Trans>generic.cancel</Trans>
        </Button>
        <Button
          onClick={handleConfirmClicked}
          color="primary"
          variant="contained"
          autoFocus
          type="submit"
          disabled={loading}>
          {loading ? <CircularProgress size={24} /> : <Trans>generic.confirm</Trans>}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
