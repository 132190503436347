import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { useArchiveWorker, useExpireWorkerDocument, useGetWorkers } from 'src/api/hooks/useWorker';
import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';

import { DataGrid, GridFilterModel, GridSelectionModel } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { SwipableWorkerDetails } from './SwipableWorkerDetail';
import { headers } from './WorkerProfileList.header';
import { QuickFilterProps, WorkerProfileToolBar } from './WorkerProfileToolbar';
import { MessageCenterModal } from './MessageCenterModal';

export const WorkerProfileList: React.FC = () => {
  const { t } = useTranslation();
  const [selectedWorkers, setSelectedWorkers] = useState<string[]>([]);
  const [workerProfiles, setWorkerProfiles] = useState<WorkerProfile[] | undefined>(undefined);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);
  const { companyId } = useParams<{ companyId: string }>();
  const [isFilterBarOpen, setIsFilterBarOpen] = useState<boolean>(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState<boolean>(false);

  const [selectedWorker, setSelectedWorker] = useState<WorkerProfile | undefined>(undefined);
  const [quickFilter, setQuickFilter] = useState<QuickFilterProps>({
    waitingForDeclaration: false,
    waitingForForeignDeclaration: false,
  });

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  useEffect(() => {
    if (!isEmpty(companyId)) {
      setFilterModel({
        items: [
          {
            columnField: 'companies',
            operatorValue: 'contains',
            value: companyId,
          },
        ],
      });
    }
  }, [companyId]);

  const [getWorkers, { loading: getWorkerLoader }] = useGetWorkers({
    onComplete: res => {
      if (res) {
        setWorkerProfiles(res);
      } else {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.error,
          message: t('alerts.workerProfileListScreen.error'),
        });
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.workerProfileListScreen.error'),
      });
    },
  });

  useEffect(() => {
    getWorkers();
  }, []);

  const [expireWorkerDocs] = useExpireWorkerDocument({
    onComplete: () => {
      getWorkers();
      setSelectedWorker(undefined);
    },
  });

  const [archiveWorker] = useArchiveWorker({
    onComplete: res => {
      if (res && res.isSuccess) {
        getWorkers();
        setSelectedWorker(undefined);
      }

      if (res && !res.isSuccess) {
        alert(res.message || '');
      }
    },
    onError: () => alert('error'),
  });

  const isLoading = getWorkerLoader;

  const applyQuickfilter = (workerProfiles: WorkerProfile[]) => {
    let filteredProfiles: WorkerProfile[] = workerProfiles;
    if (quickFilter.waitingForDeclaration) {
      filteredProfiles = filteredProfiles.filter(i => i.isDeclared === false);
    }

    if (quickFilter.waitingForForeignDeclaration) {
      filteredProfiles = filteredProfiles.filter(
        i => i.isForeignDeclared === false && i.nationality !== 'HU',
      );
    }

    return filteredProfiles;
  };

  return (
    <Grid style={{ width: '100%' }}>
      <DataGrid
        autoHeight
        components={{
          Toolbar: () => (
            <WorkerProfileToolBar
              quickFilter={quickFilter}
              setQuickFilter={setQuickFilter}
              selectedWorkers={selectedWorkers}
              isFilterBarOpen={isFilterBarOpen}
              setIsFilterBarOpen={setIsFilterBarOpen}
              isMainLoading={isLoading}
              setIsNotificationModalOpen={setIsNotificationModalOpen}
              refreshData={() => getWorkers()}
            />
          ),
        }}
        autoPageSize
        rows={applyQuickfilter(workerProfiles ? workerProfiles : [])}
        columns={headers(t)}
        filterModel={filterModel}
        disableSelectionOnClick={true}
        onSelectionModelChange={(selectedWorkerIds: GridSelectionModel) => {
          setSelectedWorkers(selectedWorkerIds as string[]);
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        onFilterModelChange={newFilterModel => setFilterModel(newFilterModel)}
        loading={isLoading}
        onRowClick={data => {
          setSelectedWorker(data.row as WorkerProfile);
        }}
        pageSize={100}
        rowsPerPageOptions={[5, 20]}
        checkboxSelection
      />
      <SwipableWorkerDetails
        loading={isLoading}
        archiveWorker={archiveWorker}
        expireWorkerDocs={expireWorkerDocs}
        data={selectedWorker}
        onClose={() => setSelectedWorker(undefined)}
      />
      <MessageCenterModal
        isOpen={isNotificationModalOpen}
        closeModal={() => setIsNotificationModalOpen(false)}
        selectedWorkerIds={selectedWorkers}
      />
    </Grid>
  );
};
