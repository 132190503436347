import { useApi } from './useApi';
import { BaseRequest, BaseHookRequest } from './useApi.types';
import { GetNewStudentNotifierResponse, GetNewStudentNotifierPayload } from './useXMLService.types';

export const useGetNewStudentNotifierXML: BaseHookRequest<
  GetNewStudentNotifierResponse,
  GetNewStudentNotifierPayload
> = params => {
  const [{ postRequest }, state] = useApi<GetNewStudentNotifierResponse>(params);
  const generateStudentNotifier: BaseRequest<GetNewStudentNotifierPayload> = details => {
    return postRequest('/admin/xmlservice/studentnotifier/generate', details?.variables);
  };
  return [generateStudentNotifier, state];
};
