import { useApi } from './useApi';
import { BaseRequest, BaseHookRequest } from './useApi.types';
import {
  InterviewQuestion,
  SendInterviewLinkPayload,
  SendinterviewLinkResponse,
  UpdateInterviewQuestionsPayload,
} from './useInterview.types';

export const useSendInterviewLink: BaseHookRequest<
  SendinterviewLinkResponse,
  SendInterviewLinkPayload
> = params => {
  const [{ getRequest }, state] = useApi(params);
  const sendInterviewLink: BaseRequest<SendInterviewLinkPayload> = details =>
    getRequest<SendInterviewLinkPayload>(
      `/admin/workerProfile/interview/${details?.variables.workerId}`,
    );
  return [sendInterviewLink, state];
};

export const useUpdateInterviewQuestions: BaseHookRequest<
  InterviewQuestion[],
  UpdateInterviewQuestionsPayload
> = params => {
  const [{ postRequest }, state] = useApi(params);
  const updateInterviewQuestions: BaseRequest<UpdateInterviewQuestionsPayload> = details => {
    return details
      ? postRequest<UpdateInterviewQuestionsPayload>(
          '/admin/interview/questions/update',
          details.variables,
        )
      : undefined;
  };

  return [updateInterviewQuestions, state];
};

export const useGetInterviewQuestions: BaseHookRequest<InterviewQuestion[], null> = params => {
  const [{ getRequest }, state] = useApi(params);
  const getInterviewQuestion = () => getRequest<InterviewQuestion[]>('/public/interview/questions');
  return [getInterviewQuestion, state];
};
