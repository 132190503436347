import { Paper, SwipeableDrawer, Typography, Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Company } from 'src/DTO/Company.type';
import { useTranslation } from 'react-i18next';
import { People, Edit, BackupTable } from '@mui/icons-material';

export type SwipableCompanyDetails = {
  data?: Company;
  onClose: () => void;
};

export const SwipableCompanyDetails: React.FC<SwipableCompanyDetails> = ({ data, onClose }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!data) return;
    if (!isOpen) {
      setIsOpen(true);
    }
  }, [data]);

  if (!data) return null;

  const navigateToDetails = (id: string) => {
    return window.open(`/companies/${id}`, '_blank');
  };

  const navigateToWorkers = (id: string) => {
    return window.open(`/workers/filter/companyid/${id}`, '_blank');
  };

  const navigateToJobs = (id: string) => {
    return window.open(`/jobs/filter/company/${id}`, '_blank');
  };

  const navigateToAccount = (id: string) => {
    return window.open(`/companies/${id}/account`, '_blank');
  };

  const navigateToInvoiceHistory = (id: string) => {
    return window.open(`/companies/${id}/invoice-history`, '_blank');
  };

  return (
    <SwipeableDrawer
      onClose={() => {
        setIsOpen(false), onClose();
      }}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      anchor="right">
      <Paper style={{ padding: 24 }}>
        <Typography color="primary" variant="h1">
          {data.name}
        </Typography>
        <Box mt={2}>
          <Button
            onClick={() => {
              navigateToDetails(data.id);
            }}
            startIcon={<Edit />}
            variant="contained">
            {t('generic.edit')}
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            onClick={() => {
              navigateToWorkers(data.id);
            }}
            startIcon={<People />}
            color="warning"
            variant="contained">
            {t('companyDetailsScreen.goToWorkers')}
          </Button>

          <Button
            onClick={() => {
              navigateToJobs(data.id);
            }}
            style={{ marginLeft: 12 }}
            startIcon={<BackupTable />}
            color="warning"
            variant="contained">
            {t('companyDetailsScreen.goToJobs')}
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            onClick={() => {
              navigateToAccount(data.id);
            }}
            startIcon={<BackupTable />}
            color="info"
            variant="contained">
            {t('companyDetailsScreen.goToAccounting')}
          </Button>
          <Button
            onClick={() => {
              navigateToInvoiceHistory(data.id);
            }}
            style={{ marginLeft: 12 }}
            startIcon={<BackupTable />}
            color="info"
            variant="contained">
            {t('companyDetailsScreen.goToInvoicing')}
          </Button>
        </Box>
        <Box mt={2}>
          <Typography variant="subtitle1">{t('companyDetailsScreen.balance')}</Typography>
          <Typography variant="subtitle2">{data.account?.balance} Ft</Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="subtitle1">{t('companyDetailsScreen.form.ceoName')}</Typography>
          <Typography variant="subtitle2">{data.ceo.name}</Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="subtitle1">{t('companyDetailsScreen.form.address')}</Typography>
          <Typography variant="subtitle2">
            {data.postCode} {data.country} {data.city} {data.addressLine1}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="subtitle1">{t('companyDetailsScreen.form.email')}</Typography>
          <Typography variant="subtitle2">{data.email}</Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="subtitle1">{t('companyDetailsScreen.form.vatNumber')}</Typography>
          <Typography variant="subtitle2">{data.vatNumber}</Typography>
        </Box>
      </Paper>
    </SwipeableDrawer>
  );
};
