import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Autorenew as AutorenewIcon } from '@mui/icons-material';
import { useGetManagerInvites, useResendManagerInvite } from 'src/api/hooks/useManager';
import { useStoreActions } from 'src/stores';
import { useTranslation, TFunction, Trans } from 'react-i18next';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { CustomeMaterialTable } from 'src/components/MaterialTable/MaterialTable';
import { ManagerInviteModal } from 'src/pages/ManagerInvites/ManagerInviteModal';
import { ManagerInvite, MANAGER_INVITE_STATUS } from 'src/DTO/ManagerInvite.type';
import { useHistory } from 'react-router-dom';
import { formatDate } from 'src/utils/formatDate';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

type PushFC = {
  (path: string, state?: unknown): void;
  (location: any): void;
};

const headers = (t: TFunction, push: PushFC) => {
  return [
    {
      headerName: t('managerInvitesScreen.headers.id'),
      field: 'id',
      sorting: false,
      hide: true,
      flex: 1,
    },
    { headerName: t('managerInvitesScreen.headers.name'), field: 'name', flex: 1 },
    {
      headerName: t('managerInvitesScreen.headers.email'),
      field: 'email',
      flex: 1,
    },
    {
      headerName: t('managerInvitesScreen.headers.status'),
      field: 'status',
      flex: 1,
      render: (rowData: ManagerInvite) => (
        <Typography
          style={{ color: rowData.status === MANAGER_INVITE_STATUS.ACCEPTED ? 'green' : 'red' }}>
          {rowData.status}
        </Typography>
      ),
    },
    {
      headerName: t('managerInvitesScreen.headers.acceptedAt'),
      field: 'acceptedAt',
      flex: 1,
      render: (rowData: ManagerInvite) => (
        <Typography>{rowData.acceptedAt && formatDate(rowData.acceptedAt)}</Typography>
      ),
    },
    {
      headerName: t('managerInvitesScreen.headers.company'),
      field: 'company.name',
      flex: 1,
      render: (rowData: ManagerInvite) => (
        <Typography
          style={{ color: 'blue', cursor: 'pointer' }}
          onClick={() => push(`/companies/${rowData.company.id}`)}>
          {rowData.company ? rowData.company.name : ''}
        </Typography>
      ),
    },
    {
      flex: 1,
      headerName: t('managerInvitesScreen.headers.createdAt'),
      field: 'createdAt',
      render: (rowData: ManagerInvite) => <Typography>{formatDate(rowData.createdAt)}</Typography>,
    },
  ];
};

export const ManagerInviteList: React.FC = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const [managerInvites, setManagerInvites] = useState<ManagerInvite[] | undefined>(undefined);
  const [inviteModalOpen, setInviteModalOpen] = useState<boolean>(false);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [getManagerInvites] = useGetManagerInvites({
    onComplete: res => {
      if (res) {
        setManagerInvites(res);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.managerInviteScreen.success'),
      });
    },
  });

  const [resendInvite] = useResendManagerInvite({
    onComplete: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.success,
        message: t('alerts.managerInviteScreen.success'),
      });
      getManagerInvites();
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.managerInviteScreen.error'),
      });
    },
  });

  useEffect(() => {
    getManagerInvites();
  }, []);

  useEffect(() => {
    if (!inviteModalOpen) {
      getManagerInvites();
    }
  }, [inviteModalOpen]);

  const doResendInvite = (id: string) => {
    resendInvite({ variables: { id } });
  };

  return (
    <Grid style={{ width: '100%' }}>
      <CustomeMaterialTable
        tableProps={{
          title: t('managerInvitesScreen.title'),
          columns: headers(t, push),
          isLoading: !managerInvites,
          data: managerInvites ? managerInvites : [],
          options: {
            toolbar: true,
          },
          components: {
            Toolbar: () => (
              <Grid>
                {/* <MTableToolbar {...props} /> */}
                <Grid
                  container
                  direction="row"
                  style={{ marginLeft: 20, marginRight: 20, marginBottom: 20 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setInviteModalOpen(true)}>
                    <Trans>managerProfilesScreen.inviteButtonLabel</Trans>
                  </Button>
                </Grid>
              </Grid>
            ),
          },
          actions: [
            {
              icon: () => <AutorenewIcon color="primary" fontSize="medium" />,
              tooltip: t('generic.resend'),
              // onClick: (_event, rowData: ManagerInvite) =>
              //   rowData.status !== MANAGER_INVITE_STATUS.ACCEPTED
              //     ? doResendInvite(rowData.id)
              //     : undefined,
            },
          ],
        }}
      />
      <DataGrid
        autoHeight
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        autoPageSize
        rows={managerInvites ? managerInvites : []}
        columns={headers(t, push)}
        loading={!managerInvites}
        pageSize={100}
        rowsPerPageOptions={[5, 20]}
      />
      <ManagerInviteModal
        isOpen={inviteModalOpen}
        cancelButtonAction={() => setInviteModalOpen(false)}
      />
    </Grid>
  );
};
