import { makeStyles } from '@mui/styles';
import { theme } from 'src/utils/theme';

export const useStyles = makeStyles(() => ({
  title: {
    height: 50,
  },
  table: {
    height: 150,
  },
  buttonContatiner: {
    height: 100,
  },
  tableItem: {
    alignItems: 'center',
  },
  arrow: {
    alignItems: 'center',
  },
  historyModalText: {
    padding: 10,
  },
  historyModalHours: {
    color: theme.palette.primary.main,
    padding: 10,
  },
}));
