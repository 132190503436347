import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useStoreActions } from 'src/stores';
import { useTranslation } from 'react-i18next';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { JobConfirmation } from 'src/DTO/JobConfirmation.type';
import { TFunction } from 'i18next';
import { Invoice } from 'src/DTO/Invoice.type';
import { useInvoices } from 'src/api/hooks/useInvoice';
import { formatDate } from 'src/utils/formatDate';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const headers = (t: TFunction) => {
  return [
    {
      headerNames: t('invoicessScreen.headers.id'),
      field: 'id',
      sorting: false,
    },
    {
      headerNames: t('invoicessScreen.headers.payout'),
      field: 'paid',
      render: (rowData: Invoice) => {
        return rowData.paid ? (
          <Typography style={{ color: 'green' }}>{String(rowData.paid)}</Typography>
        ) : (
          <Typography color="error">{String(rowData.paid)}</Typography>
        );
      },
    },
    {
      headerNames: t('invoicessScreen.headers.amount'),
      field: 'amount',
      render: (rowData: Invoice) => {
        return <Typography>{Number(rowData.amount).toLocaleString('HU-hu')}</Typography>;
      },
    },
    {
      headerNames: t('invoicessScreen.headers.month'),
      field: 'month',
    },
    {
      headerNames: t('invoicessScreen.headers.year'),
      field: 'year',
    },
    {
      headerNames: t('invoicessScreen.headers.company'),
      field: 'company.name',
    },
    {
      headerNames: t('invoicessScreen.headers.createdAt'),
      field: 'createdAt',
      filtering: false,
      render: (rowData: JobConfirmation) => (
        <Typography>{formatDate(rowData.createdAt)}</Typography>
      ),
    },
    {
      headerNames: t('generic.updatedAt'),
      field: 'updatedAt',
      filtering: false,
      render: (rowData: JobConfirmation) => (
        <Typography>{formatDate(rowData.updatedAt)}</Typography>
      ),
    },
  ];
};

export const InvoiceList: React.FC = () => {
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState<Invoice[] | undefined>(undefined);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [getInvoices] = useInvoices({
    onComplete: res => {
      if (res) {
        setInvoices(res);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.invoicesScreen.error'),
      });
    },
  });

  useEffect(() => {
    getInvoices();
  }, []);

  const navigateToDetails = (id: string) => {
    return window.open(`/invoices/${id}`, '_blank');
  };

  return (
    <Grid style={{ width: '100%' }}>
      {/* <CustomeMaterialTable
        tableProps={{
          title: t('invoicessScreen.title'),
          columns: headers(t),
          isLoading: !invoices,
          data: invoices ? invoices : [],
          options: {
            toolbar: false,
          },
          actions: [
            {
              icon: () => <ArrowForwardIcon color="primary" fontSize="medium" />,
              tooltip: t('generic.details'),
              // onClick: (_event, rowData) => navigateToDetails(rowData.id),
            },
          ],
        }}
      /> */}
      <DataGrid
        autoHeight
        components={{ Toolbar: GridToolbar }}
        autoPageSize
        rows={invoices ? invoices : []}
        columns={headers(t)}
        loading={!invoices}
        // onRowClick={data => {
        //   setSelectedJoinR(data.row as WorkerContractStatus);
        // }}
        pageSize={100}
        rowsPerPageOptions={[5, 20]}
      />
    </Grid>
  );
};
