export enum TransactionType {
  Credit = 'credit',
  Charge = 'charge',
}
export interface AddTransacModalProps {
  isOpen: boolean;
  cancelButtonAction: () => void;
  accountId: string;
  defaultTransActionType?: TransactionType;
  defaultTransActionValue?: string;
}

export interface AddTransactionsData {
  amount: string;
  description: string;
  metadata?: string;
}

export interface WorkerDetailsParams {
  workerId: string;
}
