import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { Trans, useTranslation } from 'react-i18next';
import { useStoreActions } from 'src/stores';
import { useStyles } from './InviteWorkerModal.styles';
import { InviteWorkerModalProps } from './JobDetails.types';
import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { useGetWorkers } from 'src/api/hooks/useWorker';
import { Autocomplete } from '@mui/material';
import { useGetCompanyById } from 'src/api/hooks/useCompany';
import { Company } from 'src/DTO/Company.type';
import { useInviteWorkerToJob } from 'src/api/hooks/useJob';

export const InviteWorkerModal: React.FC<InviteWorkerModalProps> = ({
  isOpen,
  cancelButtonAction,
  jobId,
  jobName,
  companyId,
}) => {
  const { t } = useTranslation();
  const { dialogContainer, titleContainer, contentContainer } = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<WorkerProfile[]>([]);
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [managerId, setManagerId] = useState<string>('');

  const [workerProfiles, setWorkerProfiles] = useState<WorkerProfile[]>([]);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [getWorkers] = useGetWorkers({
    onComplete: res => {
      if (res) {
        setWorkerProfiles(res);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.workerProfileListScreen.error'),
      });
    },
  });

  const [getCompanyById] = useGetCompanyById({
    onComplete: res => {
      if (res) {
        setCompany(res);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.companyDetailsScreen.error'),
      });
    },
  });

  const [doInviteWorkersToJob] = useInviteWorkerToJob({
    onComplete: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.success,
        message: t('alerts.inviteWorkerToJob.success'),
      });
      setLoading(false);
      cancelButtonAction();
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.inviteWorkerToJob.error'),
      });
      setLoading(false);
    },
  });

  useEffect(() => {
    getWorkers();
  }, []);

  useEffect(() => {
    if (companyId) {
      getCompanyById({ variables: { id: companyId } });
    }
  }, [companyId]);

  const handleConfirmClicked = () => {
    setLoading(true);
    doInviteWorkersToJob({
      variables: { jobId, managerId, workerIds: selectedUsers.map(worker => worker.id) },
    });
  };

  return (
    <Dialog open={isOpen} onClose={cancelButtonAction} fullWidth maxWidth="md">
      <div className={dialogContainer}>
        <DialogTitle className={titleContainer}>
          <Trans>jobDetailsScreen.inviteWorkerModal.title</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans i18nKey="jobDetailsScreen.inviteWorkerModal.job"> {{ jobName }}</Trans>
          </DialogContentText>
          <DialogContentText>
            <Trans>jobDetailsScreen.inviteWorkerModal.contentText</Trans>
          </DialogContentText>
          <Grid className={contentContainer}>
            <Autocomplete<WorkerProfile, true>
              multiple
              id="invite_workers_to_job"
              options={workerProfiles}
              disableCloseOnSelect
              getOptionLabel={option => option.name}
              onChange={(_event, newValue) => {
                console.log('set', newValue);
                setSelectedUsers(newValue);
              }}
              style={{ width: 500 }}
              renderInput={params => (
                <TextField {...params} variant="outlined" label="Workers" placeholder="Favorites" />
              )}
            />
          </Grid>
          <DialogContentText>
            <Trans>jobDetailsScreen.inviteWorkerModal.contentText2</Trans>
          </DialogContentText>
          {company && (
            <FormControl style={{ width: 500 }}>
              <Select
                onChange={event => setManagerId(event.target.value as string)}
                value={managerId}
                variant="outlined">
                {company.managers &&
                  company.managers.map(selectItem => (
                    <MenuItem key={selectItem.id} value={selectItem.id}>
                      {selectItem.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          <DialogActions>
            <Button onClick={cancelButtonAction} color="primary" autoFocus>
              <Trans>generic.cancel</Trans>
            </Button>
            <Button
              color="primary"
              variant="contained"
              autoFocus
              type="submit"
              disabled={loading}
              onClick={handleConfirmClicked}>
              {loading ? <Trans>generic.loading</Trans> : <Trans>generic.confirm</Trans>}
            </Button>
          </DialogActions>
        </DialogContent>
      </div>
    </Dialog>
  );
};
