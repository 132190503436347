import { action, Action } from 'easy-peasy';
import { Document } from 'src/DTO/Document.type';

const initialState = {
  selectedWorkerDocuments: [],
};

export interface WorkerStore {
  selectedWorkerDocuments: Document[] | undefined;
  setSelectedWorkerDocuments: Action<WorkerStore, Document[] | undefined>;
  reset: Action<WorkerStore>;
}

export const workerStore: WorkerStore = {
  ...initialState,
  setSelectedWorkerDocuments: action((state, payload) => {
    state.selectedWorkerDocuments = payload;
  }),
  reset: action(() => ({
    ...initialState,
  })),
};
