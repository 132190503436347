import React from 'react';
import { useWindowSize } from 'src/utils/useWindowSize';
import { Box } from '@mui/material';
import { appBarHeight, drawerWidth } from './SideDrawer';
import { theme } from '../../utils/theme';

const AppContainer: React.FC = ({ children }) => {
  const size = useWindowSize();
  return children ? (
    <Box
      component="div"
      style={{
        backgroundColor: theme.palette.background.default,
        paddingTop: appBarHeight,
        height: size.height,
        paddingLeft: drawerWidth,
      }}>
      {children}
    </Box>
  ) : null;
};

export { AppContainer };
