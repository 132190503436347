import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import _ from 'lodash';

import { ManagerProfile } from 'src/DTO/ManagerProfile.type';
import { useGetManagerById, useUpdateManager } from 'src/api/hooks/useManager';
import { PageHeader } from 'src/components/PageHeader/PageHeader';
import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { DetailsForm } from 'src/components/DetailsFrom/DetailsForm';
import { CustomCollapse } from 'src/components/Collapse/CustomCollapse';
import { ConfirmDialog } from 'src/components/ConfirmDialog/ConfirmDialog';
import { LocationState } from 'src/pages/WorkerProfileDetails/WorkerProfileDetails.types';
import { ManagerDataPayLoad } from 'src/api/hooks/useManager.types';

import { ManagerDetailsParams } from './ManagerProfileDetails.types';

export const ManagerProfileDetails: React.FC = () => {
  const { t } = useTranslation();
  const { push } = useHistory<LocationState>();
  const { managerId } = useParams() as ManagerDetailsParams;
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [managerProfile, setManagerProfile] = useState<ManagerProfile>({
    id: '',
    auth0Id: '',
    name: '',
    phoneNumber: '',
    email: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    documents: null,
    contracts: null,
    companies: [],
  });

  const updateAbleFields: ManagerDataPayLoad = _.pick(
    managerProfile,
    'id',
    'name',
    'email',
    'phoneNumber',
  );

  const [getWorkerDetails] = useGetManagerById({
    onComplete: res => {
      if (res) {
        setManagerProfile(res);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.managerProfileDetailsScreen.error'),
      });
    },
  });

  const [updateManager] = useUpdateManager({
    onComplete: res => {
      if (res) {
        setManagerProfile(res);
        setConfirmModalOpen(false);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.managerProfileDetailsScreen.error'),
      });
    },
  });

  useEffect(() => {
    getWorkerDetails({ variables: { id: managerId } });
  }, []);

  const handleUpdateData = (value: string, key: string) => {
    setManagerProfile({
      ...managerProfile,
      [key]: value,
    });
  };

  const doUpdateManager = () => {
    updateManager({ variables: { updatedManagerData: updateAbleFields } });
  };

  return (
    <>
      <PageHeader
        pageTitle={t('managerProfileDetailsScreen.title', { name: managerProfile.name })}
        buttons={[
          {
            buttonLabel: t('managerProfileDetailsScreen.buttonLabel'),
            onClick: () => setConfirmModalOpen(true),
          },
          // {
          //   buttonLabel: t('managerProfileDetailsScreen.terminate'),
          //   onClick: () => console.log('clicked'),
          // },
        ]}
      />
      <DetailsForm
        inputFields={[
          {
            value: managerProfile.id,
            label: t('managerProfileDetailsScreen.form.id'),
            disabled: true,
          },
          {
            value: managerProfile.name,
            label: t('managerProfileDetailsScreen.form.name'),
            onChange: handleUpdateData,
            key: 'name',
          },
          {
            value: managerProfile.email,
            label: t('managerProfileDetailsScreen.form.email'),
            onChange: handleUpdateData,
            key: 'email',
          },
          {
            value: managerProfile.phoneNumber,
            label: t('managerProfileDetailsScreen.form.phoneNumber'),
            onChange: handleUpdateData,
            key: 'phoneNumber',
          },
          {
            value: managerProfile.createdAt
              ? format(new Date(managerProfile.createdAt), 'yyyy-MM-dd')
              : managerProfile.createdAt,
            label: t('managerProfileDetailsScreen.form.createdAt'),
            disabled: true,
          },
        ]}
      />
      <ConfirmDialog
        open={confirmModalOpen}
        handleConfirm={doUpdateManager}
        title={t('managerProfileDetailsScreen.confirmUpdate')}
        setOpen={setConfirmModalOpen}
        content={Object.entries(updateAbleFields).map(([key, value]) => {
          return {
            key,
            value: _.isString(value) || _.isBoolean(value) ? value : '',
          };
        })}
      />
    </>
  );
};
