import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useCollapseStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: 10,
  },
  title: {
    color: theme.palette.common.white,
    cursor: 'pointer',
  },
  expandIcon: {
    cursor: 'pointer',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    width: 350,
  },
}));
