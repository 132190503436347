import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { DeleteForever, Backpack } from '@mui/icons-material';
import { useStoreActions } from 'src/stores';
import { useTranslation, TFunction } from 'react-i18next';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { useArchiveWorker, useGetDeleteRequests, useUpdateWorker } from 'src/api/hooks/useWorker';
import {
  DataGrid,
  GridToolbar,
  GridValueGetterParams,
  GridRenderCellParams,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import moment from 'moment';
import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { BaseRequest } from 'src/api/hooks/useApi.types';
import { ArchiveWorkerPayload } from 'src/api/hooks/useWorker.types';

const headers = (
  t: TFunction,
  archiveWorker: BaseRequest<ArchiveWorkerPayload>,
  updateWorker: BaseRequest<any>,
) => {
  return [
    {
      headerName: t('accDeleteScreen.headers.name'),
      field: 'name',
      valueGetter: (params: any) => params.row.name,
      flex: 1,
    },
    {
      headerName: t('accDeleteScreen.headers.status'),
      field: 'status',
      flex: 1,
    },
    {
      headerName: t('accDeleteScreen.headers.phoneNumber'),
      field: 'phoneNumber',
      valueGetter: (params: any) => params.row.phoneNumber,
      flex: 1,
    },
    {
      headerName: t('accDeleteScreen.headers.email'),
      field: 'email',
      flex: 1,
    },
    {
      headerName: t('accDeleteScreen.headers.deletionDate'),
      field: 'deletionDate',
      valueGetter: (params: GridValueGetterParams<Date>) =>
        moment(params.row.deletionDate).format('YYYY-MM-DD - HH:mm'),
      flex: 1,
    },
    {
      headerName: t('Days left over since deletion request'),
      field: '',
      flex: 1,
      valueGetter: (params: GridValueGetterParams<Date>) => {
        console.log('rowData', params.row);
        const endOfDeletionProcess = moment(params.row.deletionDate).add(60, 'days');
        return endOfDeletionProcess.diff(moment(), 'days');
      },
    },
    {
      headerName: t('generic.actions'),
      type: 'actions',
      field: 'actions',
      filterable: false,
      getActions: (params: GridRenderCellParams<Date>) => [
        <GridActionsCellItem
          key={1}
          showInMenu
          onClick={() => {
            archiveWorker({ variables: { workerId: params.row.id } });
          }}
          icon={<DeleteForever />}
          label="Archive"
        />,
        <GridActionsCellItem
          key={1}
          showInMenu
          onClick={() => {
            updateWorker({
              variables: {
                id: params.row.id,
                deletionDate: null,
              },
            });
          }}
          icon={<Backpack />}
          label="Remove delete request"
        />,
      ],
      flex: 1,
    },
  ];
};

export const DeleteRequests: React.FC = () => {
  const { t } = useTranslation();
  const [workers, setWorkers] = useState<WorkerProfile[] | undefined>(undefined);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [getDeleteRequests, { loading }] = useGetDeleteRequests({
    onComplete: res => res && setWorkers(res),
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.joinRequest.error'),
      });
    },
  });

  const [archiveWorker, { loading: archiveLoading }] = useArchiveWorker({
    onComplete: res => {
      if (res && res.isSuccess) {
        getDeleteRequests();
      }

      if (res && !res.isSuccess) {
        alert(res.message || '');
      }
    },
    onError: () => alert('error'),
  });

  const [updateWorker, { loading: updateLoading }] = useUpdateWorker({
    onComplete: res => {
      if (res) {
        getDeleteRequests();
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.workerProfileDetailsScreen.error'),
      });
    },
  });

  useEffect(() => {
    getDeleteRequests();
  }, []);

  return (
    <Grid style={{ width: '100%' }}>
      <DataGrid
        autoHeight
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        autoPageSize
        rows={workers || []}
        columns={headers(t, archiveWorker, updateWorker)}
        loading={loading || archiveLoading || updateLoading}
        pageSize={100}
        rowsPerPageOptions={[5, 20]}
      />
    </Grid>
  );
};
