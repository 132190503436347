import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  CircularProgress,
  Container,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/logo_d.svg';
import { omit } from 'lodash';

import { useFontStyles } from '../../utils/useFontStyles';
import { useStoreActions, useStoreState } from '../../stores';

import { useLoginAuth } from '../../api/hooks/useAuth';
import { AuthStateProps } from './Auth.types';

export const Auth: React.FC = () => {
  const font = useFontStyles();
  const { t } = useTranslation();
  const history = useHistory();

  /* GLOBAL STATE */
  const authEmail = useStoreState(state => state.auth.authEmail);

  const setUserEmail = useStoreActions(actions => actions.auth.setUserEmail);
  const updateUser = useStoreActions(actions => actions.user.update);

  /* LOCAL STATE */
  const [authData, setAuthData] = useState<AuthStateProps>({
    email: authEmail,
    password: '',
    error: null,
  });

  useEffect(() => {
    if (authData.email !== authEmail) {
      setUserEmail(authData.email);
    }
  }, [authData.email, authEmail, setUserEmail]);

  const [login, { loading }] = useLoginAuth({
    onComplete: res => {
      if (res && res.isSuccess && res.adminUser && res.token && res.tokenExpire) {
        updateUser({
          ...res.adminUser,
          tokenExpire: new Date(res.tokenExpire),
          token: res.token,
          isLogedIn: true,
        });
        history.push('/home');
      } else {
        setAuthData({
          ...authData,
          error: t('loginScreen.incorrectAuth'),
        });
      }
    },
    onError: res => {
      if (res?.response?.status === 400) {
        setAuthData({
          ...authData,
          error: res.response.data.message,
        });
      } else {
        setAuthData({
          ...authData,
          error: t('loginScreen.networkError'),
        });
      }
    },
  });

  const startLogin = () => {
    login({
      variables: omit(authData, 'error'),
    });
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      startLogin();
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        text-align="center">
        <Box
          mt="20%"
          mb="20%"
          text-align="center"
          alignItems="center"
          display="flex"
          flexDirection="column">
          <Box margin={4}>
            <Logo />
          </Box>
          <Typography className={font.titleQuote}>
            <Trans>loginScreen.title</Trans>
          </Typography>
        </Box>
        <Grid container>
          <Grid xs={12}>
            <Box pl={1}>
              <TextField
                onKeyDown={e => onKeyDown(e)}
                disabled={loading}
                label={t('loginScreen.email')}
                variant="outlined"
                fullWidth
                value={authData.email}
                onChange={event =>
                  setAuthData({
                    ...authData,
                    error: null,
                    email: event.target.value,
                  })
                }
              />
            </Box>
            <Box mt={2} pl={1}>
              <TextField
                onKeyDown={e => onKeyDown(e)}
                disabled={loading}
                fullWidth
                label={t('loginScreen.password')}
                variant="outlined"
                value={authData.password}
                type="password"
                onChange={event =>
                  setAuthData({
                    ...authData,
                    error: null,
                    password: event.target.value,
                  })
                }
              />
            </Box>
          </Grid>
          <Grid xs={12}>
            {authData.error && (
              <Box mt={2} width="100%">
                <Typography align="center" color="white">
                  {authData.error}
                </Typography>
              </Box>
            )}
            <Box mt={5} width="100%">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={startLogin}
                disabled={loading}>
                {loading ? (
                  <Box display="flex" mb={1} mt={1}>
                    <CircularProgress color="secondary" />
                  </Box>
                ) : (
                  <Trans>generic.login</Trans>
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
