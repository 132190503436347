import { CancelOutlined, Check, Download } from '@mui/icons-material';
import { Paper, SwipeableDrawer, Typography, Box, Button, TextField, Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseRequest } from 'src/api/hooks/useApi.types';
import { GetDocumentResponse, ReviewDocumentPayload } from 'src/api/hooks/useDocument.types';
import { DocumentComponent } from '../WorkerProfileDetails/DocumentComponent';

export type SwipableDocumentDetails = {
  data?: GetDocumentResponse;
  loading: boolean;
  onClose: () => void;
  signedUrls: string[];
  reviewDocument: BaseRequest<ReviewDocumentPayload>;
};

export const SwipableDocumentDetails: React.FC<SwipableDocumentDetails> = ({
  data,
  onClose,
  signedUrls,
  reviewDocument,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const date = moment(data?.expires).format('YYYY-MM-DD');
  const [expireDate, setExpireDate] = useState(date);

  useEffect(() => {
    if (!data) return;
    if (!isOpen) {
      setIsOpen(true);
    }
  }, [data]);

  if (!data) return null;

  const images = signedUrls.filter(su => su.includes('.jpg') || su.includes('.png'));
  const documents = signedUrls.filter(su => !su.includes('.jpg') && !su.includes('.png'));

  const getFilename = (url: string) => {
    const matches = url.match(/\/\w+\?/);
    if (matches && matches.length > 0) {
      return matches[0].substring(0, 20).replace('/', '') + '...';
    }

    return 'Download';
  };

  const isExpireDateValid =
    moment(expireDate).isValid() && moment(expireDate) > moment().subtract('1', 'year');

  return (
    <SwipeableDrawer
      onClose={() => {
        setIsOpen(false);
        onClose();
      }}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      anchor="right">
      {documents && !isEmpty(documents) && (
        <Box mt={2}>
          <Paper style={{ padding: 24 }}>
            <Typography variant="subtitle1">{t('documentListScreen.swipe.documents')}</Typography>
            {documents.map((d, i) => (
              <Button key={i} target="_blank" href={d} startIcon={<Download />} variant="contained">
                {getFilename(d)}
              </Button>
            ))}
          </Paper>
        </Box>
      )}

      {['StudentId', 'DoctorsPaper', 'StudentStatusVerification'].includes(data.type) && (
        <>
          <Box mt={2}>
            <Paper style={{ padding: 24 }}>
              <Typography variant="subtitle1">{t('documentListScreen.swipe.acceptdoc')}</Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    onClick={() =>
                      setExpireDate(moment().set('month', 9).endOf('month').format('YYYY-MM-DD'))
                    }
                    variant="outlined">
                    {moment().set('month', 9).endOf('month').format('YYYY-MM-DD')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() =>
                      setExpireDate(
                        moment()
                          .set('year', 2023)
                          .set('month', 0)
                          .endOf('month')
                          .format('YYYY-MM-DD'),
                      )
                    }
                    color="warning"
                    variant="outlined">
                    {moment().set('year', 2023).set('month', 0).endOf('month').format('YYYY-MM-DD')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() =>
                      setExpireDate(
                        moment()
                          .set('year', 2023)
                          .set('month', 9)
                          .endOf('month')
                          .format('YYYY-MM-DD'),
                      )
                    }
                    color="error"
                    variant="outlined">
                    {moment().set('year', 2023).set('month', 9).endOf('month').format('YYYY-MM-DD')}
                  </Button>
                </Grid>
                <Grid xs={12} item>
                  <TextField
                    onChange={e => setExpireDate(e.target.value)}
                    onBlur={() => setExpireDate(moment(expireDate).format('YYYY-MM-DD'))}
                    value={expireDate}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Button
                    disabled={!isExpireDateValid}
                    startIcon={<Check />}
                    fullWidth
                    onClick={() => {
                      reviewDocument({
                        variables: {
                          id: data.id,
                          date: expireDate,
                          action: 'accept',
                          rejectMessage: '',
                        },
                      });
                    }}
                    variant="contained"
                    color="success">
                    Accept {expireDate}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <Box mt={2}>
            <Paper style={{ padding: 24 }}>
              <Typography variant="subtitle1">{t('documentListScreen.swipe.rejectdoc')}</Typography>
              {/* <TextField margin="normal" fullWidth value={''} /> */}
              <Button
                onClick={() => {
                  reviewDocument({
                    variables: {
                      id: data.id,
                      date: expireDate,
                      action: 'reject',
                      rejectMessage: '',
                    },
                  });
                }}
                startIcon={<CancelOutlined />}
                fullWidth
                variant="contained"
                color="error">
                Reject
              </Button>
            </Paper>
          </Box>
        </>
      )}

      {images && !isEmpty(images) && (
        <Box mt={2}>
          <Paper style={{ padding: 24 }}>
            <Typography variant="subtitle1">{t('documentListScreen.swipe.images')}</Typography>
            <DocumentComponent size={100} imageUrls={images} />
          </Paper>
        </Box>
      )}

      <Box mt={2}>
        <Paper style={{ padding: 24 }}>
          <Typography variant="subtitle1">{t('documentListScreen.swipe.expires')}</Typography>
          <Typography color="primary" variant="subtitle2">
            {!isEmpty(data.expires) ? moment(data.expires).format('YYYY-MM-DD') : 'NO DATE'}
          </Typography>
        </Paper>
      </Box>
    </SwipeableDrawer>
  );
};
