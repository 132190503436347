import { Paper, SwipeableDrawer, Typography, Box, Button, Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { People, Check, BackupTable } from '@mui/icons-material';
import { GetJoinPayload, WorkerContractStatus } from 'src/api/hooks/useJoin.types';
import { DocumentComponent } from '../WorkerProfileDetails/DocumentComponent';
import { union, spread } from 'lodash';
import { BaseRequest } from 'src/api/hooks/useApi.types';
import {
  compareTaxNumbersWithBirthDay,
  validateSocialSecurityNumber,
  validateStudentNumber,
  validateTaxNumber,
} from 'src/utils/validators';
import { DocumentStatus } from 'src/DTO/DocumentStatus.enum';
import { DocumentType } from 'src/DTO/DocumentType.enum';

export type SwipableJoinDetails = {
  data?: WorkerContractStatus;
  acceptJoin: BaseRequest<GetJoinPayload>;
  deleteJoin: BaseRequest<GetJoinPayload>;
  acceptJoinLoading: boolean;
  onClose: () => void;
};

export const SwipableJoinDetails: React.FC<SwipableJoinDetails> = ({
  data,
  acceptJoin,
  deleteJoin,
  acceptJoinLoading,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!data) return;
    if (!isOpen) {
      setIsOpen(true);
    }
  }, [data]);

  if (!data) return null;

  const navigateToWorkers = (id: string) => {
    return window.open(`/worker-profile/${id}`, '_blank');
  };

  const navigateToJobs = (id: string) => {
    return window.open(`/jobs/filter/worker/${id}`, '_blank');
  };

  const collapse = spread(union);

  const hasPendingDocument = data.worker.documents?.find(d => d.status === DocumentStatus.Pending)
    ? true
    : false;

  const hasActiveStudentCard = data.worker.documents?.find(
    d =>
      d.status === DocumentStatus.Approved &&
      (d.type === DocumentType.StudentId || d.type === DocumentType.StudentStatusVerification),
  )
    ? true
    : false;

  return (
    <SwipeableDrawer
      onClose={() => {
        setIsOpen(false);
        onClose();
      }}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      anchor="right">
      <Paper style={{ padding: 24 }}>
        <Typography color="primary" variant="h1">
          {data.worker.name}
        </Typography>
        <Box mt={2}>
          <Button
            disabled={acceptJoinLoading || hasPendingDocument || !hasActiveStudentCard}
            onClick={() => {
              acceptJoin({
                variables: {
                  statusId: data.id,
                },
              });
            }}
            color="success"
            startIcon={<Check />}
            variant="contained">
            {hasPendingDocument || !hasActiveStudentCard
              ? 'PENDING DOCUMENTS OR NO ACCEPTED'
              : t('generic.accept')}
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            onClick={() => {
              navigateToWorkers(data.worker.id);
            }}
            startIcon={<People />}
            color="warning"
            variant="contained">
            {t('joinRequestsScreen.swipe.workerDetails')}
          </Button>

          <Button
            onClick={() => {
              navigateToJobs(data.worker.id);
            }}
            style={{ marginLeft: 12 }}
            startIcon={<BackupTable />}
            color="warning"
            variant="contained">
            {t('joinRequestsScreen.swipe.workerJob')}
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            onClick={() => {
              deleteJoin({
                variables: {
                  statusId: data.id,
                },
              });
            }}
            startIcon={<BackupTable />}
            color="error"
            variant="contained">
            {t('joinRequestsScreen.swipe.deleteForever')}
          </Button>
        </Box>
      </Paper>

      <Box mt={2}>
        <Paper style={{ padding: 24 }}>
          <Typography variant="subtitle1">{t('joinRequestsScreen.swipe.validations')}</Typography>
          {validateStudentNumber(data.worker.studentIdNumber || '') ? (
            <Alert severity="success">Valid Student number</Alert>
          ) : (
            <Alert severity="error">INVALID Student number</Alert>
          )}
          <br />
          {validateTaxNumber(data.worker.taxNumber || '') &&
          compareTaxNumbersWithBirthDay(data.worker.taxNumber || '', data.worker.birthDay) ? (
            <Alert severity="success">Valid TAX number</Alert>
          ) : (
            <Alert severity="error">
              INVALID TAX number
              {validateTaxNumber(data.worker.taxNumber || '') &&
              compareTaxNumbersWithBirthDay(data.worker.taxNumber || '', data.worker.birthDay) ===
                false
                ? ' - BDAY NOT MATCH'
                : ''}
            </Alert>
          )}
          <br />
          {validateSocialSecurityNumber(data.worker.socialSecurityNumber || '') ? (
            <Alert severity="success">Valid S.Security number</Alert>
          ) : (
            <Alert severity="error">INVALID S.Security number</Alert>
          )}
        </Paper>
      </Box>

      <Box mt={2}>
        <Paper style={{ padding: 24 }}>
          <Typography variant="subtitle1">{t('joinRequestsScreen.swipe.comapnies')}</Typography>
          <Typography color="primary" variant="subtitle2">
            {data.worker.companies?.map(c => c.name).join(', ')}
          </Typography>
        </Paper>
      </Box>

      <Box mt={2}>
        <Paper style={{ padding: 24 }}>
          <Typography variant="subtitle1">{t('joinRequestsScreen.swipe.documents')}</Typography>
          <DocumentComponent
            size={100}
            imageUrls={collapse(data.worker.documents?.map(doc => doc.documentUrl)) as string[]}
          />
        </Paper>
      </Box>
      <Box mt={2}>
        <Paper style={{ padding: 24 }}>
          <Box mt={1}>
            <Typography variant="subtitle1">{t('joinRequestsScreen.swipe.workerName')}</Typography>
            <Typography color="primary" variant="subtitle2">
              {data.worker.firstName} {data.worker.lastName}
            </Typography>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">{t('joinRequestsScreen.swipe.nationality')}</Typography>
            <Typography color="primary" variant="subtitle2">
              {data.worker.nationality}
            </Typography>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">{t('joinRequestsScreen.swipe.email')}</Typography>
            <Typography color="primary" variant="subtitle2">
              {data.worker.email}
            </Typography>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">{t('joinRequestsScreen.swipe.birthDay')}</Typography>
            <Typography color="primary" variant="subtitle2">
              {data.worker.birthPlace}, {data.worker.birthDay}
            </Typography>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">{t('joinRequestsScreen.swipe.sNumber')}</Typography>
            <Typography color="primary" variant="subtitle2">
              {data.worker.socialSecurityNumber}
            </Typography>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">{t('joinRequestsScreen.swipe.phone')}</Typography>
            <Typography color="primary" variant="subtitle2">
              {data.worker.phoneNumber}
            </Typography>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">
              {t('joinRequestsScreen.swipe.studentNumber')}
            </Typography>
            <Typography color="primary" variant="subtitle2">
              {data.worker.studentIdNumber}
            </Typography>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">{t('joinRequestsScreen.swipe.taxNumber')}</Typography>
            <Typography color="primary" variant="subtitle2">
              {data.worker.taxNumber}
            </Typography>
          </Box>
        </Paper>
      </Box>
    </SwipeableDrawer>
  );
};
