import React from 'react';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { FieldProps, getIn } from 'formik';

import { useFormInputFieldStyles } from './useFormInputFieldStyles';

export const FormInputField: React.FC<FieldProps> = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const { root, wrapper, label } = useFormInputFieldStyles();
  return (
    <div className={wrapper}>
      <TextField
        fullWidth
        error={Boolean(getIn(touched, field.name) && getIn(errors, field.name))}
        {...field}
        value={field.value || ''}
        {...props}
        classes={{ root }}
        InputLabelProps={{ shrink: true, classes: { root: label } }}
      />
      <FormHelperText error>
        {getIn(touched, field.name) && getIn(errors, field.name)}
      </FormHelperText>
    </div>
  );
};
