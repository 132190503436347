import { useEffect } from 'react';

interface UseScriptProps {
  url?: string;
  text?: string;
  async?: boolean;
  defer?: boolean;
}

const useScript = (props: UseScriptProps) => {
  useEffect(() => {
    const script = document.createElement('script');

    if (props.url) script.src = props.url;
    if (props.text) script.text = props.text;
    if (props.async) script.async = props.async;
    if (props.defer) script.defer = props.defer;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [props]);
};

export default useScript;
