import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { Company } from 'src/DTO/Company.type';
import { useStoreActions } from 'src/stores';
import { TFunction, useTranslation } from 'react-i18next';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { CustomeMaterialTable } from 'src/components/MaterialTable/MaterialTable';
import { formatDate } from 'src/utils/formatDate';
import { useGetJobApplications } from 'src/api/hooks/useJob';
import { JobApplication } from 'src/DTO/JobApplication';
import { DataGrid, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';

const headers = (t: TFunction) => {
  return [
    {
      headerName: t('jobApplicationListScreen.headers.id'),
      field: 'id',
      sorting: false,
      hide: false,
      filtering: false,
      flex: 1,
    },
    {
      headerName: t('jobApplicationListScreen.headers.status'),
      field: 'status',
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => {
        let statusColor: string;
        switch (params.value) {
          case 'pending':
            statusColor = 'yellow';
            break;

          case 'cancelled':
            statusColor = 'purple';
            break;

          case 'declined':
            statusColor = 'red';
            break;

          default:
            statusColor = 'green';
            break;
        }
        return <Typography style={{ color: statusColor }}>{params.value}</Typography>;
      },
    },
    {
      headerName: t('jobApplicationListScreen.headers.workerName'),
      field: 'worker.name',
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams<JobApplication>) => row.worker.name,
    },
    {
      headerName: t('jobApplicationListScreen.headers.jobname'),
      field: 'job.role.name',
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams<JobApplication>) => row.job.role.name,
    },
    {
      headerName: t('jobApplicationListScreen.headers.manager'),
      field: 'acceptedBy.name',
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams<JobApplication>) => row?.acceptedBy?.name,
    },
    {
      headerName: t('generic.acceptedAt'),
      field: 'acceptedAt',
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams<JobApplication>) =>
        row.acceptedAt ? formatDate(row.acceptedAt) : '',
    },
    {
      headerName: t('generic.createdAt'),
      field: 'createdAt',
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams<JobApplication>) => formatDate(row.createdAt),
    },
  ];
};

export const JobApplicationList: React.FC = () => {
  const { t } = useTranslation();
  const [applications, setApplications] = useState<JobApplication[] | undefined>(undefined);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [getApplications] = useGetJobApplications({
    onComplete: res => {
      if (res) {
        setApplications(res);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.jobApplicationListScreen.error'),
      });
    },
  });

  useEffect(() => {
    getApplications();
  }, []);

  /*   const navigateToDetails = (id: string, edit: boolean) => {
    return window.open(`/companies/${id}`, '_blank');
  }; */

  return (
    <Grid style={{ width: '100%' }}>
      <CustomeMaterialTable
        tableProps={{
          title: 'Company list',
          columns: headers(t),
          isLoading: !applications,
          data: applications || [],
          options: {
            toolbar: false,
          },
          actions: [
            /*  {
              icon: () => <ArrowForwardIcon color="primary" fontSize="default" />,
              tooltip: t('generic.details'),
              onClick: (_event, rowData) => navigateToDetails(rowData.id, false),
            }, */
          ],
        }}
      />
      <DataGrid
        autoPageSize
        autoHeight
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        loading={!applications}
        rows={applications ? applications : []}
        columns={headers(t)}
        pageSize={100}
        rowsPerPageOptions={[5, 20]}
      />
    </Grid>
  );
};
