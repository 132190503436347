import { useApi } from './useApi';
import { BaseRequest, BaseHookRequest } from './useApi.types';
import {
  GetDocumentsPayload,
  GetDocumentsResponse,
  GetSignedDocumentUrlsPayload,
  GetSignedDocumentUrlsResponse,
  ReviewDocumentResponse,
  ReviewDocumentPayload,
} from './useDocument.types';

export const useGetDocumentsRequests: BaseHookRequest<GetDocumentsResponse, GetDocumentsPayload> =
  params => {
    const [{ getRequest }, state] = useApi<GetDocumentsResponse>(params);
    const getRequests: BaseRequest<GetDocumentsPayload> = () => {
      return getRequest('/admin/document');
    };
    return [getRequests, state];
  };

export const useGetSignedDocumentUrls: BaseHookRequest<
  GetSignedDocumentUrlsResponse,
  GetSignedDocumentUrlsPayload
> = params => {
  const [{ postRequest }, state] = useApi<GetSignedDocumentUrlsResponse>(params);
  const postreq: BaseRequest<GetSignedDocumentUrlsPayload> = details => {
    return details
      ? postRequest<GetSignedDocumentUrlsPayload>('/admin/document/signUrl', {
          ...details.variables,
        })
      : undefined;
  };
  return [postreq, state];
};

export const useReviewDocument: BaseHookRequest<ReviewDocumentResponse, ReviewDocumentPayload> =
  params => {
    const [{ postRequest }, state] = useApi<ReviewDocumentResponse>(params);
    const postreq: BaseRequest<ReviewDocumentPayload> = details => {
      return details
        ? postRequest<ReviewDocumentPayload>('/admin/document/review', {
            ...details.variables,
          })
        : undefined;
    };
    return [postreq, state];
  };
