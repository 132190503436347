import { action, Action } from 'easy-peasy';

const initialState = {
  sound: false,
};

export interface AppStore {
  sound: boolean;
  setSound: Action<AppStore, boolean>;
}

export const appStore: AppStore = {
  ...initialState,
  setSound: action((state, payload) => {
    state.sound = payload;
  }),
};
