import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { PageHeader } from 'src/components/PageHeader/PageHeader';
import { DetailsForm } from 'src/components/DetailsFrom/DetailsForm';
import { CustomCollapse } from 'src/components/Collapse/CustomCollapse';
import { LocationState } from 'src/pages/WorkerProfileDetails/WorkerProfileDetails.types';

import { InvoiceDetailsParams, InvoiceState } from './InvoiceDetails.types';
import { useGetInvoiceById, useGetPublicUrl } from 'src/api/hooks/useInvoice';
import { UpdateInvoiceModal } from './InvoiceUpdateModal';
import { formatDate } from 'src/utils/formatDate';

export const InvoiceDetails: React.FC = () => {
  const { t } = useTranslation();
  const { push, location } = useHistory<LocationState>();
  const { invoiceId } = useParams() as InvoiceDetailsParams;
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);
  const [isEdit, setIsEdit] = useState<boolean>(location.state ? location.state.edit : false);
  const [publicUrl, setPublicUrl] = useState<string | undefined>();
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<InvoiceState>({
    id: '',
    company: {
      id: '',
      name: '',
    },
    jobConfirmations: [],
    //payouts: [],
    paid: false,
    amount: 0,
    year: 0,
    month: 0,
    createdAt: new Date(),
  });

  const [getInvoiceById] = useGetInvoiceById({
    onComplete: res => {
      if (res) {
        setInvoice(res);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.companyDetailsScreen.error'),
      });
    },
  });

  const [getPublicUrl] = useGetPublicUrl({
    onComplete: res => {
      if (res) {
        setPublicUrl(res.public_url);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.invoicePublicUrl.error'),
      });
    },
  });

  useEffect(() => {
    getInvoiceById({ variables: { id: invoiceId } });
    getPublicUrl({ variables: { id: invoiceId } });
  }, []);

  const handleUpdateData = (value: string) => {
    setInvoice({
      ...invoice,
      paid: value === 'true',
    });
  };

  const openUpdateModal = () => {
    setConfirmModalOpen(true);
  };

  const closeAndUpdateState = (res: InvoiceState) => {
    setInvoice(res);
    setConfirmModalOpen(false);
  };

  const navigateToPublicUrl = () => {
    if (publicUrl) {
      window.open(publicUrl, '_blank');
    }
  };

  return (
    <>
      <PageHeader
        pageTitle={t('invoiceDetailsScreen.title', { id: invoice.id })}
        switchButton={{
          switchAction: () => setIsEdit(!isEdit),
          switchChecked: isEdit,
        }}
        buttons={[
          {
            buttonLabel: t('invoiceDetailsScreen.buttonLabel'),
            onClick: openUpdateModal,
            disabled: !isEdit,
          },
          {
            buttonLabel: t('invoiceDetailsScreen.download'),
            onClick: navigateToPublicUrl,
          },
        ]}
      />
      <DetailsForm
        inputFields={[
          { value: invoice.id, label: t('invoiceDetailsScreen.form.id'), disabled: true },
          {
            value: invoice.company.id,
            label: t('invoiceDetailsScreen.form.companyId'),
            disabled: false,
            link: true,
            linkAction: () => push(`/companies/${invoice.company.id}`),
          },
          {
            value: invoice.company.name,
            label: t('invoiceDetailsScreen.form.companyName'),
            disabled: true,
          },
          {
            value: invoice.paid,
            label: t('invoiceDetailsScreen.form.paid'),
            disabled: true,
            onChange: handleUpdateData,
            key: 'paid',
            type: 'select',
            selectValue: invoice.paid,
            selectItems: [
              { label: 'true', value: 'true' },
              { label: 'false', value: 'false' },
            ],
          },
          {
            value: `${Number(invoice.amount).toLocaleString('HU-hu')} HUF`,
            label: t('invoiceDetailsScreen.form.amount'),
            disabled: true,
          },
          {
            value: invoice.year,
            label: t('invoiceDetailsScreen.form.year'),
            disabled: true,
          },
          {
            value: invoice.month,
            label: t('invoiceDetailsScreen.form.month'),
            disabled: true,
          },
          {
            value: invoice.createdAt
              ? format(new Date(invoice.createdAt), 'yyyy-MM-dd')
              : invoice.createdAt,
            label: t('invoiceDetailsScreen.form.createdAt'),
            disabled: true,
          },
        ]}
      />
      <CustomCollapse title={t('invoiceDetailsScreen.jobConfirmations')} arrow>
        {invoice.jobConfirmations.length ? (
          invoice.jobConfirmations.map(confirmation => (
            <DetailsForm
              key={confirmation.id}
              inputFields={[
                {
                  value: confirmation.id,
                  label: t('invoiceDetailsScreen.form.jobConfirmationId'),
                  disabled: false,
                  link: true,
                  linkAction: () => push(`/confirmations/${confirmation.id}`),
                },
              ]}
            />
          ))
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
            <Typography>
              <Trans>invoiceDetailsScreen.noConfirmation</Trans>
            </Typography>
          </Box>
        )}
      </CustomCollapse>
      <CustomCollapse title={t('invoiceDetailsScreen.transactions')} arrow>
        {invoice.transfer && invoice.transfer.length ? (
          invoice.transfer.map(transaction => (
            <DetailsForm
              key={transaction.id}
              inputFields={[
                {
                  value: transaction.id,
                  label: t('invoiceDetailsScreen.transactionId'),
                  disabled: false,
                  link: true,
                  linkAction: () => push(`/transactions/${transaction.id}`),
                },
                {
                  value: formatDate(transaction.createdAt),
                  label: t('invoiceDetailsScreen.transactionDate'),
                  disabled: true,
                },
                {
                  value: `${Number(transaction.amount).toLocaleString('HU-hu')} HUF`,
                  label: t('invoiceDetailsScreen.transactionAmount'),
                  disabled: true,
                },
              ]}
            />
          ))
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
            <Typography>
              <Trans>invoiceDetailsScreen.noTransfer</Trans>
            </Typography>
          </Box>
        )}
      </CustomCollapse>
      <UpdateInvoiceModal
        isOpen={confirmModalOpen}
        invoiceId={invoice.id}
        price={Number(invoice.amount)}
        cancelButtonAction={() => setConfirmModalOpen(false)}
        closeAndUpdateState={closeAndUpdateState}
      />
    </>
  );
};
