import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { MainLayout } from '../components/MainLayout';
import { useStoreState } from '../stores';

const ProtectedRoute = ({ component: Component, ...routerProps }: any) => {
  const user = useStoreState(state => state.user.data);

  return (
    <MainLayout>
      <Route
        {...routerProps}
        render={props => (user.isLogedIn ? <Component {...props} /> : <Redirect to="/auth" />)}
      />
    </MainLayout>
  );
};

export { ProtectedRoute };
