import { Company } from 'src/DTO/Company.type';
import { JobConfirmation } from 'src/DTO/JobConfirmation.type';
import { useApi } from './useApi';
import { BaseRequest, BaseHookRequest } from './useApi.types';
import {
  GetCompanyByIdPayload,
  GetCompanyInvoiceHistoryPayload,
  UpdateCompanyPayload,
} from './useCompany.types';

export const useGetCompanies: BaseHookRequest<Company[], null> = params => {
  const [{ postRequest }, state] = useApi<Company[]>(params);
  const getCompanies = () => {
    return postRequest('/admin/company');
  };
  return [getCompanies, state];
};

export const useGetCompanyById: BaseHookRequest<Company, GetCompanyByIdPayload> = params => {
  const [{ getRequest }, state] = useApi<Company>(params);
  const getCompany: BaseRequest<GetCompanyByIdPayload> = details =>
    getRequest<GetCompanyByIdPayload>(`/admin/company/${details?.variables.id}`);
  return [getCompany, state];
};

export const useUpdateCompany: BaseHookRequest<Company, UpdateCompanyPayload> = params => {
  const [{ postRequest }, state] = useApi<Company>(params);
  const updateCompany: BaseRequest<UpdateCompanyPayload> = details => {
    return postRequest<UpdateCompanyPayload>('/admin/company/update', {
      updatedCompanyData: details?.variables.updatedCompanyData,
    });
  };
  return [updateCompany, state];
};

export const useGetCompanyInvoiceHistory: BaseHookRequest<
  JobConfirmation[],
  GetCompanyInvoiceHistoryPayload
> = params => {
  const [{ postRequest }, state] = useApi<JobConfirmation[]>(params);
  const getCompanyInvoiceHistory: BaseRequest<GetCompanyInvoiceHistoryPayload> = details => {
    return details
      ? postRequest<GetCompanyInvoiceHistoryPayload>('/admin/company/invoicing', {
          ...details.variables,
        })
      : undefined;
  };
  return [getCompanyInvoiceHistory, state];
};

export const useGetCompanyNotInvoiced: BaseHookRequest<JobConfirmation[], GetCompanyByIdPayload> =
  params => {
    const [{ postRequest }, state] = useApi<JobConfirmation[]>(params);
    const getCompanyNotInvoiced: BaseRequest<GetCompanyByIdPayload> = details => {
      return details
        ? postRequest<GetCompanyByIdPayload>('/admin/company/not-invoiced', {
            ...details.variables,
          })
        : undefined;
    };
    return [getCompanyNotInvoiced, state];
  };

export const useGetCompanyAccount: BaseHookRequest<Company, GetCompanyByIdPayload> = params => {
  const [{ getRequest }, state] = useApi<Company>(params);
  const getCompanyAccount: BaseRequest<GetCompanyByIdPayload> = details =>
    getRequest<GetCompanyByIdPayload>(`/admin/company/account/${details?.variables.id}`);
  return [getCompanyAccount, state];
};
