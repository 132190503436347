import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import { Formik, Field, Form } from 'formik';
import { format } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import { FormInputField } from 'src/components/FormInputField/FormInputField';
import { UpdateInvoiceModalProps } from './InvoiceDetails.types';
import { PaymentHistory } from 'src/DTO/PaymentHistory.type';
import { PaymentMethod } from 'src/DTO/PaymentMethod.type';
import { invoicePaymentHistorySchema } from './validationSchema';
import { FormPaymentMethodSelect } from 'src/pages/InvoiceDetails/FormPaymentMethodSelect';
import { useUpdateInvoice } from 'src/api/hooks/useInvoice';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { useStoreActions } from 'src/stores';

export const UpdateInvoiceModal: React.FC<UpdateInvoiceModalProps> = ({
  isOpen,
  cancelButtonAction,
  closeAndUpdateState,
  price,
  invoiceId,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const paymentHistoryInitialState: PaymentHistory = {
    date: format(new Date(), 'yyyy-MM-dd'),
    price: price,
    payment_method: PaymentMethod.WIRE_TRANSFER,
  };

  const [updateInvoice] = useUpdateInvoice({
    onComplete: res => {
      if (res) {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.success,
          message: t('alerts.invoiceUpdate.success'),
        });
        closeAndUpdateState(res);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.invoiceUpdate.error'),
      });
    },
  });

  const doUpdateInvoice = (data: PaymentHistory) => {
    setLoading(true);
    updateInvoice({ variables: { invoiceId, paymentHistory: [data] } });
  };

  return (
    <Dialog open={isOpen} onClose={cancelButtonAction} fullWidth maxWidth="lg">
      <div>
        <Grid style={{ minHeight: 50 }}>
          <DialogTitle>
            <Trans>invoiceDetailsScreen.modal.title</Trans>
          </DialogTitle>
          <DialogContentText>
            <Trans>invoiceDetailsScreen.modal.subTitle</Trans>
          </DialogContentText>
        </Grid>
        <DialogContent style={{ minHeight: 500 }}>
          <Grid style={{ minHeight: 400 }}>
            <Formik
              enableReinitialize
              initialValues={paymentHistoryInitialState}
              validationSchema={() => invoicePaymentHistorySchema()}
              onSubmit={data => doUpdateInvoice(data)}>
              {() => (
                <Form>
                  <Field
                    label={t('invoiceDetailsScreen.modal.form.date')}
                    name="date"
                    component={FormInputField}
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                  <Field
                    label={t('invoiceDetailsScreen.modal.form.pricePaid')}
                    name="price"
                    component={FormInputField}
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                  <Field
                    name="payment_method"
                    component={FormPaymentMethodSelect}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                  <DialogActions>
                    <Button onClick={cancelButtonAction} color="primary" autoFocus>
                      <Trans>generic.cancel</Trans>
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      autoFocus
                      type="submit"
                      disabled={loading}>
                      {loading ? <Trans>generic.loading</Trans> : <Trans>generic.confirm</Trans>}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Grid>
        </DialogContent>
      </div>
    </Dialog>
  );
};
