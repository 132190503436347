import { Company } from './Company.type';
import { ManagerProfile } from './ManagerProfile.type';
import { WorkerProfile } from './WorkerProfile.type';

export enum LogType {
  Worker = 'Worker',
  Manager = 'Manager',
  Company = 'Company',
}

export interface LogData {
  company?: Company;
  worker?: WorkerProfile;
  manager?: ManagerProfile;
}

export interface Log {
  id: string;
  name: string;
  data: LogData;
  worker?: WorkerProfile;
  manager?: ManagerProfile;
  company?: Company;
  createdAt: Date;
  updatedAt: Date;
}
