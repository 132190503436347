import i18next from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { format } from 'date-fns';
import { initReactI18next } from 'react-i18next';
import en from './translation/en';
import hu from './translation/hu';

const resources = {
  en: {
    translation: en,
  },
  hu: {
    translation: hu,
  },
};

//todo: @mezotamas fix this line not compatible with wslint
// eslint-disable-next-line no-void
void i18next
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    keySeparator: '.',
    debug: false,
    resources,
    interpolation: {
      escapeValue: false,
      format: (value, dateFormat) => {
        if (value instanceof Date) {
          return format(value, dateFormat || 'yyyy-MM-dd');
        }
        return value;
      },
    },
    cleanCode: true,
  });

export default i18next;
