import React from 'react';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';
import DesktopDatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useStyles } from './DetialsFrom.styles';

export const boolSelectItems = [
  { label: 'true', value: true },
  { label: 'false', value: false },
];
export interface SelectItem {
  label: string;
  value: string | number | boolean;
}
export interface InputFieldProps {
  value: any;
  label: string;
  key?: string;
  onChange?: (value: any, key: any) => void;
  link?: boolean;
  linkAction?: () => void;
  variant?: 'standard' | 'filled' | 'outlined';
  disabled?: boolean;
  multiline?: boolean;
  type?: 'select' | 'textInput' | 'date';
  selectItems?: SelectItem[];
  selectValue?: any;
  error?: string;
}

export interface DetailsFormProps {
  inputFields: InputFieldProps[];
}

export const DetailsForm: React.FC<DetailsFormProps> = ({ inputFields }) => {
  const { textInputItem, linkValue, defaultValue, formContainer, formInnerContainer } = useStyles();

  return (
    <Grid className={formContainer}>
      <Grid className={formInnerContainer}>
        {inputFields &&
          inputFields.map((inputField, index) => (
            <Grid
              className={textInputItem}
              key={index}
              style={inputField.link ? { cursor: 'pointer' } : undefined}>
              {inputField.type === 'select' && (
                <FormControl>
                  <InputLabel>{inputField.label}</InputLabel>
                  <Select
                    disabled={inputField.disabled}
                    onChange={event =>
                      inputField.onChange &&
                      inputField.onChange(event.target.value as string, inputField.key || '')
                    }
                    value={inputField.selectValue}>
                    {inputField.selectItems &&
                      inputField.selectItems.map(selectItem => (
                        <MenuItem
                          key={selectItem.label}
                          value={selectItem.value as string | number}>
                          {selectItem.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
              {!inputField.type && (
                <>
                  <TextField
                    value={inputField.value}
                    fullWidth
                    variant={inputField.variant || 'standard'}
                    label={inputField.label}
                    multiline={inputField.multiline}
                    disabled={inputField.disabled}
                    InputLabelProps={{ shrink: true }}
                    onChange={e =>
                      inputField.onChange
                        ? inputField.onChange(e.target.value, inputField.key || '')
                        : undefined
                    }
                    onClick={inputField.link ? inputField.linkAction : undefined}
                    InputProps={{
                      className: inputField.link ? linkValue : defaultValue,
                    }}
                  />
                  {inputField.error && <Typography color={'red'}>{inputField.error}</Typography>}
                </>
              )}
              {inputField.type === 'date' && (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    // disableToolbar
                    disabled={inputField.disabled}
                    // variant="inline"
                    // format="yyyy-MM-dd"
                    // margin="normal"
                    label={inputField.label}
                    value={inputField.value}
                    renderInput={props => <TextField {...props} helperText="valid mask" />}
                    onChange={e => inputField.onChange && inputField.onChange(e, inputField.key)}
                    // KeyboardButtonProps={{
                    //   'aria-label': 'change date',
                    // }}
                  />
                </LocalizationProvider>
              )}
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};
