import { Grid, Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';
import { format } from 'date-fns';
import { TransferMetadata } from 'src/DTO/Transaction.type';

export interface TransactionMetaData {
  studentNet: number;
  companyName: string;
  jobConfirmationYear: number;
  jobConfirmationMonth: number;
  jobConfirmationHoursWorked: number;
}

export interface TransactionCardProps {
  id: string;
  description: string;
  amount: string;
  metadata: TransferMetadata;
  createdAt: Date;
}

export const TransactionCard: React.FC<TransactionCardProps> = ({
  id,
  description,
  amount,
  metadata,
  createdAt,
}) => {
  const filledDescription = () => {
    if (metadata) {
      description = description.replace(
        /{{jobConfirmationYear}}/gm,
        String(metadata.jobConfirmationYear),
      );
      description = description.replace(
        /{{jobConfirmationMonth}}/gm,
        String(metadata.jobConfirmationMonth),
      );
      description = description.replace(
        /{{jobConfirmationInvoiced}}/gm,
        String(metadata.jobConfirmationInvoiced),
      );
      description = description.replace(
        /{{jobConfirmationHoursWorked}}/gm,
        `${metadata.jobConfirmationHoursWorked}`,
      );
      description = description.replace(/{{workerName}}/gm, ` ${metadata.workerName} HUF`);
    }
    return description;
  };

  return (
    <Grid item xs={12}>
      <Box mb={2}>
        <Card>
          <CardContent>
            <Grid container>
              <Box>
                <Typography>
                  <Trans i18nKey="companyAccount.id">{{ id }}</Trans>
                </Typography>
                <Typography>
                  <Trans i18nKey="companyAccount.amount">
                    {{ amount: Number(amount).toLocaleString('HU-hu') }}
                  </Trans>
                </Typography>
                <Typography>
                  <Trans i18nKey="companyAccount.description">
                    {{ description: filledDescription() }}
                  </Trans>
                </Typography>
                <Typography>
                  <Trans i18nKey="companyAccount.date">
                    {{ createdAt: format(new Date(createdAt), 'yyyy-MM-dd HH:mm') }}
                  </Trans>
                </Typography>
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
};
