import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useStoreActions } from 'src/stores';
import { TFunction, useTranslation } from 'react-i18next';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { CustomeMaterialTable } from 'src/components/MaterialTable/MaterialTable';
import { useGetAllTransactions } from 'src/api/hooks/useFinance';
import { Transaction } from 'src/DTO/Transaction.type';

import { formatDate } from 'src/utils/formatDate';

export type TransactionType = 'WorkerTransaction' | 'CompanyTransaction';

const headers = (t: TFunction) => {
  return [
    {
      title: t('transactionListScreen.headers.id'),
      field: 'id',
      sorting: false,
      filtering: false,
    },
    {
      title: t('transactionListScreen.headers.amount'),
      field: 'amount',
      filtering: false,
      render: (rowData: Transaction) => {
        return <Typography>{Number(rowData.amount).toLocaleString('HU-hu')}</Typography>;
      },
    },

    {
      title: t('transactionListScreen.headers.type'),
      field: 'account',
      lookup: { true: 'Worker', false: 'Company' },
      render: (rowData: Transaction) => {
        const type = rowData.account.worker ? 'Worker' : 'Company';
        return <Typography>{type}</Typography>;
      },
    },
    { title: t('transactionListScreen.headers.worker'), field: 'account.worker.name' },
    {
      title: t('transactionListScreen.headers.company'),
      field: 'account.company.name',
    },
    {
      title: t('transactionListScreen.headers.createdAt'),
      field: 'createdAt',
      filtering: false,
      render: (rowData: Transaction) => <Typography>{formatDate(rowData.createdAt)}</Typography>,
    },
    {
      title: t('generic.updatedAt'),
      field: 'updatedAt',
      filtering: false,
      render: (rowData: Transaction) => <Typography>{formatDate(rowData.updatedAt)}</Typography>,
    },
  ];
};

export const TransactionList: React.FC = () => {
  const { t } = useTranslation();
  const [transactions, setTransactions] = useState<Transaction[] | undefined>(undefined);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [getAllTransactions] = useGetAllTransactions({
    onComplete: res => {
      if (res) {
        setTransactions(res);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.managerProfileListScreen.error'),
      });
    },
  });

  useEffect(() => {
    getAllTransactions();
  }, []);

  const navigateToDetails = (id: string) => {
    return window.open(`/transactions/${id}`, '_blank');
  };

  return (
    <Grid style={{ width: '100%' }}>
      <CustomeMaterialTable
        tableProps={{
          title: 'Transaction list',
          columns: headers(t),
          isLoading: !transactions,
          data: transactions ? transactions : [],
          options: {
            toolbar: false,
          },
          actions: [
            {
              icon: () => <ArrowForwardIcon color="primary" fontSize="medium" />,
              tooltip: t('generic.details'),
              // onClick: (_event, rowData) => navigateToDetails(rowData.id),
            },
          ],
        }}
      />
    </Grid>
  );
};
