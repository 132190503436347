import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  textInputItem: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  formContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formInnerContainer: {
    width: '80%',
  },
  linkValue: {
    color: 'blue',
    cursor: 'pointer',
  },
  defaultValue: {
    color: '#000505',
  },
}));
