import { Company } from './Company.type';

export enum MANAGER_INVITE_STATUS {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export interface ManagerInvite {
  id: string;
  name: string;
  phoneNumber: string;
  status: MANAGER_INVITE_STATUS;
  acceptedAt: Date | null;
  company: Company;
  createdAt: Date;
  updatedAt: Date;
}

export enum ManagerInviteResponse {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  ALREADY_INVITED = 'ALREADY_INVITED',
}
