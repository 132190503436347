import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';

import { useStoreActions } from 'src/stores';
import { Formik, Form, Field } from 'formik';
import { FormInputField } from 'src/components/FormInputField/FormInputField';
import { useAddTransaction } from 'src/api/hooks/useFinance';

import { addTransactionSchema } from './validationSchema';
import { AddTransacModalProps, AddTransactionsData, TransactionType } from './WorkerAccount.types';
import { PopUpEnum } from 'src/stores/NotificationStore';

export const TransactionModal: React.FC<AddTransacModalProps> = ({
  isOpen,
  cancelButtonAction,
  accountId,
  defaultTransActionType,
  defaultTransActionValue,
}) => {
  const { t } = useTranslation();

  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [transactionType, setTransactionType] = useState<TransactionType>(
    defaultTransActionType || TransactionType.Credit,
  );

  const inviteInitialState = {
    amount: defaultTransActionValue || '',
    description: '',
    metaData: '',
  };

  const [addTransaction] = useAddTransaction({
    onComplete: res => {
      cancelButtonAction();
      if (res) {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.success,
          message: t('alerts.transaction.success'),
        });
      }
      cancelButtonAction();
    },
    onError: () => {
      cancelButtonAction();
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.transaction.error'),
      });
    },
  });

  const handleConfirmClicked = (data: AddTransactionsData) => {
    const amount =
      transactionType === TransactionType.Credit
        ? data.amount.replace(/\s/g, '').replace(/\./g, '')
        : `-${data.amount}`.replace(/\s/g, '').replace(/\./g, '');

    addTransaction({
      variables: {
        ...data,
        amount: amount,
        accountId,
      },
    });
  };

  const handleTransactionTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTransactionType((event.target as HTMLInputElement).value as any);
  };

  return (
    <Dialog open={isOpen} onClose={cancelButtonAction} fullWidth maxWidth="md">
      <div>
        <DialogTitle>
          <Trans>workerAccount.modal.title</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans>workerAccount.modal.subTitle</Trans>
          </DialogContentText>
          <Grid>
            <Box mb={3} mt={3} display="flex">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={transactionType}
                  name="radio-buttons-group"
                  onChange={handleTransactionTypeChange}>
                  <FormControlLabel
                    value={TransactionType.Credit}
                    control={<Radio />}
                    label="Jóváīrás"
                  />
                  <FormControlLabel
                    value={TransactionType.Charge}
                    control={<Radio />}
                    label="Terhelés"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Formik
              enableReinitialize
              initialValues={inviteInitialState}
              validationSchema={addTransactionSchema}
              onSubmit={data => handleConfirmClicked(data)}>
              {() => (
                <Form>
                  <Field
                    label={t('workerAccount.modal.amount')}
                    name="amount"
                    component={FormInputField}
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                  <Field
                    label={t('workerAccount.modal.description')}
                    name="description"
                    component={FormInputField}
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                  <Field
                    label={t('workerAccount.modal.metaData')}
                    name="metaData"
                    component={FormInputField}
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                  <DialogActions>
                    <Button onClick={cancelButtonAction} color="primary" autoFocus>
                      <Trans>generic.cancel</Trans>
                    </Button>
                    <Button color="primary" variant="contained" autoFocus type="submit">
                      <Trans>generic.confirm</Trans>
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Grid>
        </DialogContent>
      </div>
    </Dialog>
  );
};
