import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import Switch from 'react-switch';

import { useGetCompanyById, useUpdateCompany } from 'src/api/hooks/useCompany';
import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { PageHeader } from 'src/components/PageHeader/PageHeader';
import { ConfirmDialog } from 'src/components/ConfirmDialog/ConfirmDialog';
import { DetailsForm } from 'src/components/DetailsFrom/DetailsForm';
import { Company } from 'src/DTO/Company.type';
import { CustomCollapse } from 'src/components/Collapse/CustomCollapse';
import { CompanyDataPayLoad } from 'src/api/hooks/useCompany.types';
import { LocationState } from 'src/pages/WorkerProfileDetails/WorkerProfileDetails.types';

import { CompanyDetailsParams } from './CompanyDetails.types';

export const CompanyDetails: React.FC = () => {
  const { t } = useTranslation();
  const { push, location } = useHistory<LocationState>();
  const { companyId } = useParams() as CompanyDetailsParams;
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);
  const [company, setCompany] = useState<Company>({
    id: '',
    active: true,
    city: '',
    country: '',
    addressLine1: '',
    email: '',
    name: '',
    number: '',
    postCode: '',
    rake: '',
    ceo: {
      id: '',
      name: '',
      email: '',
      phoneNumber: '',
    },
    workers: [],
    managers: [],
    confirmations: [],
    roles: [],
    vatNumber: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    jobConfirmationsNeeded: true,
  });

  const updateAbleFields: CompanyDataPayLoad = _.pick(
    company,
    'id',
    'name',
    'addressLine1',
    'city',
    'country',
    'postCode',
    'number',
    'rake',
    'email',
    'vatNumber',
    'jobConfirmationsNeeded',
  );

  const [getCompanyById, { loading }] = useGetCompanyById({
    onComplete: res => {
      if (res) {
        setCompany(res);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.companyDetailsScreen.error'),
      });
    },
  });

  const [updateCompany, { loading: updateLoading }] = useUpdateCompany({
    onComplete: res => {
      if (res) {
        setCompany(res);
        setConfirmModalOpen(false);
      }
    },
    onError: () => {
      setConfirmModalOpen(false);
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.companyDetailsScreen.error'),
      });
    },
  });

  useEffect(() => {
    getCompanyById({ variables: { id: companyId } });
  }, []);

  const handleUpdateData = (value: string | boolean, key: string) => {
    setCompany({
      ...company,
      [key]: value,
    });
  };

  const doUpdateCompany = () => {
    updateCompany({ variables: { updatedCompanyData: updateAbleFields } });
  };

  if (loading || updateLoading) return <LinearProgress />;

  return (
    <>
      <PageHeader
        pageTitle={t('companyDetailsScreen.title', { name: company.name })}
        buttons={[
          {
            buttonLabel: t('companyDetailsScreen.buttonLabel'),
            onClick: () => setConfirmModalOpen(true),
            disabled: !isEdit,
          },
        ]}
      />
      <Grid container direction="row">
        <Grid item style={{ width: '50%' }}>
          <Box display="flex" pl="10%">
            <Switch
              disabled={!isEdit}
              checked={company.jobConfirmationsNeeded}
              onChange={checked => handleUpdateData(checked, 'jobConfirmationsNeeded')}
            />
            <Box ml={2}>
              <Typography>
                <Trans>companyDetailsScreen.enableConfirmations</Trans>
              </Typography>
            </Box>
          </Box>
          <DetailsForm
            inputFields={[
              { value: company.id, label: t('companyDetailsScreen.form.id'), disabled: true },
              {
                value: company.name,
                label: t('companyDetailsScreen.form.name'),
                disabled: !isEdit,
                onChange: handleUpdateData,
                key: 'name',
              },
              {
                value: company.addressLine1,
                label: t('companyDetailsScreen.form.address'),
                disabled: !isEdit,
                onChange: handleUpdateData,
                key: 'addressLine1',
              },
              {
                value: company.city,
                label: t('companyDetailsScreen.form.city'),
                disabled: !isEdit,
                onChange: handleUpdateData,
                key: 'city',
              },
              {
                value: company.country,
                label: t('companyDetailsScreen.form.country'),
                disabled: !isEdit,
                onChange: handleUpdateData,
                key: 'country',
              },
              {
                value: company.postCode,
                label: t('companyDetailsScreen.form.postCode'),
                disabled: !isEdit,
                onChange: handleUpdateData,
                key: 'postCode',
              },
              {
                value: company.email,
                label: t('companyDetailsScreen.form.email'),
                disabled: !isEdit,
                onChange: handleUpdateData,
                key: 'email',
              },
            ]}
          />
        </Grid>
        <Grid item style={{ width: '50%' }}>
          <DetailsForm
            inputFields={[
              {
                value: company.number,
                label: t('companyDetailsScreen.form.number'),
                disabled: !isEdit,
                onChange: handleUpdateData,
                key: 'number',
              },
              {
                value: company.rake,
                label: t('companyDetailsScreen.form.rake'),
                disabled: !isEdit,
                onChange: handleUpdateData,
                key: 'rake',
              },
              {
                value: company.vatNumber,
                label: t('companyDetailsScreen.form.vatNumber'),
                disabled: !isEdit,
                onChange: handleUpdateData,
                key: 'vatNumber',
              },
              {
                value: company.ceo.id,
                label: t('companyDetailsScreen.form.ceoId'),
                disabled: false,
                link: true,
                linkAction: () => push(`/manager-profile/${company.ceo.id}`),
              },
              {
                value: company.ceo.name,
                label: t('companyDetailsScreen.form.ceoName'),
                disabled: true,
              },
              {
                value: company.ceo.phoneNumber,
                label: t('companyDetailsScreen.form.ceoPhoneNumber'),
                disabled: true,
              },
              {
                value: company.ceo.email,
                label: t('companyDetailsScreen.form.email'),
                disabled: true,
              },
            ]}
          />
        </Grid>
      </Grid>
      <ConfirmDialog
        open={confirmModalOpen}
        handleConfirm={doUpdateCompany}
        title={t('companyDetailsScreen.confirmUpdate')}
        setOpen={setConfirmModalOpen}
        content={Object.entries(updateAbleFields).map(([key, value]) => {
          return {
            key,
            value: _.isString(value) || _.isBoolean(value) ? value : '',
          };
        })}
      />
    </>
  );
};
