import React from 'react';
import { Trans } from 'react-i18next';
import { format } from 'date-fns';
import _ from 'lodash';
import clsx from 'clsx';
import {
  Button,
  DialogTitle,
  DialogActions,
  Dialog,
  DialogContent,
  Typography,
  Box,
} from '@mui/material';
import { useFontStyles } from 'src/utils/useFontStyles';

import { useStyles } from './ConfirmaDialog.styles';
import { ConfirmDialogProps } from './ConfirmDialog.types';

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  handleConfirm,
  open,
  setOpen,
  title,
  content,
  cancelButtonLabel,
  confirmButtonLabel,
}) => {
  const font = useFontStyles();
  const { dataType, title: titleStyle } = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onBackdropClick={handleClose}
      // es={handleClose}
      fullWidth
      maxWidth="xl">
      <DialogTitle className={clsx(font.titleStrong, titleStyle)} id="confirmation-dialog-title">
        {title}
      </DialogTitle>
      {content && (
        <DialogContent>
          {content.map(({ key, value, index }) => (
            <Box key={`${key}_${index}`} display="flex">
              <Typography className={clsx(font.text3, dataType)}>{key}:</Typography>
              <Typography className={font.text5}>
                {_.isString(value)
                  ? value
                  : _.isDate(value)
                  ? format(new Date(value), 'yyyy-MM-dd')
                  : String(value)}
              </Typography>
            </Box>
          ))}
        </DialogContent>
      )}

      <DialogActions>
        <Button autoFocus onClick={handleClose} variant="contained">
          {cancelButtonLabel ? cancelButtonLabel : <Trans>generic.cancel</Trans>}
        </Button>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          {confirmButtonLabel ? confirmButtonLabel : <Trans>generic.save</Trans>}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
