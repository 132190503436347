import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Company } from 'src/DTO/Company.type';
import { useGetCompanies } from 'src/api/hooks/useCompany';
import { useStoreActions } from 'src/stores';
import { useTranslation } from 'react-i18next';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { headers } from './companylist.headers';
import { SwipableCompanyDetails } from './SwipableCompanyDetail';

export const CompanyList: React.FC = () => {
  const { t } = useTranslation();
  const [companies, setCompanies] = useState<Company[] | undefined>(undefined);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(undefined);

  const [getCompanies] = useGetCompanies({
    onComplete: res => {
      if (res) {
        setCompanies(res);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.managerProfileListScreen.error'),
      });
    },
  });

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <Grid style={{ height: '100%' }}>
      <DataGrid
        autoHeight
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        autoPageSize
        rows={companies ? companies : []}
        columns={headers(t)}
        loading={!companies}
        onRowClick={data => {
          setSelectedCompany(data.row as Company);
        }}
        pageSize={100}
        rowsPerPageOptions={[5, 20]}
      />
      <SwipableCompanyDetails
        data={selectedCompany}
        onClose={() => setSelectedCompany(undefined)}
      />
    </Grid>
  );
};
