import { NotificationPayload, NotificationResponse } from './useNotifications.types';

import { useApi } from './useApi';
import { BaseRequest, BaseHookRequest } from './useApi.types';

export const useNotifications: BaseHookRequest<NotificationResponse[], NotificationPayload[]> =
  params => {
    const [{ getRequest }, state] = useApi<NotificationResponse[]>(params);
    const getNotifications: BaseRequest<NotificationResponse[]> = () =>
      getRequest<NotificationPayload>('/user/notification/all');

    return [getNotifications, state];
  };

export const useSendMessage: BaseHookRequest<any, any> = params => {
  const [{ postRequest }, state] = useApi<any>(params);
  const inviteManager: BaseRequest<any> = details =>
    postRequest<any>('/admin/message/send', details?.variables);
  return [inviteManager, state];
};
