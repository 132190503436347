import { createStore, createTypedHooks, persist } from 'easy-peasy';
import { authStore, AuthStore } from './AuthStore';
import { userStore, UserStore } from './UserStore';
import { workerStore, WorkerStore } from './WorkerStore';
import { jobStore, JobStore } from './JobStore';
import { popUpStore, PopUpStore } from './NotificationStore';
import { financeStore, FinanceStore } from './FinanceStore';
import { appStore, AppStore } from './AppStore';

export interface StoreModel {
  auth: AuthStore;
  user: UserStore;
  workers: WorkerStore;
  jobs: JobStore;
  popUp: PopUpStore;
  finance: FinanceStore;
  app: AppStore;
}

const typedStore: StoreModel = {
  auth: authStore,
  user: userStore,
  workers: workerStore,
  jobs: jobStore,
  popUp: popUpStore,
  finance: financeStore,
  app: appStore,
};

const { useStoreActions, useStoreState, useStoreDispatch } = createTypedHooks<StoreModel>();

export { useStoreActions, useStoreDispatch, useStoreState };

const store = createStore(persist(typedStore));

export default store;
