import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ENVIROMENT, getConfig, getEnviroment } from '../../configs/config';
import { useStoreState } from '../../stores';
import { useAxiosResponse } from './useAxios.types';

export const useAxios = (): useAxiosResponse => {
  const userStore = useStoreState(data => data.user.data);

  const axiosClient: AxiosInstance = Axios.create({
    baseURL: getConfig().url,
    timeout: 1000 * 30,
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${userStore.token}`,
      appVersion: getConfig().version,
    },
  });

  axiosClient.interceptors.request.use(x => {
    if (getEnviroment() === ENVIROMENT.PRODUCTION) return x;
    console.log('axios-log', {
      baseUrl: x.baseURL,
      url: x.url,
      data: x.data,
      hasToken:
        x.headers && x.headers.Authorization && userStore.token && userStore.token.length > 0,
    });
    return x;
  });

  axiosClient.interceptors.response.use(
    res => {
      return res;
    },
    err => {
      return Promise.reject(err);
    },
  );

  const get = async <T>(url: string, params?: Record<any, any>) =>
    await axiosClient.get<T>(url, {
      params,
    });

  const post = async <T>(url: string, data?: Record<any, any>, config?: AxiosRequestConfig) =>
    await axiosClient.post<T>(url, data, config);

  return { get, post, axiosClient };
};
