export const convertTextToHtml = (text: string) => {
  const lines = text.split('\n');
  const html = lines
    .map(line => {
      if (line.trim().startsWith('-') || line.trim().startsWith('*')) {
        return `<li>${line.trim().slice(1).trim()}</li>`;
      }
      const indentedLine = line.replace(/^\s+/g, match => '&nbsp;'.repeat(match.length));
      return `<p>${indentedLine}</p>`;
    })
    .join('');
  return html.replace(/(<li>.*?<\/li>)/g, '<ul>$1</ul>');
};
