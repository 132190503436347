import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Grid, Typography, Box } from '@mui/material';

import { Document } from 'src/DTO/Document.type';
import { useFontStyles } from 'src/utils/useFontStyles';

import { PageHeader } from 'src/components/PageHeader/PageHeader';

import { DocumentComponent } from './DocumentComponent';
import { useGetWorkerDocuments, useGetWorkerContracts } from 'src/api/hooks/useWorker';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Contract } from 'src/DTO/Contract.type';

export const WorkerDocuments: React.FC = () => {
  const { t } = useTranslation();
  const font = useFontStyles();
  const [documents, setDocuments] = useState<Document[] | undefined>(undefined);
  const [contracts, setContracts] = useState<Contract[] | undefined>(undefined);

  const { workerId } = useParams() as { workerId: string };

  const [getDocuments] = useGetWorkerDocuments({
    onComplete: res => {
      setDocuments(res?.documents || []);
    },
  });

  const [getContracts] = useGetWorkerContracts({
    onComplete: res => {
      if (!contracts || contracts.length === 0) {
        setContracts(res || []);
      }
    },
  });

  useEffect(() => {
    getDocuments({
      variables: {
        id: workerId,
      },
    });
    getContracts({
      variables: {
        id: workerId,
      },
    });

    return () => {
      setContracts([]);
    };
  }, []);

  return (
    <>
      <PageHeader pageTitle={t('workerDocumentsScreen.title')} buttons={[]} />
      <Grid container style={{ marginLeft: 50 }} xs={12}>
        <Typography>Contracts</Typography>

        {!contracts && (
          <Grid item alignItems="center" justifyContent="center">
            <Typography className={font.caption}>Worker has no contracts yet</Typography>
          </Grid>
        )}
        {contracts &&
          contracts.map(contract => (
            <Grid item xs={12} key={contract.url}>
              <Typography
                component="a"
                className={font.caption}
                style={{ color: 'white' }}
                href={contract.url}
                target="_blank">
                {`${contract.title}    `}
                {moment(contract.createdAt).format('YYYY-MM-DD HH-mm')}
              </Typography>
            </Grid>
          ))}

        <Box mt={2}>
          <Typography>Papers</Typography>
        </Box>
        {!documents ? (
          <Grid item alignItems="center" justifyContent="center">
            <Typography className={font.caption}>
              <Trans>workerDocumentsScreen.noDocuments</Trans>
            </Typography>
          </Grid>
        ) : (
          documents.map((document, index) => (
            <Grid item xs={12} key={index}>
              <Typography className={font.caption}>
                {moment(document.createdAt).format('YYYY-MM-DD HH-mm')}
              </Typography>
              <DocumentComponent size={200} imageUrls={document.documentUrl} />
            </Grid>
          ))
        )}
      </Grid>
    </>
  );
};
