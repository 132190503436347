import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useScript from 'src/hooks/useScript';
import { useStoreActions } from 'src/stores';
import { InterviewQuestion } from 'src/api/hooks/useInterview.types';
import { useGetInterviewQuestions, useUpdateInterviewQuestions } from 'src/api/hooks/useInterview';
import { PopUpEnum } from 'src/stores/NotificationStore';

export const MyInterviewQuestion: React.FC = () => {
  const [questions, setQuestions] = useState<InterviewQuestion[]>();
  const { t } = useTranslation();
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [getInterviewQuestions] = useGetInterviewQuestions({
    onComplete: res => {
      setQuestions(res);
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.getInterviewQuestions.error'),
      });
    },
  });

  const [updateQuestions] = useUpdateInterviewQuestions({
    onComplete: res => {
      if (res) {
        setQuestions(res);
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.success,
          message: t('alerts.updateInterviewQuestions.success'),
        });
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.updateInterviewQuestions.error'),
      });
    },
  });

  useScript({
    url: 'https://embed.myinterview.com/questionsWidget/1.4.0/widget.js',
  });

  useScript({
    text: `
    var questionsBuilderConfig = {
        questions: ${JSON.stringify(questions)},
    };`,
  });

  useEffect(() => {
    getInterviewQuestions();
    window.addEventListener('message', getQuestionsFromWidget, false);
  }, []);

  const getQuestionsFromWidget = (event: any) => {
    if (event.origin !== 'https://embed.myinterview.com' || !event.data.questions) return;
    if (event.data && event.data.questions) {
      updateQuestions({ variables: { questions: event.data.questions } });
    }
  };

  return (
    <Grid style={{ width: '100%' }}>
      <form>
        <div id="myinterview-questions-container"></div>
      </form>
    </Grid>
  );
};
