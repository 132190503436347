import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';
import { useStoreActions } from 'src/stores';
import { useTranslation, TFunction } from 'react-i18next';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { useAcceptJoin, useDeleteJoin, useGetJoinRequests } from 'src/api/hooks/useJoin';
import { GetJoinPayload, WorkerContractStatus } from 'src/api/hooks/useJoin.types';
import {
  DataGrid,
  GridToolbar,
  GridValueGetterParams,
  GridRenderCellParams,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import moment from 'moment';
import { BaseRequest } from 'src/api/hooks/useApi.types';
import { SwipableJoinDetails } from './SwipableJoinDetail';

const headers = (t: TFunction, acceptJoin: BaseRequest<GetJoinPayload>) => {
  return [
    {
      headerName: t('joinRequestsScreen.headers.name'),
      field: 'worker.name',
      valueGetter: (params: any) => params.row.worker.name,
      flex: 1,
    },

    {
      headerName: t('joinRequestsScreen.headers.phoneNumber'),
      field: 'worker.phoneNumber',
      valueGetter: (params: any) => params.row.worker.phoneNumber,
      flex: 1,
    },
    {
      headerName: t('joinRequestsScreen.headers.status'),
      field: 'studentOrgContractStatus',
      flex: 1,
    },
    {
      headerName: t('joinRequestsScreen.headers.createdAt'),
      field: 'createdAt',
      valueGetter: (params: GridValueGetterParams<Date>) =>
        moment(params.row.createdAt).format('YYYY-MM-DD - HH:mm'),
      flex: 1,
    },
    {
      headerName: t('generic.actions'),
      type: 'actions',
      field: 'actions',
      filterable: false,
      getActions: (params: GridRenderCellParams<Date>) => [
        <GridActionsCellItem
          key={1}
          showInMenu
          onClick={() => {
            console.log('delete');
          }}
          icon={<DeleteForever />}
          label="Delete"
        />,
      ],
      flex: 1,
    },
  ];
};

export const JoinRequests: React.FC = () => {
  const { t } = useTranslation();
  const [joinRequests, setJoinRequests] = useState<WorkerContractStatus[] | undefined>(undefined);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);
  const [selectedJoinR, setSelectedJoinR] = useState<WorkerContractStatus | undefined>(undefined);

  const [getJoinRequests, { loading }] = useGetJoinRequests({
    onComplete: res => {
      if (res) {
        setJoinRequests(res.requests);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.joinRequest.error'),
      });
    },
  });

  const [acceptJoin, { loading: acceptJoinLoading }] = useAcceptJoin({
    onComplete: res => {
      if (res) {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.success,
          message: t('alerts.acceptJoinRequest.success'),
        });
        window.location.reload();
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.acceptJoinRequest.error'),
      });
    },
  });

  const [deleteJoin, { loading: deleteJoinRequest }] = useDeleteJoin({
    onComplete: res => {
      if (res) {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.success,
          message: t('alerts.acceptJoinRequest.success'),
        });
        window.location.reload();
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.acceptJoinRequest.error'),
      });
    },
  });

  useEffect(() => {
    getJoinRequests();
  }, []);

  return (
    <Grid style={{ width: '100%' }}>
      <DataGrid
        autoHeight
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        autoPageSize
        rows={joinRequests ? joinRequests : []}
        columns={headers(t, acceptJoin)}
        loading={loading || acceptJoinLoading || deleteJoinRequest}
        onRowClick={data => {
          setSelectedJoinR(data.row as WorkerContractStatus);
        }}
        pageSize={100}
        rowsPerPageOptions={[5, 20]}
      />
      <SwipableJoinDetails
        acceptJoin={acceptJoin}
        deleteJoin={deleteJoin}
        acceptJoinLoading={acceptJoinLoading}
        data={selectedJoinR}
        onClose={() => setSelectedJoinR(undefined)}
      />
    </Grid>
  );
};
