import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Transaction, TransferMetadata } from 'src/DTO/Transaction.type';
import { PageHeader } from 'src/components/PageHeader/PageHeader';
import { DetailsForm } from 'src/components/DetailsFrom/DetailsForm';
import { useGetTransactionById } from 'src/api/hooks/useFinance';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { useStoreActions } from 'src/stores';

export interface TransactionDetailsParams {
  transactionId: string;
}

export const TransactionDetails: React.FC = () => {
  const { t } = useTranslation();
  const { transactionId } = useParams() as TransactionDetailsParams;
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [transaction, setTransaction] = useState<Transaction>({
    id: '',
    account: {
      id: '',
      balance: '',
      name: '',
      company: undefined,
      worker: undefined,
    },
    description: '',
    amount: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    metadata: {
      workerName: '',
      jobConfirmationYear: 0,
      jobConfirmationMonth: 0,
      jobConfirmationHoursWorked: 0,
      jobConfirmationInvoiced: 0,
    },
  });

  const [getJobConfirmation] = useGetTransactionById({
    onComplete: res => {
      if (res) {
        setTransaction(res);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.transactionDetailScreen.error'),
      });
    },
  });

  const filledDescription = (metadata: TransferMetadata, description: string) => {
    if (metadata) {
      description = description.replace(
        /{{jobConfirmationYear}}/gm,
        String(metadata.jobConfirmationYear),
      );
      description = description.replace(
        /{{jobConfirmationMonth}}/gm,
        String(metadata.jobConfirmationMonth),
      );
      description = description.replace(/{{workerName}}/gm, String(metadata.workerName));
      description = description.replace(
        /{{jobConfirmationHoursWorked}}/gm,
        `${metadata.jobConfirmationHoursWorked}`,
      );
      description = description.replace(
        /{{jobConfirmationInvoiced}}/gm,
        ` ${metadata.jobConfirmationInvoiced} HUF`,
      );
    }
    return description;
  };

  useEffect(() => {
    getJobConfirmation({ variables: { id: transactionId } });
  }, []);

  return (
    <>
      <PageHeader
        pageTitle={t('transactionDetailsScreen.title', { id: transaction.id })}
        buttons={[]}
      />
      <DetailsForm
        inputFields={[
          { value: transaction.id, label: t('transactionDetailsScreen.form.id'), disabled: true },
          {
            value: filledDescription(transaction.metadata, transaction.description),
            label: t('transactionDetailsScreen.form.description'),
            disabled: true,
          },
          {
            value: `${transaction.amount} Huf`,
            label: t('transactionDetailsScreen.form.amount'),
            disabled: true,
          },
          {
            value: transaction.account.id,
            label: t('transactionDetailsScreen.form.accountId'),
            disabled: true,
          },
          {
            value: `${transaction.account.balance} Huf`,
            label: t('transactionDetailsScreen.form.accountBalance'),
            disabled: true,
          },
          {
            value: transaction.account.name,
            label: t('transactionDetailsScreen.form.accountName'),
            disabled: true,
          },
          {
            value: transaction.account.company?.id,
            label: t('transactionDetailsScreen.form.companyId'),
            disabled: true,
          },
          {
            value: transaction.account.company?.name,
            label: t('transactionDetailsScreen.form.companyName'),
            disabled: true,
          },
          {
            value: transaction.account.worker?.id,
            label: t('transactionDetailsScreen.form.workerId'),
            disabled: true,
          },
          {
            value: transaction.account.worker?.name,
            label: t('transactionDetailsScreen.form.workerName'),
            disabled: true,
          },
          {
            value: transaction.createdAt
              ? format(new Date(transaction.createdAt), 'yyyy-MM-dd')
              : transaction.createdAt,
            label: t('transactionDetailsScreen.form.createdAt'),
            disabled: true,
          },
        ]}
      />
    </>
  );
};
