export const expiredStudentIdEmail = {
  hu: {
    subject: 'A diákigazolványod érvényessége le fog járni 1 hónapon belül',
    html: `
  Kedves {workerName},

  A diákigazolványod érvényessége le fog járni 1 hónapon belül, kérlek frissítsd amint tudod és töltsd fel a Della App-on keresztül!

  Köszönöm!

  Üdv,
  Kiss Péter Pál
  Della Diák Hero
  Telefonszám: + 36 20 421 8158
  email: info@hellostudent.hu
  messenger: facebook.com/peterpal.kiss.50

  U.I.: Keress nyugodtan whatsappon, viberen vagy signalon is a fenti számon vagy csak nyomj rá a válasz gombra.
    `,
  },
  en: {
    subject: 'Your Student ID will expire within one month',
    html: `
  Dear {workerName},

  Your Student ID will expire within one month. Please update it as soon as you can and upload it through the Della App!

  Thank you,

  Üdv,
  Péter Pál Kiss
  Della Diák Hero
  Phone: + 36 20 421 8158
  email: info@hellostudent.hu
  messenger: facebook.com/peterpal.kiss.50

  P.S.: For any questions or problems you may have, please contact us via the in-app chat or by calling us at +36 30 098 6976.
    `,
  },
};
