import { expiredStudentIdEmail } from '../email/expiredStudentIdEmail';
import { expiredStudentIdPush } from '../push/expiredStudentIdPush';
import { expiredStudentIdSMS } from '../sms/expiredStudentIdSMS';

export const eventTypes = [
  {
    key: 'CUSTOM EMAIL',
    value: undefined,
  },
  {
    key: 'CUSTOM SMS',
    value: undefined,
  },
  {
    key: 'CUSTOM PUSH',
    value: undefined,
  },
  {
    key: 'PUSH 1',
    value: { push: { hu: expiredStudentIdPush.hu, en: expiredStudentIdPush.en } },
  },
  {
    key: 'PUSH 2',
    value: { push: { hu: expiredStudentIdPush.hu, en: expiredStudentIdPush.en } },
  },
  {
    key: 'EMAIL 1',
    value: {
      email: {
        html: { hu: expiredStudentIdEmail.hu.html, en: expiredStudentIdEmail.en.html },
        subject: { hu: expiredStudentIdEmail.hu.subject, en: expiredStudentIdEmail.en.subject },
      },
    },
  },
  {
    key: 'EMAIL 2',
    value: {
      email: {
        html: { hu: expiredStudentIdEmail.hu.html, en: expiredStudentIdEmail.en.html },
        subject: { hu: expiredStudentIdEmail.hu.subject, en: expiredStudentIdEmail.en.subject },
      },
    },
  },
  {
    key: 'EMAIL 3',
    value: {
      email: {
        html: { hu: expiredStudentIdEmail.hu.html, en: expiredStudentIdEmail.en.html },
        subject: { hu: expiredStudentIdEmail.hu.subject, en: expiredStudentIdEmail.en.subject },
      },
    },
  },
  {
    key: 'EMAIL 4',
    value: {
      email: {
        html: { hu: expiredStudentIdEmail.hu.html, en: expiredStudentIdEmail.en.html },
        subject: { hu: expiredStudentIdEmail.hu.subject, en: expiredStudentIdEmail.en.subject },
      },
    },
  },
  {
    key: 'SMS 1',
    value: { sms: { hu: expiredStudentIdSMS.hu, en: expiredStudentIdSMS.en } },
  },
  {
    key: 'SMS 2',
    value: { sms: { hu: expiredStudentIdSMS.hu, en: expiredStudentIdSMS.en } },
  },
  {
    key: 'SMS 3',
    value: { sms: { hu: expiredStudentIdSMS.hu, en: expiredStudentIdSMS.en } },
  },
  {
    key: 'SMS 4',
    value: { sms: { hu: expiredStudentIdSMS.hu, en: expiredStudentIdSMS.en } },
  },
];
