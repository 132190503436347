import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, LinearProgress } from '@mui/material';
import _ from 'lodash';
import { format } from 'date-fns';

import { PopUpEnum } from 'src/stores/NotificationStore';
import { ConfirmDialog } from 'src/components/ConfirmDialog/ConfirmDialog';
import { PageHeader } from 'src/components/PageHeader/PageHeader';
import { DetailsForm } from 'src/components/DetailsFrom/DetailsForm';
import { useStoreActions } from 'src/stores';
import {
  useGetJobConfirmationById,
  useUpdateJobConfirmaton,
  useCloseJobConfirmaton,
  useConfirmationHistory,
  useCalculateJobWage,
} from 'src/api/hooks/useJob';
import { FastInfo } from 'src/components/FastInfo/FastInfo';
import { Log } from 'src/DTO/Log.type';

import {
  ConfirmationLog,
  JobConfirmationDetailsParams,
  JobConfirmationState,
} from './JobConfirmationDetails.types';
import { JobConfirmationModal } from './JobConfirmationModal';
import { HistoryModal } from './HistoryModal';
import moment from 'moment';
import { WagesCard } from './WagesCard';

export const JobConfirmationDetails: React.FC = () => {
  const { t } = useTranslation();
  const { confirmationId } = useParams() as JobConfirmationDetailsParams;
  const { push } = useHistory();
  const [calculateJobWage, { data }] = useCalculateJobWage();

  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [historyData, setHistoryData] = useState<Log[]>([]);
  const [originalHoursWorked, setOriginalHoursWorked] = useState<number>(0);
  const [jobConfirmationModalOpen, setJobConfirmationModalOpen] = useState<boolean>(false);
  const [closeModalOpen, setCloseModalOpen] = useState<boolean>(false);
  const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);
  const [jobConfirmation, setJobConfirmation] = useState<JobConfirmationState>({
    id: '',
    job: {
      id: '',
    },
    company: {
      id: '',
      name: '',
    },
    hoursWorked: 0,
    closedBy: null,
    invoice: {
      id: '',
    },
    manager: {
      id: '',
    },
    hourlyGrossWage: 0,
    year: 0,
    month: 0,
    worker: {
      id: '',
      name: '',
      birthDay: '',
    },
    managerConfirmedAt: new Date(),
    workerConfirmedAt: new Date(),
    createdAt: new Date(),
    updatedAt: new Date(),
  });

  const jobConfirmationProps = _.pick(
    jobConfirmation,
    'id',
    'hoursWorked',
    'hourlyGrossWage',
    'year',
    'month',
    'managerConfirmedAt',
    'workerConfirmedAt',
  );

  const [getJobConfirmation, { loading }] = useGetJobConfirmationById({
    onComplete: res => {
      if (res) {
        setJobConfirmation(res);
        setOriginalHoursWorked(res.hoursWorked);

        calculateJobWage({
          variables: {
            hourlyGrossWage: res.hourlyGrossWage,
            monthlyHours: Number(res.hoursWorked),
          },
        });
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.jobConfirmationDetailScreen.error'),
      });
    },
  });

  const [updateJobConfirmation, { loading: updateLoading }] = useUpdateJobConfirmaton({
    onComplete: () => {
      push('/confirmations');
    },
    onError: () => {
      setJobConfirmationModalOpen(false);
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.jobConfirmationDetailScreen.error'),
      });
    },
  });

  const [closeJobConfirmation] = useCloseJobConfirmaton({
    onComplete: () => {
      setCloseModalOpen(false);
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.success,
        message: t('alerts.jobConfirmationClose.success'),
      });
    },
    onError: () => {
      setCloseModalOpen(false);
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.jobConfirmationClose.error'),
      });
    },
  });

  const [getConfirmationHistory] = useConfirmationHistory({
    onComplete: res => {
      if (res) {
        setHistoryData(res);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.jobConfirmationHistory.error'),
      });
    },
  });

  const handleUpdateData = (value: string, key: string) => {
    setJobConfirmation({
      ...jobConfirmation,
      [key]: value,
    });
  };

  const handleIsEdit = () => {
    setIsEdit(!isEdit);
  };

  const doUpdateJobConfirmation = () => {
    updateJobConfirmation({
      variables: { id: jobConfirmation.id, hoursWorked: jobConfirmation.hoursWorked },
    });
  };

  const doCloseJobConfirmation = () => {
    closeJobConfirmation({ variables: { id: jobConfirmation.id } });
  };

  useEffect(() => {
    getJobConfirmation({ variables: { id: confirmationId } });
  }, []);

  useEffect(() => {
    if (jobConfirmation.id) {
      getConfirmationHistory({
        variables: {
          managerId: jobConfirmation.manager?.id,
          workerId: jobConfirmation.worker.id,
          jobConfirmationId: jobConfirmation.id,
        },
      });
    }
  }, [jobConfirmation]);

  const getFastInfoLabels = () => {
    const labels: string[] = [];
    if (jobConfirmation.closedBy !== null) {
      labels.push(
        t('jobConfirmationDetailsScreen.closed', {
          date: format(new Date(jobConfirmation.updatedAt), 'yyyy-MM-dd HH:mm'),
          closedBy: jobConfirmation.closedBy,
        }),
      );
    }
    if (jobConfirmation.workerConfirmedAt) {
      labels.push(
        t('jobConfirmationDetailsScreen.confirmed', {
          connectionType: 'Worker',
          date: format(new Date(jobConfirmation.workerConfirmedAt), 'yyyy-MM-dd HH:mm'),
        }),
      );
    }
    if (jobConfirmation.managerConfirmedAt) {
      labels.push(
        t('jobConfirmationDetailsScreen.confirmed', {
          connectionType: 'Manager',
          date: format(new Date(jobConfirmation.managerConfirmedAt), 'yyyy-MM-dd HH:mm'),
        }),
      );
    }
    /*    if (jobConfirmation.invoice.id) {
      labels.push(t('jobConfirmationDetailsScreen.invoiced'));
    } else {
      labels.push(t('jobConfirmationDetailsScreen.notInvoiced'));
    } */
    return labels;
  };

  if (loading || updateLoading || !data) return <LinearProgress />;

  const workerWages =
    moment(jobConfirmation.worker.birthDay).diff('years') > 25 ? data.wageOver25 : data.wageUnder25;

  return (
    <>
      <PageHeader
        pageTitle={t('jobConfirmationDetailsScreen.title', { id: confirmationId })}
        switchButton={{
          switchAction: handleIsEdit,
          switchChecked: isEdit,
        }}
        buttons={[
          {
            buttonLabel: t('jobConfirmationDetailsScreen.buttonLabel'),
            onClick: () =>
              originalHoursWorked !== jobConfirmation.hoursWorked &&
              setJobConfirmationModalOpen(true),
            disabled: !isEdit || jobConfirmation.closedBy !== null,
          },
          {
            buttonLabel: t('jobConfirmationDetailsScreen.close'),
            onClick: () => setCloseModalOpen(true),
            disabled: jobConfirmation.closedBy !== null,
          },
          {
            buttonLabel: t('jobConfirmationDetailsScreen.history'),
            onClick: () => setHistoryModalOpen(true),
            disabled: false,
          },
        ]}
      />
      {getFastInfoLabels().length > 0 && (
        <FastInfo
          fastInfo={[
            { key: t('jobConfirmationDetailsScreen.status'), chipLabels: getFastInfoLabels() },
          ]}
        />
      )}
      <Grid container direction="row">
        <Grid item direction="column" style={{ width: '50%' }}>
          <DetailsForm
            inputFields={[
              {
                value: jobConfirmation.id,
                label: t('jobConfirmationDetailsScreen.form.id'),
                disabled: true,
              },
              {
                value: jobConfirmation.company.name,
                label: t('jobConfirmationDetailsScreen.form.companyName'),
                disabled: true,
              },
              {
                value: jobConfirmation.worker.name,
                label: t('jobConfirmationDetailsScreen.form.workerName'),
                disabled: true,
              },

              {
                value: jobConfirmation.month,
                label: t('jobConfirmationDetailsScreen.form.month'),
                disabled: true,
              },
              {
                value: jobConfirmation.hoursWorked,
                label: t('jobConfirmationDetailsScreen.form.hoursWorked'),
                disabled: !isEdit || jobConfirmation.closedBy !== null,
                onChange: handleUpdateData,
                key: 'hoursWorked',
              },
              {
                value: jobConfirmation.hourlyGrossWage,
                label: t('jobConfirmationDetailsScreen.form.hourlyGrossWage'),
                disabled: true,
              },

              {
                value: workerWages.totalInvoicedAmount || 0,
                label: t('jobConfirmationDetailsScreen.form.totalInvoiced'),
                disabled: true,
              },
              {
                value: workerWages.totalCommissionAmount || 0,
                label: t('jobConfirmationDetailsScreen.form.totalCommission'),
                disabled: true,
              },
              {
                value: workerWages.totalGrossAmount || 0,
                label: t('jobConfirmationDetailsScreen.form.totalGross'),
                disabled: true,
              },
              {
                value: workerWages.totalPersonalTaxAmount || 0,
                label: t('jobConfirmationDetailsScreen.form.totalPersonal'),
                disabled: true,
              },
              {
                value: workerWages.totalNetAmount || 0,
                label: t('jobConfirmationDetailsScreen.form.totalNet'),
                disabled: true,
              },

              {
                value: jobConfirmation.closedBy === null ? 'Not closed' : jobConfirmation.closedBy,
                label: t('jobConfirmationDetailsScreen.form.closedBy'),
                disabled: true,
              },
              {
                value: jobConfirmation.workerConfirmedAt
                  ? format(new Date(jobConfirmation.workerConfirmedAt), 'yyyy-MM-dd HH:mm')
                  : t('jobConfirmationDetailsScreen.workerDidNotConfirm'),
                label: t('jobConfirmationDetailsScreen.form.workerConfirmedAt'),
                disabled: true,
              },
              {
                value: jobConfirmation.managerConfirmedAt
                  ? format(new Date(jobConfirmation.managerConfirmedAt), 'yyyy-MM-dd HH:mm')
                  : t('jobConfirmationDetailsScreen.managerDidNotConfirm'),
                label: t('jobConfirmationDetailsScreen.form.managerConfirmedAt'),
                disabled: true,
              },
              {
                value: format(new Date(jobConfirmation.createdAt), 'yyyy-MM-dd HH:mm'),
                label: t('jobConfirmationDetailsScreen.form.createdAt'),
                disabled: true,
              },
              {
                value: format(new Date(jobConfirmation.updatedAt), 'yyyy-MM-dd HH:mm'),
                label: t('jobConfirmationDetailsScreen.form.updatedAt'),
                disabled: true,
              },
            ]}
          />
        </Grid>
        <Grid item style={{ width: '50%' }}>
          <DetailsForm
            inputFields={[
              {
                value: jobConfirmation.job.id,
                label: t('jobConfirmationDetailsScreen.form.jobId'),
                disabled: false,
                link: true,
                linkAction: () => push(`/jobs/${jobConfirmation.job.id}`),
              },
              {
                value: jobConfirmation.company.id,
                label: t('jobConfirmationDetailsScreen.form.companyId'),
                disabled: false,
                link: true,
                linkAction: () => push(`/companies/${jobConfirmation.company.id}`),
              },
              {
                value: jobConfirmation.worker.id,
                label: t('jobConfirmationDetailsScreen.form.workerId'),
                disabled: false,
                link: true,
                linkAction: () => push(`/worker-profile/${jobConfirmation.worker.id}`),
              },
            ]}
          />
          {jobConfirmation.wages && <WagesCard wages={jobConfirmation.wages} />}
        </Grid>
      </Grid>
      <JobConfirmationModal
        isOpen={jobConfirmationModalOpen}
        updatedHoursWorked={jobConfirmation.hoursWorked}
        hourlyGrossWage={jobConfirmation.hourlyGrossWage}
        confirmButtonAction={doUpdateJobConfirmation}
        cancelButtonAction={() => setJobConfirmationModalOpen(false)}
        originalWages={workerWages}
      />
      <ConfirmDialog
        open={closeModalOpen}
        handleConfirm={doCloseJobConfirmation}
        title={t('jobConfirmationDetailsScreen.confirmClose')}
        setOpen={setCloseModalOpen}
        content={Object.entries(jobConfirmationProps).map(([key, value]) => {
          return {
            key,
            value: _.isString(value) || _.isNumber(value) || _.isBoolean(value) ? value : '',
          };
        })}
      />
      <HistoryModal
        isOpen={historyModalOpen}
        cancelButtonAction={() => setHistoryModalOpen(false)}
        logData={historyData as ConfirmationLog[]}
      />
    </>
  );
};
