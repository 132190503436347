import { action, Action } from 'easy-peasy';
import { ADMIN_ROLE } from 'src/DTO/AdminUser.type';
export interface UserStoreData {
  id?: string;
  email: string;
  isLogedIn: boolean;
  token?: string;
  tokenExpire?: Date;
  firstName?: string;
  lastName?: string;
  role?: ADMIN_ROLE;
}

export interface UserStore {
  data: UserStoreData;
  update: Action<UserStore, UserStoreData>;
  reset: Action<UserStore>;
}

const initialState = {
  id: '',
  token: '',
  isLogedIn: false,
  firstName: '',
  lastName: '',
  email: '',
};

export const userStore: UserStore = {
  data: initialState,
  update: action((state, payload) => {
    state.data = { ...state.data, ...payload };
  }),
  reset: action(state => {
    state.data = { ...initialState };
  }),
};
