import React from 'react';
import { StoreProvider } from 'easy-peasy';
import store from './stores';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { AppRouter } from './router/AppRouter';

import { theme } from './utils/theme';
import './i18next';
import { AppRehydratationProvider } from './providers/providers/AppRehydratationProvider';

function App() {
  return (
    <StoreProvider store={store}>
      <AppRehydratationProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppRouter />
        </ThemeProvider>
      </AppRehydratationProvider>
    </StoreProvider>
  );
}

export default App;
