export enum PaymentMethod {
  ARUHITEL = 'aruhitel',
  BANKCARD = 'bankcard',
  BARION = 'barion',
  BARTER = 'barter',
  CASH = 'cash',
  CASH_ON_DELIVERY = 'cash_on_delivery',
  COUPON = 'coupon',
  ELORE_UTALAS = 'elore_utalas',
  EP_KARTYA = 'ep_kartya',
  KOMPENZACIO = 'kompenzacio',
  LEVONAS = 'levonas',
  ONLINE_BANKCARD = 'online_bankcard',
  PAYONEER = 'payoneer',
  PAYPAL = 'paypal',
  PAYPAL_UTOLAG = 'paypal_utolag',
  PAYU = 'payu',
  PICK_PACK_PONT = 'pick_pack_pont',
  POSTAI_CSEKK = 'postai_csekk',
  POSTAUTALVANY = 'postautalvany',
  SKRILL = 'skrill',
  SZEP_CARD = 'szep_card',
  TRANSFERWISE = 'transferwise',
  UPWORK = 'upwork',
  UTALVANY = 'utalvany',
  VALTO = 'valto',
  WIRE_TRANSFER = 'wire_transfer',
}
