import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Grid, LinearProgress } from '@mui/material';
import _ from 'lodash';

import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { Document } from 'src/DTO/Document.type';
import { useSendInterviewLink } from 'src/api/hooks/useInterview';
import { useGetWorkerById, useUpdateWorker } from 'src/api/hooks/useWorker';
import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { ConfirmDialog } from 'src/components/ConfirmDialog/ConfirmDialog';
import { WORKER_STATUS } from 'src/DTO/WorkerStatus.enum';
import { PageHeader } from 'src/components/PageHeader/PageHeader';
import { boolSelectItems, DetailsForm } from 'src/components/DetailsFrom/DetailsForm';
import { JobConfirmation } from 'src/DTO/JobConfirmation.type';

import { WorkerDetailsParams } from './WorkerProfileDetails.types';
import { GENDER_TYPE } from 'src/DTO/Gender.enum';
import { SUPPORTED_LANGS } from 'src/DTO/SupportedLang.enum';
import {
  compareTaxNumbersWithBirthDay,
  validateSocialSecurityNumber,
  validateTaxNumber,
} from 'src/utils/validators';

export const WorkerProfileDetails: React.FC = () => {
  const { t } = useTranslation();
  const { workerId } = useParams() as WorkerDetailsParams;
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [socialSecError, setSocialSecError] = useState<string>('');
  const [taxNumberError, setTaxNumberError] = useState<string>('');
  const [linkConfirmModalOpen, setLinkConfirmModalOpen] = useState<boolean>(false);
  const [jobConfirmations, setJobConfirmations] = useState<JobConfirmation[]>([]);
  const [workerDocuments, setWorkerDocuments] = useState<Document[] | undefined>([]);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);
  const [workerProfile, setWorkerProfile] = useState<WorkerProfile>({
    id: '',
    name: '',
    firstName: '',
    lastName: '',
    birthDay: '',
    phoneNumber: '',
    email: '',
    status: WORKER_STATUS.NOT_INVITED,
    hasPaidStudentOrg: false,
    isDeclared: false,
    isForeignDeclared: false,
    createdAt: new Date(),
    updatedAt: new Date(),
  });

  const [sendLink] = useSendInterviewLink({
    onComplete: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.success,
        message: t('alerts.sendInterviewLink.success'),
      });
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.sendInterviewLink.error'),
      });
    },
  });

  const [getWorkerDetails, { loading }] = useGetWorkerById({
    onComplete: res => {
      if (res) {
        setWorkerProfile(res.worker);
        setJobConfirmations(res.confirmations);
        setWorkerDocuments(res.worker.documents);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.workerProfileDetailsScreen.error'),
      });
    },
  });

  const [updateWorker, { loading: updateLoading }] = useUpdateWorker({
    onComplete: res => {
      if (res) {
        setWorkerProfile(res);
        setConfirmModalOpen(false);
        setTaxNumberError('');
      }
    },
    onError: () => {
      setConfirmModalOpen(false);
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.workerProfileDetailsScreen.error'),
      });
    },
  });

  useEffect(() => {
    getWorkerDetails({ variables: { id: workerId } });
  }, []);

  const handleUpdateData = (value: string, key: string) => {
    setWorkerProfile({
      ...workerProfile,
      [key]: value,
    });
  };

  const doUpdateWorker = () => {
    setConfirmModalOpen(false);

    if (
      workerProfile.nationality &&
      workerProfile.nationality == 'HU' &&
      !validateSocialSecurityNumber(workerProfile.socialSecurityNumber || '')
    ) {
      setSocialSecError('Social sec validation error');
      return;
    }

    const isValid =
      validateTaxNumber(workerProfile.taxNumber || '') &&
      compareTaxNumbersWithBirthDay(workerProfile.taxNumber || '', workerProfile.birthDay);
    if (isValid) {
      updateWorker({ variables: { ...workerProfile } });
      setTaxNumberError('');
      setSocialSecError('');
    } else setTaxNumberError('Not valid');
  };

  const sendInteviewLink = () => {
    sendLink({ variables: { workerId: workerProfile.id } });
  };

  if (loading || updateLoading) return <LinearProgress />;

  return (
    <>
      <PageHeader
        pageTitle={t('workerProfileDetailsScreen.title', { name: workerProfile.name })}
        buttons={[
          {
            buttonLabel: t('workerProfileDetailsScreen.buttonLabel'),
            onClick: () => setConfirmModalOpen(true),
          },
        ]}
      />
      <Grid container direction="row">
        <Grid item style={{ width: '50%' }}>
          <DetailsForm
            inputFields={[
              {
                value: workerProfile.id,
                label: t('workerProfileDetailsScreen.form.id'),
                disabled: true,
              },
              {
                value: workerProfile.name,
                label: t('workerProfileDetailsScreen.form.name'),
                disabled: true,
                onChange: handleUpdateData,
                key: 'name',
              },
              {
                value: workerProfile.firstName,
                label: t('workerProfileDetailsScreen.form.firstName'),
                onChange: handleUpdateData,
                key: 'firstName',
              },
              {
                value: workerProfile.lastName,
                label: t('workerProfileDetailsScreen.form.lastName'),
                onChange: handleUpdateData,
                key: 'lastName',
              },
              {
                value: workerProfile.birthName,
                label: t('workerProfileDetailsScreen.form.birthName'),
                onChange: handleUpdateData,
                key: 'birthName',
              },
              {
                value:
                  workerProfile.address ??
                  `${workerProfile?.postCode} ${workerProfile.city} ${workerProfile.addressLine1}`,
                label: t('workerProfileDetailsScreen.form.address'),
                onChange: handleUpdateData,
                key: 'address',
                disabled: true,
              },
              {
                value: workerProfile?.postCode,
                label: t('workerProfileDetailsScreen.form.postCode'),
                onChange: handleUpdateData,
                key: 'postCode',
              },
              {
                value: workerProfile?.city,
                label: t('workerProfileDetailsScreen.form.city'),
                onChange: handleUpdateData,
                key: 'city',
              },
              {
                value: workerProfile?.addressLine1,
                label: t('workerProfileDetailsScreen.form.addressLine1'),
                onChange: handleUpdateData,
                key: 'addressLine1',
              },
              {
                value: workerProfile.gender,
                label: t('workerProfileDetailsScreen.form.gender'),
                key: 'gender',
                type: 'select',
                onChange: handleUpdateData,
                selectValue: workerProfile.gender,
                selectItems: Object.entries(GENDER_TYPE).map(([key, value]) => {
                  return {
                    label: key,
                    value,
                  };
                }),
              },
              {
                value: workerProfile.nationality,
                label: t('workerProfileDetailsScreen.form.nationality'),
                onChange: handleUpdateData,
                key: 'nationality',
              },
              {
                value: workerProfile.birthPlace,
                label: t('workerProfileDetailsScreen.form.birthPlace'),
                onChange: handleUpdateData,
                key: 'birthPlace',
              },
              {
                value: workerProfile.birthDay,
                label: t('workerProfileDetailsScreen.form.birthDay'),
                onChange: handleUpdateData,
                key: 'birthDay',
                type: 'date',
              },
              {
                value: workerProfile.mothersFirstName,
                label: t('workerProfileDetailsScreen.form.mothersFirstName'),
                onChange: handleUpdateData,
                key: 'mothersFirstName',
              },
              {
                value: workerProfile.mothersLastName,
                label: t('workerProfileDetailsScreen.form.mothersLastName'),
                onChange: handleUpdateData,
                key: 'mothersLastName',
              },
              {
                value: workerProfile.email,
                label: t('workerProfileDetailsScreen.form.email'),
                onChange: handleUpdateData,
                key: 'email',
              },
              {
                value: workerProfile.phoneNumber,
                label: t('workerProfileDetailsScreen.form.phoneNumber'),
                onChange: handleUpdateData,
                key: 'phoneNumber',
              },
            ]}
          />
        </Grid>
        <Grid item style={{ width: '50%' }}>
          <DetailsForm
            inputFields={[
              {
                value: workerProfile.status,
                label: t('workerProfileDetailsScreen.form.status'),
                key: 'status',
                type: 'select',
                onChange: handleUpdateData,
                selectValue: workerProfile.status,
                selectItems: Object.entries(WORKER_STATUS).map(([key, value]) => {
                  return {
                    label: key,
                    value,
                  };
                }),
              },
              {
                value: workerProfile.studentIdNumber,
                label: t('workerProfileDetailsScreen.form.studentId'),
                onChange: handleUpdateData,
                key: 'studentIdNumber',
              },
              {
                value: workerProfile.taxNumber,
                label: t('workerProfileDetailsScreen.form.taxNumber'),
                onChange: handleUpdateData,
                key: 'taxNumber',
                error: taxNumberError,
              },
              {
                value: workerProfile.socialSecurityNumber,
                label: t('workerProfileDetailsScreen.form.socailsecurityNumber'),
                onChange: handleUpdateData,
                key: 'socialSecurityNumber',
                error: socialSecError,
              },
              {
                value: workerProfile.bankAccountNumber,
                label: t('workerProfileDetailsScreen.form.bankAccountNumber'),
                onChange: handleUpdateData,
                key: 'bankAccountNumber',
              },
              {
                value: workerProfile.hasPaidStudentOrg,
                label: t('workerProfileDetailsScreen.form.hasPaidStudentOrg'),
                onChange: handleUpdateData,
                key: 'hasPaidStudentOrg',
                type: 'select',
                selectValue: workerProfile.hasPaidStudentOrg,
                selectItems: boolSelectItems,
              },
              {
                value: workerProfile.interviewId,
                label: t('workerProfileDetailsScreen.form.interviewId'),
                disabled: true,
                key: 'interviewId',
              },
              {
                value: workerProfile.isDeclared,
                label: t('workerProfileDetailsScreen.form.isDeclared'),
                onChange: handleUpdateData,
                key: 'isDeclared',
                type: 'select',
                selectValue: workerProfile.isDeclared,
                selectItems: boolSelectItems,
              },
              {
                value: workerProfile.isTransactionalWorker,
                label: 'Transactional worker',
                onChange: handleUpdateData,
                key: 'isTransactionalWorker',
                type: 'select',
                selectValue: workerProfile.isTransactionalWorker,
                selectItems: boolSelectItems,
              },
              {
                value: workerProfile.language,
                label: t('workerProfileDetailsScreen.form.language'),
                onChange: handleUpdateData,
                key: 'language',
                type: 'select',
                selectValue: workerProfile.language,
                selectItems: Object.entries(SUPPORTED_LANGS).map(([key, value]) => {
                  return {
                    label: key,
                    value,
                  };
                }),
              },
              {
                value: workerDocuments && workerDocuments?.length > 0 ? true : false,
                label: t('workerProfileDetailsScreen.form.documents'),
                disabled: true,
              },
              {
                value: workerProfile.createdAt
                  ? format(new Date(workerProfile.createdAt), 'yyyy-MM-dd')
                  : workerProfile.createdAt,
                label: t('workerProfileDetailsScreen.form.createdAt'),
                disabled: true,
              },
            ]}
          />
        </Grid>
      </Grid>
      <ConfirmDialog
        open={confirmModalOpen}
        handleConfirm={doUpdateWorker}
        title={t('workerProfileDetailsScreen.confirmUpdate')}
        setOpen={setConfirmModalOpen}
        content={Object.entries(workerProfile).map(([key, value]) => {
          return {
            key,
            value: _.isString(value) || _.isBoolean(value) ? value : '',
          };
        })}
      />
      <ConfirmDialog
        open={linkConfirmModalOpen}
        handleConfirm={sendInteviewLink}
        title={t('workerProfileDetailsScreen.linkSendConfirm')}
        setOpen={() => setLinkConfirmModalOpen(false)}
        cancelButtonLabel={t('generic.no')}
        confirmButtonLabel={t('generic.yes')}
      />
    </>
  );
};
