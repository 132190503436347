import React from 'react';
import { Trans } from 'react-i18next';
import { format } from 'date-fns';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { ConfirmationLog, HistoryModalProps } from './JobConfirmationDetails.types';
import { sortByDate } from 'src/utils/sortByDate';
import { useStyles } from './JobConfirmationModal.styles';

export const HistoryModal: React.FC<HistoryModalProps> = ({
  logData,
  isOpen,
  cancelButtonAction,
}) => {
  const { historyModalText, historyModalHours } = useStyles();

  return (
    <Dialog open={isOpen} onClose={cancelButtonAction} fullWidth maxWidth="md">
      <div>
        <Grid style={{ minHeight: 50 }}>
          <DialogTitle>
            <Trans>jobConfirmationDetailsScreen.historyModal.title</Trans>
          </DialogTitle>
        </Grid>
        <DialogContent>
          <Box>
            {sortByDate(logData).map((log: ConfirmationLog, index: number) => (
              <Box display="flex" key={`${index}_${Math.random()}`}>
                <Typography className={historyModalText}>{index + 1}</Typography>
                <Typography className={historyModalText}>
                  {format(new Date(log.createdAt), 'yyyy-MM-dd HH:mm')}
                </Typography>
                <Typography className={historyModalText}>{log.name}</Typography>
                <Typography className={historyModalHours}>
                  <Trans i18nKey="jobConfirmationDetailsScreen.historyModal.acceptedHours">
                    {{ hours: log.data.conf.hoursWorked }}
                  </Trans>
                </Typography>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelButtonAction} color="primary" autoFocus>
            <Trans>generic.cancel</Trans>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
