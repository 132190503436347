import React from 'react';
import { Trans } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { LogDetailsModalProps } from './Logs.types';

export const LogDetailsModal: React.FC<LogDetailsModalProps> = ({ log, isOpen, cancelAction }) => {
  return (
    <Dialog open={isOpen} onClose={cancelAction} fullWidth maxWidth="md">
      <div>
        <Grid style={{ minHeight: 50 }}>
          <DialogTitle>
            <Trans>jobConfirmationDetailsScreen.historyModal.title</Trans>
          </DialogTitle>
        </Grid>
        <DialogContent>
          <Box>
            {JSON.stringify(log)}
            {/* {sortByDate(logData).map((log: ConfirmationLog, index: number) => (
              <Box display="flex" key={`${index}_${Math.random()}`}>
                <Typography className={historyModalText}>{index + 1}</Typography>
                <Typography className={historyModalText}>{formatDate(log.createdAt)}</Typography>
                <Typography className={historyModalText}>{log.name}</Typography>
                <Typography className={historyModalHours}>
                  <Trans i18nKey="jobConfirmationDetailsScreen.historyModal.acceptedHours">
                    {{ hours: log.data.conf.hoursWorked }}
                  </Trans>
                </Typography>
              </Box>
            ))} */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelAction} color="primary" autoFocus>
            <Trans>generic.cancel</Trans>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
