import React, { useCallback, useState } from 'react';
import { Box, Grid, Modal, Typography } from '@mui/material';
import { DocumentProps } from './WorkerProfileDetails.types';
import ImageViewer from 'react-simple-image-viewer';

export const DocumentComponent: React.FC<DocumentProps> = ({ imageUrls, size = 500 }) => {
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [isViewerOpen, setIsViewerOpen] = useState<boolean>(false);

  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Grid container>
      {imageUrls ? (
        imageUrls.map((imageUrl, index) => (
          <Grid style={{ margin: 10 }} key={`${index}_${Math.random()}`}>
            <img
              src={imageUrl}
              width={size}
              height={size}
              key={index}
              onClick={() => openImageViewer(index)}
              alt="document_image"
            />
          </Grid>
        ))
      ) : (
        <Grid style={{ margin: 10 }}>
          <Typography>Worker did not uploaded pictures to this document </Typography>
        </Grid>
      )}

      {isViewerOpen && (
        <Modal
          open={isViewerOpen}
          onClose={() => {
            setIsViewerOpen(false);
          }}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description">
          <Box sx={{ width: '100vh', height: '100vh' }}>
            <ImageViewer
              src={imageUrls || []}
              currentIndex={currentImage}
              disableScroll={false}
              backgroundStyle={{ position: 'absolute', width: '100vh', height: '100vh' }}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
            />
          </Box>
        </Modal>
      )}
    </Grid>
  );
};
