import { Paper, SwipeableDrawer, Typography, Box, Button, Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Edit, BackupTable, Money, Archive, ExplicitRounded } from '@mui/icons-material';
import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import {
  compareTaxNumbersWithBirthDay,
  validateSocialSecurityNumber,
  validateStudentNumber,
  validateTaxNumber,
} from 'src/utils/validators';
import { ALIGMENT_STATUS } from 'src/api/hooks/useJoin.types';
import { BaseRequest } from 'src/api/hooks/useApi.types';
import { ExpireWorkerPayload } from 'src/api/hooks/useWorker.types';
import moment from 'moment';

export type SwipableWorkerDetails = {
  data?: WorkerProfile;
  loading: boolean;
  expireWorkerDocs: BaseRequest<ExpireWorkerPayload>;
  archiveWorker: BaseRequest<ExpireWorkerPayload>;
  onClose: () => void;
};

export const SwipableWorkerDetails: React.FC<SwipableWorkerDetails> = ({
  data,
  expireWorkerDocs,
  archiveWorker,
  loading,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!data && isOpen) {
      setIsOpen(false);
    }
    if (!isOpen) {
      setIsOpen(true);
    }
  }, [data]);

  if (!data) return null;

  const navigateToWorker = (id: string) => {
    return window.open(`/worker-profile/${id}`, '_blank');
  };

  const navigateToJobs = () => {
    return window.open(`/jobs/filter/worker/${data.id}`, '_blank');
  };

  const navigateToDocuments = () => {
    return window.open(`/worker-profile/${data.id}/documents`, '_blank');
  };

  const navigateToAccount = () => {
    return window.open(`/worker-profile/${data.id}/account`, '_blank');
  };

  const navigateToConfirms = () => {
    return window.open(`/confirmations/filter/worker/${data.id}`, '_blank');
  };

  return (
    <SwipeableDrawer
      onClose={() => {
        setIsOpen(false);
        onClose();
      }}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      anchor="right">
      <Paper style={{ padding: 24 }}>
        <Typography color="primary" variant="h1">
          {data.name} ({Math.abs(moment(data.birthDay).diff(moment(), 'years'))})
        </Typography>
        <Typography color="primary" variant="body1">
          Bday: {moment(data.birthDay).format('YYYY-MM-DD')}
        </Typography>
        <Box mt={2}>
          <Button
            onClick={() => {
              navigateToWorker(data.id);
            }}
            color="primary"
            startIcon={<Edit />}
            variant="contained">
            {t('generic.edit')}
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            onClick={() => {
              navigateToAccount();
            }}
            startIcon={<Money />}
            color="info"
            variant="contained">
            {t('workerProfilesScreen.swipe.account')}
          </Button>

          <Button
            onClick={() => {
              navigateToDocuments();
            }}
            style={{ marginLeft: 12 }}
            startIcon={<BackupTable />}
            color="info"
            variant="contained">
            {t('workerProfilesScreen.swipe.documents')}
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            onClick={() => {
              navigateToJobs();
            }}
            startIcon={<Money />}
            color="info"
            variant="contained">
            {t('workerProfilesScreen.swipe.jobs')}
          </Button>
          <Button
            onClick={() => {
              navigateToConfirms();
            }}
            style={{ marginLeft: 12 }}
            startIcon={<BackupTable />}
            color="info"
            variant="contained">
            {t('workerProfilesScreen.swipe.confirms')}
          </Button>
        </Box>
      </Paper>
      <Box mt={2}>
        <Paper style={{ padding: 24 }}>
          <Typography variant="subtitle1">{t('workerProfilesScreen.swipe.actions')}</Typography>
          <Button
            onClick={() => {
              if (confirm('Do you really want to expire the documents?')) {
                expireWorkerDocs({
                  variables: {
                    workerId: data.id,
                  },
                });
              }
            }}
            disabled={
              data.contractStatus?.studentCardDocumentStatus !== ALIGMENT_STATUS.ACCEPTED || loading
            }
            color="warning"
            startIcon={<ExplicitRounded />}
            variant="contained">
            {t('workerProfilesScreen.swipe.expireDocuments')}
          </Button>
          <Button
            onClick={() => {
              if (confirm('Do you really want to ARCHIVE the documents?')) {
                archiveWorker({
                  variables: {
                    workerId: data.id,
                  },
                });
              }
            }}
            disabled={loading}
            style={{ marginLeft: 12 }}
            color="error"
            startIcon={<Archive />}
            variant="contained">
            {t('generic.archive')}
          </Button>
        </Paper>
      </Box>
      <Box mt={2}>
        <Paper style={{ padding: 24 }}>
          <Typography variant="subtitle1">{t('workerProfilesScreen.swipe.balance')}</Typography>
          <Typography color="primary" variant="subtitle2">
            {data.account?.balance} Ft
          </Typography>
        </Paper>
      </Box>
      <Box mt={2}>
        <Paper style={{ padding: 24 }}>
          <Typography variant="subtitle1">{t('joinRequestsScreen.swipe.validations')}</Typography>
          {validateStudentNumber(data.studentIdNumber || '') ? (
            <Alert severity="success">Valid Student number</Alert>
          ) : (
            <Alert severity="error">INVALID Student number</Alert>
          )}
          <br />
          {validateTaxNumber(data.taxNumber || '') &&
          compareTaxNumbersWithBirthDay(data.taxNumber || '', data.birthDay) ? (
            <Alert severity="success">Valid TAX number</Alert>
          ) : (
            <Alert severity="error">
              INVALID TAX number
              {validateTaxNumber(data.taxNumber || '') &&
              compareTaxNumbersWithBirthDay(data.taxNumber || '', data.birthDay) === false
                ? ' - BDAY NOT MATCH'
                : ''}
            </Alert>
          )}
          <br />
          {validateSocialSecurityNumber(data.socialSecurityNumber || '') ? (
            <Alert severity="success">Valid S.Security number</Alert>
          ) : (
            <Alert severity="error">INVALID S.Security number</Alert>
          )}
        </Paper>
      </Box>
      <Box mt={2}>
        <Paper style={{ padding: 24 }}>
          <Typography variant="subtitle1">{t('workerProfilesScreen.swipe.companies')}</Typography>
          <Typography color="primary" variant="subtitle2">
            {data.companies?.map(n => n.name).join(', ')}
          </Typography>
        </Paper>
      </Box>
    </SwipeableDrawer>
  );
};
