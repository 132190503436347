import { Invoice } from 'src/DTO/Invoice.type';
import { useApi } from './useApi';
import { BaseHookRequest, BaseRequest } from './useApi.types';
import {
  GetInvoiceByIdPayload,
  UpdateInvoicePayload,
  DocumentPublicUrl,
  CreateInvoicePayload,
} from './useInvoice.types';

export const useInvoices: BaseHookRequest<Invoice[], null> = params => {
  const [{ getRequest }, state] = useApi<Invoice[]>(params);
  const getInvoices = () => getRequest('admin/invoice');
  return [getInvoices, state];
};

export const useGetInvoiceById: BaseHookRequest<Invoice, GetInvoiceByIdPayload> = params => {
  const [{ getRequest }, state] = useApi<Invoice>(params);
  const getInvoice: BaseRequest<GetInvoiceByIdPayload> = details =>
    getRequest<GetInvoiceByIdPayload>(`/admin/invoice/${details?.variables.id}`);
  return [getInvoice, state];
};

export const useUpdateInvoice: BaseHookRequest<Invoice, UpdateInvoicePayload> = params => {
  const [{ postRequest }, state] = useApi<Invoice>(params);
  const updateInvoice: BaseRequest<UpdateInvoicePayload> = details => {
    return details
      ? postRequest<UpdateInvoicePayload>('/admin/invoice/update', { ...details.variables })
      : undefined;
  };
  return [updateInvoice, state];
};

export const useGetPublicUrl: BaseHookRequest<DocumentPublicUrl, GetInvoiceByIdPayload> =
  params => {
    const [{ getRequest }, state] = useApi<DocumentPublicUrl>(params);
    const getInvoicePublicUrl: BaseRequest<GetInvoiceByIdPayload> = details =>
      getRequest<GetInvoiceByIdPayload>(`/admin/invoice/download/${details?.variables.id}`);
    return [getInvoicePublicUrl, state];
  };

export const useCreateInvoice: BaseHookRequest<Invoice, CreateInvoicePayload> = params => {
  const [{ postRequest }, state] = useApi<Invoice>(params);
  const createInvoice: BaseRequest<CreateInvoicePayload> = details => {
    return details
      ? postRequest<CreateInvoicePayload>('/admin/invoice/create', { ...details.variables })
      : undefined;
  };
  return [createInvoice, state];
};
