import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { Document } from 'src/DTO/Document.type';
import { useApi } from './useApi';
import { BaseRequest, BaseHookRequest } from './useApi.types';
import {
  GetWorkerByIdPayload,
  GetWorkerByIdResponse,
  GetWorkersParams,
  UpdateWorkerDocumentPayload,
  WorkerDataPayLoad,
  GenerateFGDeclarationPDFPayload,
  GenerateFGDeclarationPDFResponse,
  ExpireWorkerPayload,
  ArchiveWorkerPayload,
  UpdateDeclarationPayload,
  GetWorkerDocumentsResponse,
  GetWorkerContractsResponse,
} from './useWorker.types';
import { Account } from 'src/DTO/Account.type';
import { BasicResult } from './types';

export const useGetWorkers: BaseHookRequest<WorkerProfile[], GetWorkersParams | undefined> =
  params => {
    const [{ postRequest }, state] = useApi<WorkerProfile[]>(params);
    const getWorkers = () => {
      return postRequest('/admin/workerProfile');
    };
    return [getWorkers, state];
  };

export const useGetWorkerById: BaseHookRequest<GetWorkerByIdResponse, GetWorkerByIdPayload> =
  params => {
    const [{ getRequest }, state] = useApi<GetWorkerByIdResponse>(params);
    const getWorker: BaseRequest<GetWorkerByIdPayload> = details =>
      getRequest<GetWorkerByIdPayload>(`/admin/workerProfile/${details?.variables.id}`);
    return [getWorker, state];
  };

export const useGetWorkerDocuments: BaseHookRequest<
  GetWorkerDocumentsResponse,
  GetWorkerByIdPayload
> = params => {
  const [{ getRequest }, state] = useApi<GetWorkerDocumentsResponse>(params);
  const getWorker: BaseRequest<GetWorkerByIdPayload> = details =>
    getRequest<GetWorkerByIdPayload>(`/admin/workerProfile/${details?.variables.id}/documents`);
  return [getWorker, state];
};

export const useGetWorkerContracts: BaseHookRequest<
  GetWorkerContractsResponse,
  GetWorkerByIdPayload
> = params => {
  const [{ getRequest }, state] = useApi<GetWorkerContractsResponse>(params);
  const getWorkerContracts: BaseRequest<GetWorkerByIdPayload> = details =>
    getRequest<GetWorkerByIdPayload>(`/admin/document/workerContracts/${details?.variables.id}`);
  return [getWorkerContracts, state];
};

export const useUpdateWorker: BaseHookRequest<WorkerProfile, WorkerDataPayLoad> = params => {
  const [{ postRequest }, state] = useApi<WorkerProfile>(params);
  const updateWorker: BaseRequest<WorkerDataPayLoad> = details => {
    return details
      ? postRequest<WorkerDataPayLoad>('/admin/workerProfile/update', details.variables)
      : undefined;
  };
  return [updateWorker, state];
};

export const useUpdateWorkerDocument: BaseHookRequest<Document[], UpdateWorkerDocumentPayload> =
  params => {
    const [{ postRequest }, state] = useApi<Document[]>(params);
    const updateWorkerDocument: BaseRequest<UpdateWorkerDocumentPayload> = details => {
      return details
        ? postRequest<UpdateWorkerDocumentPayload>(
            '/admin/workerProfile/document/update',
            details.variables,
          )
        : undefined;
    };
    return [updateWorkerDocument, state];
  };

export const useGetWorkerAccount: BaseHookRequest<Account, GetWorkerByIdPayload> = params => {
  const [{ getRequest }, state] = useApi<Account>(params);
  const getWorkerAccount: BaseRequest<GetWorkerByIdPayload> = details =>
    getRequest<GetWorkerByIdPayload>(`/admin/workerProfile/account/${details?.variables.id}`);
  return [getWorkerAccount, state];
};

export const useGenerateFGDeclaration: BaseHookRequest<
  GenerateFGDeclarationPDFResponse,
  GenerateFGDeclarationPDFPayload
> = params => {
  const [{ postRequest }, state] = useApi<GenerateFGDeclarationPDFResponse>(params);
  const request: BaseRequest<GenerateFGDeclarationPDFPayload> = details => {
    console.log('condigsss');
    return details
      ? postRequest<GenerateFGDeclarationPDFPayload>(
          'admin/workerProfile/foreignDeclaration/generate',
          details.variables,
        )
      : undefined;
  };
  return [request, state];
};

export const useExpireWorkerDocument: BaseHookRequest<BasicResult, ExpireWorkerPayload> =
  params => {
    const [{ getRequest }, state] = useApi<BasicResult>(params);
    const request: BaseRequest<ExpireWorkerPayload> = details =>
      getRequest<ExpireWorkerPayload>(
        `/admin/workerProfile/expire/docs/${details?.variables.workerId}`,
      );
    return [request, state];
  };

export const useArchiveWorker: BaseHookRequest<BasicResult, ArchiveWorkerPayload> = params => {
  const [{ getRequest }, state] = useApi<BasicResult>(params);
  const request: BaseRequest<ArchiveWorkerPayload> = details =>
    getRequest<ArchiveWorkerPayload>(`/admin/workerProfile/archive/${details?.variables.workerId}`);
  return [request, state];
};

export const useUpdateDeclaration: BaseHookRequest<BasicResult, UpdateDeclarationPayload> =
  params => {
    const [{ postRequest }, state] = useApi<BasicResult>(params);
    const updateWorker: BaseRequest<UpdateDeclarationPayload> = details => {
      return details
        ? postRequest<UpdateDeclarationPayload>(
            '/admin/workerProfile/declaration/update',
            details.variables,
          )
        : undefined;
    };
    return [updateWorker, state];
  };

export const useGetDeleteRequests: BaseHookRequest<WorkerProfile[], undefined> = params => {
  const [{ getRequest }, state] = useApi<WorkerProfile[]>(params);
  const getDeleteRequests = () => {
    return getRequest('/admin/workerProfile/delete/requests');
  };
  return [getDeleteRequests, state];
};
