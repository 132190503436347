import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { Formik, Field, Form } from 'formik';
import { useInviteManager } from 'src/api/hooks/useManager';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { useStoreActions } from 'src/stores';

import {
  ManagerInviteModalProps,
  ManagerInviteProps,
} from '../ManagerProfileDetails/ManagerProfileDetails.types';
import { useStyles } from './ManagerInviteModal.styles';
import { Trans, useTranslation } from 'react-i18next';
import { inviteManagerSchema } from './validationSchema';
import { FormInputField } from 'src/components/FormInputField/FormInputField';
import { ManagerInviteResponse } from 'src/DTO/ManagerInvite.type';
import { removeWhiteSpaces } from 'src/utils/removeWhiteSpaces';

export const ManagerInviteModal: React.FC<ManagerInviteModalProps> = ({
  isOpen,
  cancelButtonAction,
}) => {
  const { t } = useTranslation();
  const { dialogContainer, titleContainer, contentContainer } = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const inviteInitialState = {
    name: '',
    email: '',
  };

  const [inviteManager] = useInviteManager({
    onComplete: res => {
      setLoading(false);
      cancelButtonAction();
      if (res && res === ManagerInviteResponse.SUCCESS) {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.success,
          message: t('alerts.managerInvite.success'),
        });
      } else {
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.warning,
          message: t('alerts.managerInvite.warning'),
        });
      }
    },
    onError: () => {
      setLoading(false);
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.managerInvite.error'),
      });
    },
  });

  const handleConfirmClicked = (data: ManagerInviteProps) => {
    setLoading(true);
    inviteManager({ variables: { ...data } });
  };

  return (
    <Dialog open={isOpen} onClose={cancelButtonAction}>
      <div className={dialogContainer}>
        <DialogTitle className={titleContainer}>
          <Trans>managerInvitesScreen.modal.title</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans>managerInvitesScreen.modal.description</Trans>
          </DialogContentText>
          <Grid className={contentContainer}>
            <Formik
              enableReinitialize
              initialValues={inviteInitialState}
              validationSchema={inviteManagerSchema}
              onSubmit={data =>
                handleConfirmClicked({
                  ...data,
                  email: removeWhiteSpaces(data.email),
                })
              }>
              {() => (
                <Form>
                  <Field
                    label={t('generic.name')}
                    name="name"
                    component={FormInputField}
                    variant="standard"
                    placeholder="John Doe"
                    InputLabelProps={{ shrink: true }}
                  />
                  <Field
                    label={t('generic.email')}
                    name="email"
                    component={FormInputField}
                    variant="standard"
                    placeholder="dellauser@gmail.com"
                    InputLabelProps={{ shrink: true }}
                  />
                  <DialogActions>
                    <Button onClick={cancelButtonAction} color="primary" autoFocus>
                      <Trans>generic.cancel</Trans>
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      autoFocus
                      type="submit"
                      disabled={loading}>
                      {loading ? <Trans>generic.loading</Trans> : <Trans>generic.confirm</Trans>}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Grid>
        </DialogContent>
      </div>
    </Dialog>
  );
};
