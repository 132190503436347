import { makeStyles } from '@mui/styles';
import { theme } from 'src/utils/theme';

export const useStyles = makeStyles(() => ({
  highLightedText: {
    height: 40,
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 600,
    paddingLeft: 10,
  },
}));
