import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  wageCard: {
    border: '1px solid #ccc',
    borderRadius: 10,
    padding: 20,
    margin: 20,
  },
  wageCardTitle: {
    height: 50,
  },
  wageData: {
    paddingRight: 10,
  },
  titleContainer: {
    paddingBottom: 20,
  },
  contentContainer: {
    paddingBottom: 20,
  },
}));
