import { createTheme } from '@mui/material/styles';

const PRIMARY_TEXT_COLOR = '#ffffff';
const PRIMARY_COLOR = '#007fff';
const SECOUNDARY_COLOR = '#001e3c';

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#0b1929',
      paper: '#0b1929',
    },
    text: {
      primary: PRIMARY_TEXT_COLOR,
      secondary: PRIMARY_TEXT_COLOR,
    },
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: '#001e3c',
    },
    info: {
      main: '#ffff',
    },
    common: {
      white: '#ffffff',
    },
    success: {
      main: '#00b16a',
    },
    warning: {
      main: '#f7ca18',
    },
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    body1: {
      fontSize: 14,
    },
    h1: {
      fontSize: 24,
    },
    h2: {
      fontSize: 22,
    },
    h3: {
      fontSize: 20,
    },
    h4: {
      fontSize: 18,
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'info',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSmoothing: 'antialiased',
          osxFontSmoothing: 'grayscale',
        },
        body: {
          body: { backgroundColor: SECOUNDARY_COLOR },
          margin: 0,
        },
        b: {
          fontWeight: 500,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        focusVisible: {
          backgroundColor: '#003162',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: 'info',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#fff',
          fontSize: 14,
        },
      },
    },
    // MuiFormControl: {
    //   styleOverrides: {
    //     root: {
    //       width: '100%',
    //     },
    //   },
    // },
    MuiTypography: {
      styleOverrides: {},
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
  },
  // overrides: {
  //
  //   MuiFormControl: {
  //     root: {
  //       width: '100%',
  //     },
  //   },

  //   MuiBottomNavigationAction: {
  //     root: {
  //       color: PRIMARY_COLOR,
  //     },
  //   },

  // },
});

export { theme };
