import React from 'react';
import { Box, Typography, Chip } from '@mui/material';
import { useStyles } from './FastInfo.styles';

export interface FastInfo {
  key: string;
  chipLabels: string[];
}

export interface FastInfoProps {
  fastInfo?: FastInfo[];
}

export const FastInfo: React.FC<FastInfoProps> = ({ fastInfo }) => {
  const { fastInfoContainer, fastInfoTitle, fastInfoItem } = useStyles();
  return (
    <Box className={fastInfoContainer}>
      {fastInfo &&
        fastInfo.map(({ key, chipLabels }) => (
          <Box
            key={Math.random()}
            display="flex"
            flexDirection="row"
            alignItems="center"
            className={fastInfoItem}>
            <Typography className={fastInfoTitle}>{key}: </Typography>
            {chipLabels.length &&
              chipLabels.map(chipLabel => (
                <Chip
                  key={Math.random()}
                  label={chipLabel}
                  color="primary"
                  variant="outlined"
                  clickable
                  className={fastInfoItem}
                />
              ))}
          </Box>
        ))}
    </Box>
  );
};
