import React, { useEffect } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Alert, AlertTitle } from '@mui/material';
import { Snackbar } from '@mui/material';
import { useStoreActions, useStoreState } from 'src/stores';

const useStyles = makeStyles((theme: Theme) => ({
  basic: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export const PopUp: React.FC = () => {
  const { basic } = useStyles();
  const emptyPopUpData = useStoreActions(action => action.popUp.emptyPopUpData);
  const popUpData = useStoreState(state => state.popUp.popUpData);

  const handleOnClose = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    emptyPopUpData();
  };

  useEffect(() => {
    emptyPopUpData();
  }, []);

  return (
    <>
      {popUpData?.map(notification => (
        <Snackbar
          className={basic}
          open={notification.isOpen}
          autoHideDuration={3000}
          onClose={() => handleOnClose}
          key={Math.random()}>
          <Alert severity={notification.type}>
            {notification.title && <AlertTitle>{notification.title}</AlertTitle>}
            {notification.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};
