import React, { useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import useSound from 'use-sound';
import { useStoreState } from 'src/stores';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const spinningCoinSound = require('src/sounds/spinning-coin-1.mp3');

export const Home: React.FC = () => {
  const soundState = useStoreState(state => state.app.sound);
  const [play] = useSound(spinningCoinSound, { volume: 0.99 });

  useEffect(() => {
    if (soundState) {
      play();
    }
  }, []);

  return <Grid></Grid>;
};
