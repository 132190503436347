import React from 'react';

import { AppContainer } from './AppContainer';
import { Nav } from './Nav';

import { PopUp } from 'src/components/PopUp/PopUp';

const MainLayout: React.FC = ({ children }) => {
  return (
    <>
      <Nav />
      <AppContainer>
        {children}
        <PopUp />
      </AppContainer>
    </>
  );
};

export { MainLayout };
