import React from 'react';

import { useStoreRehydrated } from 'easy-peasy';

export const AppRehydratationProvider: React.FC = ({ children }) => {
  const isRehydrated = useStoreRehydrated();
  console.log(isRehydrated, 'isRehydrated');
  if (isRehydrated) {
    return <>{children}</>;
  } else {
    return <></>;
  }
};
