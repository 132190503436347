import React from 'react';
import { TFunction } from 'react-i18next';
import { Alert, Typography } from '@mui/material';
import { WorkerProfile } from 'src/DTO/WorkerProfile.type';

import { formatDate } from 'src/utils/formatDate';
import { GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';
import { Company } from 'src/DTO/Company.type';
import { ALIGMENT_STATUS } from 'src/api/hooks/useJoin.types';

export const headers = (t: TFunction) => {
  return [
    {
      headerName: t('workerProfilesScreen.headers.id'),
      field: 'id',
      sorting: false,
      flex: 1,
      hide: true,
    },
    {
      headerName: t('workerProfilesScreen.headers.companies'),
      field: 'companies',
      sorting: false,
      flex: 1,
      valueGetter: (params: GridValueGetterParams<Company[]>) =>
        params.row.companies.map((c: Company) => c.id).join(','),
      hide: true,
    },
    { headerName: t('workerProfilesScreen.headers.name'), field: 'name', flex: 1 },
    { headerName: 'Student ID / Status', field: 'expiringDocumentsState', flex: 1 },
    {
      headerName: t('workerProfilesScreen.headers.status'),
      field: 'status',
      sorting: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<WorkerProfile>) => {
        const { contractStatus, isDeclared, jobs } = params.row as WorkerProfile;
        if (contractStatus) {
          if (contractStatus.studentOrgContractStatus === ALIGMENT_STATUS.UNDER_REVIEW) {
            return <Alert severity="error">UNDER REVIEW</Alert>;
          }
          if (contractStatus.studentOrgContractStatus === ALIGMENT_STATUS.EXPIRED) {
            return <Alert severity="error">EXPIRED</Alert>;
          }
          if (
            contractStatus.studentOrgContractStatus === ALIGMENT_STATUS.ACCEPTED &&
            isDeclared === false
          ) {
            return <Alert severity="warning">DEC. NEEDED</Alert>;
          }

          if (
            contractStatus.studentOrgContractStatus === ALIGMENT_STATUS.ACCEPTED &&
            isDeclared === true &&
            jobs &&
            jobs.length > 0
          ) {
            return <Alert severity="success">ACTIVE</Alert>;
          }

          if (
            contractStatus.studentOrgContractStatus === ALIGMENT_STATUS.ACCEPTED &&
            isDeclared === true
          ) {
            return <Alert severity="success">ACTIVE NOJOB</Alert>;
          }

          if (contractStatus.studentOrgContractStatus === ALIGMENT_STATUS.NOT_UPLOADED) {
            return <Alert severity="info">INACTIVE</Alert>;
          }

          return 'asdas';
        } else {
          return 'ERROR ON STATUS';
        }
      },
      valueGetter: (params: GridValueGetterParams<Company[]>) => {
        const { contractStatus, isDeclared, jobs } = params.row as WorkerProfile;
        if (contractStatus) {
          if (contractStatus.studentOrgContractStatus === ALIGMENT_STATUS.UNDER_REVIEW) {
            return 'UNDER REVIEW';
          }
          if (contractStatus.studentOrgContractStatus === ALIGMENT_STATUS.EXPIRED) {
            return 'EXPIRED';
          }
          if (
            contractStatus.studentOrgContractStatus === ALIGMENT_STATUS.ACCEPTED &&
            isDeclared === false
          ) {
            return 'DEC. NEEDED';
          }

          if (
            contractStatus.studentOrgContractStatus === ALIGMENT_STATUS.ACCEPTED &&
            isDeclared === true &&
            jobs &&
            jobs.length > 0
          ) {
            return 'ACTIVE';
          }

          if (
            contractStatus.studentOrgContractStatus === ALIGMENT_STATUS.ACCEPTED &&
            isDeclared === true
          ) {
            return 'ACTIVE NOJOB';
          }

          if (contractStatus.studentOrgContractStatus === ALIGMENT_STATUS.NOT_UPLOADED) {
            return 'INACTIVE';
          }

          return 'asdas';
        } else {
          return 'ERROR ON STATUS';
        }
      },
    },
    {
      headerName: t('workerProfilesScreen.headers.isDeclared'),
      field: 'isDeclared',
      sorting: true,
      flex: 1,
      hide: false,
    },
    {
      headerName: t('workerProfilesScreen.headers.isForeignDeclared'),
      field: 'isForeignDeclared',
      sorting: true,
      flex: 1,
      hide: false,
    },
    {
      headerName: t('workerProfilesScreen.headers.nationality'),
      field: 'nationality',
      sorting: true,
      flex: 1,
      hide: false,
    },
    {
      headerName: t('workerProfilesScreen.headers.preferredLocations'),
      field: 'preferredLocations',
      sorting: true,
      flex: 1,
    },
    {
      headerName: t('workerProfilesScreen.headers.companies'),
      field: 'companies2',
      sorting: false,
      flex: 1,
      valueGetter: (params: GridValueGetterParams<Company[]>) =>
        params.row.companies.map((c: Company) => c.name).join(','),
    },
    { headerName: t('workerProfilesScreen.headers.email'), field: 'email', flex: 1 },
    {
      headerName: t('workerProfilesScreen.headers.phoneNumber'),
      field: 'phoneNumber',
      flex: 1,
    },
    {
      headerName: t('generic.updatedAt'),
      field: 'updatedAt',
      flex: 1,
      render: (rowData: WorkerProfile) => <Typography>{formatDate(rowData.updatedAt)}</Typography>,
    },
  ];
};
