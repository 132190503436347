import { useApi } from './useApi';
import { BaseRequest, BaseHookRequest } from './useApi.types';
import { GetJoinRequest, GetJoinPayload } from './useJoin.types';

export const useGetJoinRequests: BaseHookRequest<GetJoinRequest, GetJoinPayload> = params => {
  const [{ getRequest }, state] = useApi<GetJoinRequest>(params);
  const getJoinRequests: BaseRequest<GetJoinPayload> = () => {
    return getRequest('/admin/join/requests');
  };
  return [getJoinRequests, state];
};

export const useAcceptJoin: BaseHookRequest<GetJoinRequest, GetJoinPayload> = params => {
  const [{ postRequest }, state] = useApi<GetJoinRequest>(params);
  const acceptJoin: BaseRequest<GetJoinPayload> = details => {
    return postRequest('/admin/join/accept', details?.variables);
  };
  return [acceptJoin, state];
};

export const useDeleteJoin: BaseHookRequest<GetJoinRequest, GetJoinPayload> = params => {
  const [{ postRequest }, state] = useApi<GetJoinRequest>(params);
  const acceptJoin: BaseRequest<GetJoinPayload> = details => {
    return postRequest('/admin/join/deleteForever', details?.variables);
  };
  return [acceptJoin, state];
};
