import * as React from 'react';
import { Box, Card, CardActions, CardContent, Button, Typography, Grid } from '@mui/material';

export interface InfoToDisplay {
  title: string;
  value: string | number;
}

export interface ConfirmationCardProps {
  title?: string;
  actionButtonTitle?: string;
  action?: () => void;
  infoToDisplay?: InfoToDisplay[];
}

export const ConfirmationCard: React.FC<ConfirmationCardProps> = props => {
  return (
    <Box>
      <Card variant="outlined">
        <React.Fragment>
          <CardContent>
            {props.title && (
              <Typography color="primary" gutterBottom>
                {props.title}
              </Typography>
            )}
            {props.infoToDisplay?.length &&
              props.infoToDisplay.map((info, index) => (
                <Grid container key={index}>
                  <Typography variant="body2">{info.title}: </Typography>
                  <Typography variant="body2" color="primary" style={{ paddingLeft: 10 }}>
                    {info.value}
                  </Typography>
                </Grid>
              ))}
          </CardContent>
          {props.actionButtonTitle && props.action && (
            <CardActions>
              <Button variant="contained" size="small" onClick={props.action}>
                {props.actionButtonTitle}
              </Button>
            </CardActions>
          )}
        </React.Fragment>
      </Card>
    </Box>
  );
};
