import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';

import { ModalProps } from './JobConfirmationDetails.types';
import { useStyles } from './JobConfirmationModal.styles';
import { useCalculateJobWage } from 'src/api/hooks/useJob';

export const JobConfirmationModal: React.FC<ModalProps> = ({
  isOpen,
  updatedHoursWorked,
  hourlyGrossWage,
  confirmButtonAction,
  cancelButtonAction,
  originalWages,
}) => {
  const { t } = useTranslation();
  const { tableItem, arrow, title: titleStyle, table, buttonContatiner } = useStyles();
  const [calculateJobWage, { data }] = useCalculateJobWage();

  useEffect(() => {
    calculateJobWage({
      variables: {
        hourlyGrossWage: hourlyGrossWage,
        monthlyHours: Number(updatedHoursWorked),
      },
    });
  }, [updatedHoursWorked]);

  return (
    <Dialog open={isOpen} onClose={cancelButtonAction} fullWidth maxWidth="lg">
      <div>
        <DialogTitle className={titleStyle}>
          {t('jobConfirmationDetailsScreen.modal.title')}
        </DialogTitle>
        <DialogTitle>{t('jobConfirmationDetailsScreen.modal.subTitle')} </DialogTitle>
        <DialogContent className={table}>
          <Grid container>
            <Grid item xs={6}>
              <Grid item className={tableItem}>
                <Typography> Total gross amount:</Typography>
              </Grid>
              <Grid item className={tableItem}>
                <Typography> Total net amount:</Typography>
              </Grid>
              <Grid item className={tableItem}>
                <Typography> Total commission:</Typography>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Grid item className={tableItem}>
                <Typography> {originalWages.totalGrossAmount.toLocaleString('HU-hu')}</Typography>
              </Grid>
              <Grid item className={tableItem}>
                <Typography> {originalWages.totalNetAmount.toLocaleString('HU-hu')}</Typography>
              </Grid>
              <Grid item className={tableItem}>
                <Typography>
                  {' '}
                  {originalWages.totalCommissionAmount.toLocaleString('HU-hu')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Grid item className={arrow}>
                <ArrowRightIcon />
              </Grid>
              <Grid item className={arrow}>
                <ArrowRightIcon />
              </Grid>
              <Grid item className={arrow}>
                <ArrowRightIcon />
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Grid item className={tableItem}>
                <Typography color="error">
                  {' '}
                  {data?.wageOver25?.totalGrossAmount.toLocaleString('HU-hu')}
                </Typography>
              </Grid>
              <Grid item className={tableItem}>
                <Typography color="error">
                  {' '}
                  {data?.wageOver25?.totalNetAmount.toLocaleString('HU-hu')}
                </Typography>
              </Grid>
              <Grid item className={tableItem}>
                <Typography color="error">
                  {' '}
                  {data?.wageOver25?.totalCommissionAmount.toLocaleString('HU-hu')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={buttonContatiner}>
          <Button autoFocus onClick={cancelButtonAction} variant="contained">
            <Trans>generic.cancel</Trans>
          </Button>
          <Button onClick={confirmButtonAction} variant="contained" color="primary">
            <Trans>generic.save</Trans>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
