import { action, Action } from 'easy-peasy';

const initialState = {
  authEmail: '',
};

export interface AuthStore {
  authEmail: string;
  setUserEmail: Action<AuthStore, string>;
  reset: Action<AuthStore>;
}

export const authStore: AuthStore = {
  ...initialState,
  setUserEmail: action((state, payload) => {
    state.authEmail = payload;
  }),
  reset: action(() => ({
    ...initialState,
  })),
};
