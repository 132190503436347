import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ProtectedRoute } from './ProtectedRoute';

import { Auth } from 'src/pages/Auth';
import { Home } from 'src/pages/Home';
import { WorkerProfileList } from 'src/pages/WorkerProfiles/WorkerProfileList';
import { WorkerProfileDetails } from 'src/pages/WorkerProfileDetails/WorkerProfileDetails';
import { WorkerDocuments } from 'src/pages/WorkerProfileDetails/WorkerDocuments';
import { ManagerProfileList } from 'src/pages/ManagerProfiles/ManagerProfileList';
import { ManagerProfileDetails } from 'src/pages/ManagerProfileDetails/ManagerProfileDetails';
import { CompanyList } from 'src/pages/Companies/CompanyList';
import { JobConfirmationList } from 'src/pages/JobConfirmations/JobConfirmations';
import { JobConfirmationDetails } from 'src/pages/JobConfirmationDetails/JobConfirmationDetails';
import { CompanyDetails } from 'src/pages/CompanyDetails/CompanyDetails';
import { JobList } from 'src/pages/Jobs/JobsList';
import { TransactionList } from 'src/pages/Transactions/Transactions';
import { TransactionDetails } from 'src/pages/TransactionDetails/TransactionDetails';
import { InvoiceList } from 'src/pages/Invoices/Invoices';
import { InvoiceDetails } from 'src/pages/InvoiceDetails/InvoiceDetails';
import { JobDetails } from 'src/pages/JobDetials/JobDetails';
import { ManagerInviteList } from 'src/pages/ManagerInvites/ManagerInvites';
import { InvoiceHistory } from 'src/pages/CompanyInvoices/InvoiceHistory';
import { JoinRequests } from 'src/pages/Join/JoinRequests';
import { DeleteRequests } from 'src/pages/DeleteRequests/DeleteRequests';
import { LogList } from 'src/pages/Logs/LogsList';
import { CompanyAccount } from 'src/pages/CompanyAccount/CompanyAccount';
import { WorkerAccount } from 'src/pages/WorkerAccount/WorkerAccount';
import { MyInterviewQuestion } from 'src/pages/MyInterviewQuestion/MyInterviewQuestions';
import { JobApplicationList } from 'src/pages/Applications/ApplicationList';
import { DocumentList } from 'src/pages/Documents/DocumentList';

export const AppRouter: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route component={Auth} path="/auth" />

        {/* wkprofiles */}
        <ProtectedRoute component={WorkerDocuments} path="/worker-profile/:workerId/documents" />
        <ProtectedRoute component={WorkerAccount} path="/worker-profile/:workerId/account" />
        <ProtectedRoute component={WorkerProfileDetails} path="/worker-profile/:workerId" />
        <ProtectedRoute component={WorkerProfileList} path="/workers/filter/companyid/:companyId" />
        <ProtectedRoute component={WorkerProfileList} path="/worker-profile" />

        {/* companies */}
        <ProtectedRoute component={InvoiceHistory} path="/companies/:companyId/invoice-history" />
        <ProtectedRoute component={CompanyAccount} path="/companies/:companyId/account" />
        <ProtectedRoute component={CompanyDetails} path="/companies/:companyId" />
        <ProtectedRoute component={CompanyList} path="/companies" />

        <ProtectedRoute component={ManagerProfileDetails} path="/manager-profile/:managerId" />
        <ProtectedRoute component={ManagerProfileList} path="/manager-profile" />

        <ProtectedRoute component={JobConfirmationList} path="/confirmations/filter/:entity/:id" />
        <ProtectedRoute component={JobConfirmationDetails} path="/confirmations/:confirmationId" />
        <ProtectedRoute component={JobConfirmationList} path="/confirmations" />

        <ProtectedRoute component={JobApplicationList} path="/applications" />

        <ProtectedRoute component={JobList} path="/jobs/filter/:entity/:id" />
        <ProtectedRoute component={JobDetails} path="/jobs/:jobId" />
        <ProtectedRoute component={JobList} path="/jobs" />

        <ProtectedRoute component={TransactionDetails} path="/transactions/:transactionId" />
        <ProtectedRoute component={TransactionList} path="/transactions" />

        <ProtectedRoute component={InvoiceDetails} path="/invoices/:invoiceId" />
        <ProtectedRoute component={InvoiceList} path="/invoices" />

        <ProtectedRoute component={ManagerInviteList} path="/manager-invites" />

        <ProtectedRoute component={JoinRequests} path="/join-requests" />

        <ProtectedRoute component={DeleteRequests} path="/delete-requests" />

        <ProtectedRoute component={LogList} path="/logs" />

        <ProtectedRoute component={DocumentList} path="/documents" />

        <ProtectedRoute component={MyInterviewQuestion} path="/myinterview" />

        <ProtectedRoute component={Home} path="/" />
      </Switch>
    </Router>
  );
};
