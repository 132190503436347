import React, { useState } from 'react';
import { Box, Button, ButtonGroup, Grid, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { WagesCardProps } from './JobConfirmationDetails.types';
import { useStyles } from './styles';

export const WagesCard: React.FC<WagesCardProps> = ({ wages }) => {
  const styles = useStyles();
  const [isHourly, setIsHourly] = useState<boolean>(true);

  return (
    <div className={styles.wageCard}>
      <Grid className={styles.wageCardTitle}>
        <Trans>jobConfirmationDetailsScreen.wages.modal.title</Trans>
      </Grid>
      <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
        <Typography className={styles.wageData}>
          <Trans>generic.rake</Trans>
        </Typography>
        <Typography color="primary"> {wages.rake}</Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
        <ButtonGroup color="primary">
          <Button onClick={() => setIsHourly(true)} variant={isHourly ? 'contained' : 'outlined'}>
            <Trans>generic.hourly</Trans>
          </Button>
          <Button onClick={() => setIsHourly(false)} variant={isHourly ? 'outlined' : 'contained'}>
            <Trans>generic.total</Trans>
          </Button>
        </ButtonGroup>
      </Box>
      <Box>
        <Box display="flex">
          <Typography className={styles.wageData}>
            <Trans>generic.invoiced</Trans>
          </Typography>
          <Typography color="primary">
            {isHourly
              ? wages.invoicedHourlyAmount.toLocaleString('HU-hu')
              : wages.totalInvoicedAmount.toLocaleString('HU-hu')}{' '}
            HUF
          </Typography>
        </Box>
        <Box display="flex">
          <Typography className={styles.wageData}>
            <Trans>generic.studentGross</Trans>
          </Typography>
          <Typography color="primary">
            {isHourly
              ? wages.hourlyGrossAmount.toLocaleString('HU-hu')
              : wages.totalGrossAmount.toLocaleString('HU-hu')}{' '}
            HUF
          </Typography>
        </Box>
        <Box display="flex">
          <Typography className={styles.wageData}>
            <Trans>generic.studentNet</Trans>
          </Typography>
          <Typography color="primary">
            {isHourly
              ? wages.hourlyNetAmount.toLocaleString('HU-hu')
              : wages.totalNetAmount.toLocaleString('HU-hu')}{' '}
            HUF
          </Typography>
        </Box>
        <Box display="flex">
          <Typography className={styles.wageData}>
            <Trans>generic.personalTax</Trans>
          </Typography>
          <Typography color="primary">
            {isHourly
              ? wages.hourlyPersonalTaxAmount.toLocaleString('HU-hu')
              : wages.totalPersonalTaxAmount.toLocaleString('HU-hu')}{' '}
            HUF
          </Typography>
        </Box>
      </Box>
    </div>
  );
};
