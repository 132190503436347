import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { Log, LogType } from 'src/DTO/Log.type';
import { useStoreActions } from 'src/stores';
import { useTranslation, TFunction } from 'react-i18next';
import { ArrowForward as ArrowForwardIcon, Details as DetailsIcon } from '@mui/icons-material';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { CustomeMaterialTable } from 'src/components/MaterialTable/MaterialTable';
import { useGetLogs } from 'src/api/hooks/useLogs';
import { formatDate } from 'src/utils/formatDate';
import { LogDetailsModal } from './LogDetailsModal';

const headers = (t: TFunction) => {
  return [
    {
      title: t('logList.headers.id'),
      field: 'id',
      sorting: false,
    },
    { title: t('logList.headers.info'), field: 'name' },
    {
      title: t('logList.headers.type'),
      field: 'data',
      render: (rowData: Log) => {
        const type: LogType = rowData.company
          ? LogType.Company
          : rowData.worker
          ? LogType.Worker
          : LogType.Manager;
        const color = rowData.company ? 'green' : rowData.worker ? 'blue' : 'purple';
        return <Typography style={{ color }}>{type}</Typography>;
      },
    },
    {
      title: t('logList.headers.createdAt'),
      field: 'createdAt',
      render: (rowData: Log) => <Typography>{formatDate(rowData.createdAt)}</Typography>,
    },
    {
      title: t('generic.updatedAt'),
      field: 'updatedAt',
      render: (rowData: Log) => <Typography>{formatDate(rowData.updatedAt)}</Typography>,
    },
  ];
};

export const LogList: React.FC = () => {
  const { t } = useTranslation();
  const [logs, setLogs] = useState<Log[] | undefined>(undefined);
  const [selectedLog, setSelectedLog] = useState<Log | undefined>(undefined);
  const [detailsModalOpen, setDetailsModalOpen] = useState<boolean>(false);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [getLogs] = useGetLogs({
    onComplete: res => {
      if (res) {
        setLogs(res);
      }
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('alerts.logList.error'),
      });
    },
  });

  useEffect(() => {
    getLogs();
  }, []);

  const navigateToEntities = (rowData: Log) => {
    const type: LogType = rowData.company
      ? LogType.Company
      : rowData.worker
      ? LogType.Worker
      : LogType.Manager;
    let url = '';
    let id = '';

    switch (type) {
      case LogType.Company:
        url = 'companies';
        id = (rowData.company && rowData.company.id) || '';
        break;
      case LogType.Worker:
        url = 'worker-profile';
        id = (rowData.worker && rowData.worker.id) || '';
        break;
      case LogType.Manager:
        url = 'manager-profile';
        id = (rowData.manager && rowData.manager.id) || '';
        break;
    }

    return window.open(`/${url}/${id}`, '_blank');
  };

  const openLogDetailsModal = (logData: Log) => {
    setDetailsModalOpen(true);
    setSelectedLog(logData);
  };

  return (
    <Grid style={{ width: '100%' }}>
      <CustomeMaterialTable
        tableProps={{
          title: t('jobsScreen.title'),
          columns: headers(t),
          isLoading: !logs,
          data: logs ? logs : [],
          options: {
            toolbar: false,
          },
          actions: [
            {
              icon: () => <DetailsIcon color="primary" fontSize="medium" />,
              tooltip: t('generic.openDetails'),
              // onClick: (_event, rowData) => openLogDetailsModal(rowData),
            },
            {
              icon: () => <ArrowForwardIcon color="primary" fontSize="medium" />,
              tooltip: t('generic.navigateToEntity'),
              // onClick: (_event, rowData) => navigateToEntities(rowData),
            },
          ],
        }}
      />
      {selectedLog && (
        <LogDetailsModal
          isOpen={detailsModalOpen}
          log={selectedLog}
          cancelAction={() => setDetailsModalOpen(false)}
        />
      )}
    </Grid>
  );
};
