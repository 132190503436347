import React from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import { FieldProps, getIn, useField } from 'formik';
import { PaymentMethod } from 'src/DTO/PaymentMethod.type';

import { useFormInputFieldStyles } from 'src/components/FormInputField/useFormInputFieldStyles';
import { FormControl, InputLabel, Select, OutlinedInput, MenuItem } from '@mui/material';
import { Trans } from 'react-i18next';

export const FormPaymentMethodSelect: React.FC<FieldProps> = props => {
  const { wrapper } = useFormInputFieldStyles();

  const [field, meta, helpers] = useField(props.field.name);
  const { value = '' } = meta;
  const { setValue } = helpers;

  const paymentMethods = Object.entries(PaymentMethod).map(([index, key]) => ({
    id: index,
    name: key,
  }));

  const paymentMethodComponents = paymentMethods.map(paymentMethod => {
    return (
      <MenuItem value={paymentMethod.name} key={paymentMethod.id}>
        {paymentMethod.name}
      </MenuItem>
    );
  });

  return (
    <div className={wrapper}>
      <FormControl variant="outlined" style={{ width: '100%' }}>
        <InputLabel shrink>
          <Trans>invoiceDetailsScreen.modal.form.paymentMethod</Trans>
        </InputLabel>
        <Select
          {...field}
          value={value}
          onChange={event => setValue(event.target.value as string)}
          input={<OutlinedInput notched />}>
          {paymentMethodComponents && paymentMethodComponents}
        </Select>
      </FormControl>
      <FormHelperText error>
        {getIn(props.form.touched, field.name) && getIn(props.form.errors, field.name)}
      </FormHelperText>
    </div>
  );
};
