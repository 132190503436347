import * as Yup from 'yup';
import i18next from 'i18next';

const MIN_LENGTH = 3;
const PHONE_NUMBER_MIN_LENGTH = 12;

export const inviteManagerSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .required(i18next.t('validation.required'))
      .min(MIN_LENGTH, i18next.t('validation.minLength', { min: MIN_LENGTH })),
    email: Yup.string()
      .required(i18next.t('validation.required'))
      .min(
        PHONE_NUMBER_MIN_LENGTH,
        i18next.t('validation.minLength', { number: PHONE_NUMBER_MIN_LENGTH }),
      )
      .email(i18next.t('validation.invalidEmail')),
  });
