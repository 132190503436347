import { WorkerProfile } from 'src/DTO/WorkerProfile.type';

export enum ALIGMENT_STATUS {
  ACCEPTED = 'ACCEPTED',
  UNDER_REVIEW = 'UNDER_REVIEW',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  NOT_UPLOADED = 'NOT_UPLOADED',
}

export interface WorkerContractStatus {
  id: string;
  studentOrgContractStatus: ALIGMENT_STATUS;
  studentCardDocumentStatus: ALIGMENT_STATUS;
  worker: WorkerProfile;
  createdAt: Date;
  updatedAt: Date;
}

export interface GetJoinRequest {
  requests: WorkerContractStatus[];
}

export interface GetJoinPayload {}

export interface AcceptJoinResponse {
  isSuccess: true;
}

export interface AcceptJoinPayload {
  statusId: string;
}
