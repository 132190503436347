import * as Yup from 'yup';

export const validationSchema = (messageType: string) => {
  return Yup.object().shape({
    ...(messageType === 'EMAIL' && {
      titleHu: Yup.string()
        .required('Hungarian title is required')
        .min(4, 'Hungarian title must be at least 4 characters'),
      titleEn: Yup.string()
        .required('English title is required')
        .min(4, 'English title must be at least 4 characters'),
      textHu: Yup.string()
        .required('Hungarian text is required')
        .min(4, 'Hungarian text must be at least 4 characters'),
      textEn: Yup.string()
        .required('English text is required')
        .min(4, 'English text must be at least 4 characters'),
    }),

    ...(messageType === 'SMS' && {
      textHu: Yup.string()
        .required('Hungarian text is required')
        .min(4, 'Hungarian text must be at least 4 characters'),
      textEn: Yup.string()
        .required('English text is required')
        .min(4, 'English text must be at least 4 characters'),
    }),

    ...(messageType === 'PUSH' && {
      textHu: Yup.string()
        .required('Hungarian text is required')
        .min(4, 'Hungarian text must be at least 4 characters'),
      textEn: Yup.string()
        .required('English text is required')
        .min(4, 'English text must be at least 4 characters'),
    }),
  });
};
