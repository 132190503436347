import {
  LoginPayload,
  LoginResponse,
  MeUserResponse,
  MeUserPayload,
  RequestManagerTokenPayload,
  RequestManagerTokenResponse,
} from './useAuth.types';
import { useApi } from './useApi';
import { BaseRequest, BaseHookRequest } from './useApi.types';

export const useLoginAuth: BaseHookRequest<LoginResponse, LoginPayload> = params => {
  const [{ postRequest }, state] = useApi<LoginResponse>(params);
  const login: BaseRequest<LoginPayload> = details =>
    postRequest<LoginPayload>('/admin/auth/login', details?.variables);

  return [login, state];
};

export const useMe: BaseHookRequest<MeUserResponse, MeUserPayload> = params => {
  const [{ getRequest }, state] = useApi<MeUserResponse>(params);
  const registerUser: BaseRequest<MeUserPayload> = () =>
    getRequest<MeUserPayload>('/admin/auth/me');
  return [registerUser, state];
};

export const useRequestManagerTokenLogin: BaseHookRequest<
  RequestManagerTokenResponse,
  RequestManagerTokenPayload
> = params => {
  const [{ postRequest }, state] = useApi<RequestManagerTokenResponse>(params);
  const request: BaseRequest<RequestManagerTokenPayload> = details =>
    postRequest<RequestManagerTokenPayload>(
      '/admin/auth/token/generate/manager',
      details?.variables,
    );
  return [request, state];
};
