import { Job } from 'src/DTO/Job.type';
import { JobApplication } from 'src/DTO/JobApplication';
import { JobConfirmation } from 'src/DTO/JobConfirmation.type';
import { Log } from 'src/DTO/Log.type';
import { useApi } from './useApi';
import { BaseRequest, BaseHookRequest } from './useApi.types';
import {
  ActivateJobPayload,
  CalculateJobWagePayload,
  CloseJobConfirmationPayLoad,
  GetConfirmationHistoryPayload,
  GetJobByIdPayload,
  GetJobConfirmationByIdPayload,
  InviteWorkersToJobPayload,
  JobWageResponse,
  UpdateJobConfirmationPayLoad,
} from './useJob.types';

export const useGetJobs: BaseHookRequest<Job[], null> = params => {
  const [{ postRequest }, state] = useApi<Job[]>(params);
  const getJobs = () => {
    return postRequest('/admin/job');
  };
  return [getJobs, state];
};

export const useGetJobById: BaseHookRequest<Job, GetJobByIdPayload> = params => {
  const [{ getRequest }, state] = useApi<Job>(params);
  const getJobById: BaseRequest<GetJobByIdPayload> = details =>
    getRequest<GetJobByIdPayload>(`/admin/job/${details?.variables.id}`);
  return [getJobById, state];
};

export const useGetJobConfirmations: BaseHookRequest<JobConfirmation[], null> = params => {
  const [{ getRequest }, state] = useApi<JobConfirmation[]>(params);
  const getJobConfirmations = () => {
    return getRequest('/admin/jobConfirmation');
  };
  return [getJobConfirmations, state];
};

export const useGetJobConfirmationById: BaseHookRequest<
  JobConfirmation,
  GetJobConfirmationByIdPayload
> = params => {
  const [{ getRequest }, state] = useApi<JobConfirmation>(params);
  const getJobConfirmation: BaseRequest<GetJobConfirmationByIdPayload> = details => {
    return getRequest(`/admin/jobConfirmation/${details?.variables.id}`);
  };
  return [getJobConfirmation, state];
};

export const useUpdateJobConfirmaton: BaseHookRequest<
  JobConfirmation,
  UpdateJobConfirmationPayLoad
> = params => {
  const [{ postRequest }, state] = useApi<JobConfirmation>(params);
  const updateJobConfirmation: BaseRequest<GetJobConfirmationByIdPayload> = details => {
    return postRequest('/admin/jobConfirmation/update', {
      updatedJobConfirmationData: details?.variables,
    });
  };
  return [updateJobConfirmation, state];
};

export const useCloseJobConfirmaton: BaseHookRequest<string, CloseJobConfirmationPayLoad> =
  params => {
    const [{ postRequest }, state] = useApi<string>(params);
    const closeJobConfirmation: BaseRequest<CloseJobConfirmationPayLoad> = details => {
      return postRequest(`/admin/jobConfirmation/close/${details?.variables.id}`);
    };
    return [closeJobConfirmation, state];
  };

export const useInviteWorkerToJob: BaseHookRequest<
  { success: boolean },
  InviteWorkersToJobPayload
> = params => {
  const [{ postRequest }, state] = useApi<{ success: boolean }>(params);
  const inviteWorkerToJob: BaseRequest<InviteWorkersToJobPayload> = details => {
    return details
      ? postRequest('/admin/job/inviteWorker', {
          ...details.variables,
        })
      : undefined;
  };
  return [inviteWorkerToJob, state];
};

export const useConfirmationHistory: BaseHookRequest<Log[], GetConfirmationHistoryPayload> =
  params => {
    const [{ postRequest }, state] = useApi<Log[]>(params);
    const getConfirmation: BaseRequest<GetConfirmationHistoryPayload> = details => {
      return details
        ? postRequest('/admin/jobConfirmation/history', {
            ...details.variables,
          })
        : undefined;
    };
    return [getConfirmation, state];
  };

export const useCalculateJobWage: BaseHookRequest<JobWageResponse, CalculateJobWagePayload> =
  params => {
    const [{ postRequest }, state] = useApi(params);
    const calculateJobWage: BaseRequest<CalculateJobWagePayload> = details =>
      postRequest<CalculateJobWagePayload>('/admin/job/calculateJobWage', details?.variables);
    return [calculateJobWage, state];
  };

export const useGetJobApplications: BaseHookRequest<JobApplication[], null> = params => {
  const [{ getRequest }, state] = useApi<JobApplication[]>(params);
  const getJobs = () => {
    return getRequest('/admin/application');
  };
  return [getJobs, state];
};

export const useActivateJob: BaseHookRequest<Job, ActivateJobPayload> = params => {
  const [{ getRequest }, state] = useApi<Job>(params);
  const activateJob: BaseRequest<ActivateJobPayload> = details => {
    return getRequest(`/admin/job/activate/${details?.variables.id}`);
  };
  return [activateJob, state];
};

export const useDeActivateJob: BaseHookRequest<Job, ActivateJobPayload> = params => {
  const [{ getRequest }, state] = useApi<Job>(params);
  const deActivateJob: BaseRequest<ActivateJobPayload> = details => {
    return getRequest(`/admin/job/deactivate/${details?.variables.id}`);
  };
  return [deActivateJob, state];
};
